import React, { useState, useEffect, ChangeEvent } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link} from "react-router-dom";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Header from './include/Header.js';
import Footer from './include/Footer.js';
import { format } from 'date-fns';
import ActionLogout from '../component/ActionLogout.js';
import { fetchGet, fetchPost, fetchHtml } from "../services/apiCalls.js";
import dayjs, { Dayjs } from 'dayjs';

const accessToken = localStorage.getItem('accessToken');

function uniq(a) {
    return Array.from(new Set(a));
}

const InsightCreation_Page = () => {

    let currentinsight = window.location.pathname;
    var insightid = currentinsight.split(/[/]+/).pop();

    if(insightid == ""){
        window.location.href="/admin/insight-list";
    }

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessageDoc, setErrorMessageDoc] = useState("");
    const [successMessageDoc, setSuccessMessageDoc] = useState("");
    const [errorMessageThumb, setErrorMessageThumb] = useState("");
    const [successMessageThumb, setSuccessMessageThumb] = useState("");
    const [docfile, setDocFile] = useState([]);
    const [thufile, setThuFile] = useState('');
    const [formDocS3Link, setFormDocS3Link] = useState([]);
    const [formDocS3LinkName, setFormDocS3LinkName] = useState([]);
    const [formS3Link, setFormS3Link] = useState("");
    const [authorData, setAuthorData] = useState([]);
    const [featureData, setFeatureData] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [lastUpdated, setLastupdateDate] = useState("");
    const [formDataTags, setFormDataTags] = useState("");
    const [formDataFeatures, setFormDataFeatures] = useState("");
    const [displayLoader, setDisplayLoader] = useState(true);
    const [datainsights, setDataInsights] = useState([]);
    const [formMode, setFormMode] = useState("");
    const [s3BodyLinkDel, setS3BodyLinkDel] = useState("");
    const [openAlertNewUpload, setOpenAlertNewUpload] = useState(false);
    const [openAlertDel, setOpenAlertDel] = useState(false);
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertNewUpload(false);
        setOpenAlertDel(false);
    };

    const theme = createTheme({
        typography: {
            fontFamily: [
                'Semplicita Pro'
            ],
            fontWeight: [
                '400'
            ]
        },
    });

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

   let test = [];

    

    function handleuploaddocumentstep1(filetype) {
        //debugger;
        return fetch('/api/cms/insight/upload/step-1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(filetype)
        })
            .then(response => {
                if (response.status == 401) {
                    //throw new Error("Server responds with error! Accesstoken.");
                    setSuccessMessageDoc("");
                    setErrorMessageDoc("Something went wrong. Please try again. " + response.message);
                }
                return response.json()
            })
            .then(data => {
                setFormDocS3Link(oldArraya => [...oldArraya, data.s3_link]);
                if (data.link != "") {
                    return data;
                }
            })
            .catch((err) => console.error(err));
    }

    function handleuploaddocumentstep2(link, documentfile) {
        //debugger;
        return fetch(link, {
            method: 'PUT',
            body: documentfile,
        })
            .then(res => {
                if (res.status == 200) {
                    setSuccessMessageDoc("Document uploaded successfully");
                    setErrorMessageDoc("");
                    return res.status;
                } else {
                    setSuccessMessageDoc("");
                    setErrorMessageDoc("Something went wrong. Please try again. " + res.message);
                }

            })
            .catch((err) => console.error(err));
    }

    function handleuploadthumbnailstep1(filetype) {
        return fetch('/api/cms/insight/upload/thumbnail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(filetype)
        })
            .then(response => {
                if (response.status == 401) {
                    //throw new Error("Server responds with error! Accesstoken.");
                    setSuccessMessageThumb("");
                    setErrorMessageThumb("Something went wrong. Please try again. " + response.message);
                }
                return response.json()
            })
            .then(data => {
                setFormS3Link(data.s3_link);
                if (data.link != "") {
                    return data;
                }
            })
            .catch((err) => console.error(err));
    }

    function handleuploadthumbnailstep2(link, thumbnailfile) {
        return fetch(link, {
            method: 'PUT',
            async: true,
            body: thumbnailfile,
        })
            .then(res => {
                if (res.status == 200) {
                    setSuccessMessageThumb("Thumbnail uploaded successfully");
                    setErrorMessageThumb("");
                    return res.status;
                }
                else {
                    setSuccessMessageThumb("");
                    setErrorMessageThumb("Something went wrong. Please try again. " + res.message);
                }
            })
            .catch((err) => console.error(err));
    }

    let filenamedoc = "";
    const handleFileChangeDoc = async (e: ChangeEvent<HTMLInputElement>) => {

        const files = Array.from(e.target.files);

        console.log(files);

        if (e.target.files) {
            setErrorMessageDoc("");
            setSuccessMessageDoc("");

            for (let i = 0; i < files.length; i++) {

                // Here it guarantees that state updated correctly
                //setState(prevStep => prevStep + statusStep);


                let newfile = files[i];
                //setDocFile(newfile.name);
                
                

                //console.log("files>>");
                //console.log(newfile.name);
                //console.log(docfile);


                let filetype_temp = newfile.type.split('/');
                let filetype = filetype_temp[1];

                if (filetype != 'mp4' || filetype != 'flv') {

                    let resdoc1 = await handleuploaddocumentstep1(filetype);
                    let resdoc2 = await handleuploaddocumentstep2(resdoc1.link, newfile);
                    setDocFile(oldArray => [...oldArray, { s3BodyLink: resdoc1.s3_link, s3BodyFileName: newfile.name }]);
                    setFormDocS3LinkName(oldArray => [...oldArray, newfile.name]);

                    filenamedoc = resdoc1.s3_link;

                    setErrorMessageDoc("");
                    setSuccessMessageDoc("Attachment Uploaded Successfully.");

                }
                else {
                    setSuccessMessageDoc("");
                    setErrorMessageDoc("File extension not allowed.");
                }
            }
        }
    };

    function handlefinaluploadinsight(newdocumentdata) {
        return fetch('/api/cms/insight/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(newdocumentdata)
        })
            .then(data => {
                if (data.status == 200) {
                    setErrorMessage("");
                    setSuccessMessage("Insight updated sccessfully...");
                    setOpenAlertNewUpload(true);
                    //popupLinkId.handleClickAlertNewUpload();
                    //popupLinkId.CloseModalUpload();
                    setTimeout(function () { window.location.href = "/admin/insight-list" }, 3000);
                }
                else {
                    setSuccessMessage("");
                    setErrorMessage("Something went wrong. Please try again.");
                }
            });
    }

    let filenamethumb = "";
    const handleFileChangeThumb = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setErrorMessageThumb("");
            setSuccessMessageThumb("");
            let newfile = e.target.files[0];
            setThuFile(newfile.name);
            let filetype_temp = newfile.type.split('/');
            let filetype = filetype_temp[1];

            if (filetype == 'jpg' || filetype == 'jpeg' || filetype == 'png') {

                let resthumb1 = await handleuploadthumbnailstep1(filetype);
                let resthumb2 = await handleuploadthumbnailstep2(resthumb1.link, newfile);

                filenamethumb = resthumb1.s3_link;

                setErrorMessageThumb("");
                setSuccessMessageThumb("Thumbnail Uploaded Successfully.");

            }
            else {
                setSuccessMessageThumb("");
                setErrorMessageThumb("Only jpg, jpeg and png files allowed.");
            }
        }
    };

    const handleFormTags = (value, e) => {
        setFormDataTags(value);
    }

    const handleFormFeatures = (value, e) => {
        setFormDataFeatures(value);
    }

    const handledate = (x, event) => {
        let ldate = JSON.stringify(x);
        setLastupdateDate(ldate.replace(/['"]+/g, ''));
    }

    let testana = [];

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        const data = new FormData(event.currentTarget);

        console.log('here');
        console.log(data.get('title'));
        console.log(data.get('insightbody')); 
        console.log(data.get('author')); 
        console.log(lastUpdated); 
        console.log(formDataTags); 
        console.log(formDataFeatures); 
            
        let author = data.get('author');
        let title = data.get('title');
        let insightbody = data.get('insightbody');
        let thumbnailfile = data.get('thumbnailfile');
        let documentfile = data.get('documentfile');
        let year = data.get('year');
        let featureId = formDataFeatures;
        let updateddate = lastUpdated;
        let realupdateddate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

        //console.log(">>>>>>>");
        //console.log(formDocS3Link);
        
        if (author == "") {
            setErrorMessage("Please select author");
        }
        /*else if (featureId == "") {
            setErrorMessage("Please select features");
        }*/
        else if (title == "") {
            setErrorMessage("Please enter title");
        }
        else if (insightbody == "") {
            setErrorMessage("Please enter content");
        }
        else if (updateddate == "") {
            setErrorMessage("Please select date");
        }
        else if (documentfile.name == "" && formDocS3Link == "") {
            setErrorMessage("Please select attachment");
        }
        else if (thumbnailfile.name == "" && formS3Link == "") {
            setErrorMessage("Please select thumbnail");
        }
        else {
            
            setErrorMessage("");
            setSuccessMessage("");

            var formDataTagsstring = "";

            var formDataFeaturesstring = "";

            if (formDataTags.length > 0) {

                formDataTagsstring = formDataTags.reduce((result, item) => {
                    return `${result}${item.id},`
                }, "");

                formDataTagsstring = formDataTagsstring.substring(0, formDataTagsstring.length - 1);
            }

            if (formDataFeatures.length > 0) {

                formDataFeaturesstring = formDataFeatures.reduce((result, item) => {
                    return `${result}${item.featureId},`
                }, "");

                formDataFeaturesstring = formDataFeaturesstring.substring(0, formDataFeaturesstring.length - 1);
            }


            //console.log(formDataTagsstring);
            //console.log(formDataFeaturesstring);

            console.log('files>>');
            console.log(formDocS3Link);

            var formDocS3LinkTemp = formDocS3Link.filter(elements => {
                return elements !== null;
            });

            if (formDocS3Link.length > 0) {

                testana.push({ s3BodyLink: "", s3ThumbnailLink: formS3Link, s3BodyFileName: "", s3ThumbnailFileName: thufile ? thufile : "thumbnail.jpg" });

                for (let i = 0; i < formDocS3Link.length; i++) {
                    if (formDocS3Link[i].s3BodyLink === null) {

                    }
                    else if (formDocS3Link[i].s3BodyLink === undefined) {

                    }
                    else if (formDocS3Link[i].s3BodyLink !== null || formDocS3Link[i].s3BodyLink !== undefined || formDocS3Link[i].s3BodyLink !== "") {
                        testana.push({ s3BodyLink: formDocS3Link[i].s3BodyLink, s3ThumbnailLink: "", s3BodyFileName: formDocS3Link[i].s3BodyFileName, s3ThumbnailFileName: "" });
                    }
                }

                for (let i = 0; i < formDocS3Link.length; i++) {
                    if (formDocS3Link[i].s3BodyLink === undefined) {
                        testana.push({ s3BodyLink: formDocS3Link[i], s3ThumbnailLink: "", s3BodyFileName: formDocS3LinkName[i], s3ThumbnailFileName: "" });
                    }
                }
            }
            //console.log(testana);

            const newdocumentdata = {
                "id": parseInt(insightid),
                "authorId": author,
                "connectedToFeature": true,
                "mode": formMode,
                "active": true,
                "tags": formDataTagsstring,
                "title": title,
                "htmlcontent": insightbody,
                "displayDate": updateddate,
                "realUpdatedDate": realupdateddate,
                "featureIds": (formDataFeatures.length > 0) ? uniq(formDataFeatures.map(item => item.featureId)) : [],
                "insightAttachments": uniq(testana), 
                "s3HtmlContentLink": "string"
            }

            console.log("docfile");
            console.log(docfile);
            console.log("final data");
            console.log(newdocumentdata);


            if (formMode == "preview") {
                const tempauth = authorData.filter(el => el.userId == author);

                let insighturltemp = title + " " + insightid;
                let insighturl = insighturltemp.replace(/\s+/g, '-').toLowerCase();

                //let previewdata = [];
                //previewdata.push({ insightid: insightid, previewurl: insighturl, displayDate: lastUpdated, displayTitle: title, displayContent: insightbody, displayAuthor: tempauth[0].userName });
                localStorage.setItem("displayDate-" + insightid, lastUpdated);
                localStorage.setItem("displayTitle-" + insightid, title);
                localStorage.setItem("displayContent-" + insightid, insightbody);
                localStorage.setItem("displayAuthor-" + insightid, tempauth[0].userName);
                //localStorage.setItem("insightPreviews", JSON.stringify(previewdata));
                window.open("/insight-preview/" + insighturl, '_blank');


            } else {
                const resfinalupload = await handlefinaluploadinsight(newdocumentdata);
            }

            
        }
        
    };

    const handleClickfordownloaddocument = async (s3_link, filename, use_in_viewer) => {
            const response = await downloaddocumentrequest({
                s3_link,
                filename,
                use_in_viewer
            });
        
    };

    const handleClickfordownloaddocumentAttach = async (s3_link, filename, use_in_viewer) => {
            const response = await downloaddocumentrequestattach({
                s3_link,
                filename,
                use_in_viewer
            });
        
    };

    const handleattachmentdel = (s3BodyLinkDelTmp) => {
        //setOpenAlertDel(true);
        setS3BodyLinkDel(s3BodyLinkDelTmp);
        setDocFile(docfile.filter(item => item.s3BodyLink !== s3BodyLinkDelTmp));
        setFormDocS3Link(formDocS3Link.filter(item => item.s3BodyLink !== s3BodyLinkDelTmp));
    }

    const handleattachmentdelfinal = () => {
        setDocFile(docfile.filter(item => item.s3BodyLink !== s3BodyLinkDel));
        setFormDocS3Link(formDocS3Link.filter(item => item.s3BodyLink !== s3BodyLinkDel));
        handleCloseAlert();
    }
    async function downloaddocumentrequest(downlddocumentrequest) {
        var data = await fetchPost('/api/portal/insight/download', downlddocumentrequest);
        setErrorMessageDoc("");
        if (data.status == "E" && data.message !== null) {
            setErrorMessageDoc("");
            ActionLogout(data.message);
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessageDoc(data.message);
        }
        else {
            setErrorMessageDoc("");
            //get content from url
            var tempdata = await fetchHtml(data);
        }

    }

    async function downloaddocumentrequestattach(downlddocumentrequest) {
        var data = await fetchPost('/api/portal/insight/download', downlddocumentrequest);
        setErrorMessageDoc("");
        if (data.status == "E" && data.message !== null) {
            setErrorMessageDoc("");
            ActionLogout();
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessageDoc(data.message);
        }
        else {
            setErrorMessageDoc("");
            //get content from url
            window.open(data, "_blank");
        }

    }

    let testtag = [];
    var tid = "";
    var tempd = "";;
    const fetchData = async () => {

        var datastart = await fetchGet('/api/cms/insight/start');

        if (datastart.status == "E" && datastart.message !== null) {
            setErrorMessage("");
            ActionLogout(datastart.message);
        } else if (datastart.status == "N" && datastart.message !== null) {
            setErrorMessage(datastart.message);
        }
        else {
            setAuthorData(datastart.authors);
            setFeatureData(datastart.features);
            setTagsData(datastart.tags.filter(el => el.isActive == true));
        }


        var datainsight = await fetchGet('/api/cms/insight/GetInsight?insightId=' + insightid);

        if (datainsight.status == "E" && datainsight.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
            ActionLogout(datainsight.message);
        } else if (datainsight.status == "N" && datainsight.message !== null) {
            setErrorMessage(datainsight.message);
            setDisplayLoader(false);
        }
        else
        {
            setDataInsights(datainsight);
            setLastupdateDate(datainsight.insightmodel.displayDate);
            setFormDocS3Link(datainsight.insightattachments);
            setFormS3Link(datainsight.insightmodel.s3ThumbnailLink);
            setDocFile(datainsight.insightattachments);

            var temp = await handleClickfordownloaddocument(datainsight.insightmodel.s3HtmlContentLink, currentinsight, true);
            setDisplayLoader(false);

            var tempdatatag = datainsight.insightmodel.tags.split(",");
            
            if (tempdatatag !== "") {
                for (var i = 0; i < tempdatatag.length; i++) {
                    tid = tempdatatag[i];
                    console.log(tid);
                    tempd = datastart.tags.filter(el => el.id == tid);
                    if (tempd[0] !== undefined) {
                        testtag.push({ id: tempd[0].id, name: tempd[0].name, isActive: tempd[0].isActive });
                    }
                }
            }

            //setFormDataTags(datastart.tags.filter(el => el.id == 2));
            setFormDataTags(testtag);
            setFormDataFeatures(datainsight.featureList);
            //console.log(datastart.tags.filter(el => el.isActive == true));

            if (datainsight.insightattachments.length > 0) {
                for (let i = 0; i < datainsight.insightattachments.length; i++) {
                     setFormDocS3LinkName(oldArray => [...oldArray, datainsight.insightattachments[i].s3BodyFileName]);
                }
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Header />

            <Snackbar open={openAlertNewUpload} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity="success" className="w-100">
                    Insight Updated Successfully!
                </Alert>
            </Snackbar>

            <Snackbar open={openAlertDel} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} className="stardardalert">
                <Alert icon={false} severity="error" variant="standard">
                    <span>Are you sure you want to delete the attachment?</span>
                    <span>
                        <Button variant="contained" className="smlbtn bg-blue-gradient" onClick={() => handleattachmentdelfinal()}><span>Yes</span></Button>
                        <Button variant="contained" className="smlbtn searchbtn" onClick={() => setOpenAlertDel(false)} style={{ marginLeft: '15px' }}><span>No</span></Button>
                    </span>

                </Alert>

            </Snackbar>

            
            <Container component="main" maxWidth="lg" className='main-container'>
                
                <Container className='whtiebgmain'>
                    <Grid xs={12}>  
                     <Link to="/admin/insight-list"><Text type='h2' className=' d-inline-block backlink mr-1'><FontAwesomeIcon icon={faCircleArrowLeft} size='10px' /> </Text></Link>
                <Text type='h2'className='text-gradient d-inline-block mb-3 '>Insight Update</Text>
                </Grid>
             
                    <Box component="form" onSubmit={handleSubmit} noValidate className='whtiebgemailfrom p-4' >
                        <Grid container spacing={3}  >
                            <Grid item sm={6}>
                                <Grid container spacing={3} className='cmsinput'>
                                    <Grid item xs={12} sm={8} md={9}>
                                        {authorData && datainsights && !displayLoader &&
                                        <Select 
                                                className='insightselect '
                                                style={{ backgroundColor: '#fff' }}                                              
                                                name='author'
                                                value={datainsights.insightmodel.authorId}
                                                MenuProps={{
                                                    disableScrollLock: true,
                                                }}
                                            >
                                                {authorData && authorData.map(i => {
                                                    return (
                                                        <MenuItem value={i.userId} >{i.userName}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        }
                                     
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className='cmsinput'>
                                    <Grid item xs={12} sm={8} md={9}>
                                        
                                        {tagsData &&
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                options={tagsData}
                                                getOptionLabel={(option) => option.name}
                                                value={uniq(formDataTags)}
                                                onChange={(a, b) => handleFormTags(b)}
                                                isOptionEqualToValue={(option, value) => option.id == value.id}
                                                filterSelectedOptions
                                                noOptionsText={'Please type to get the option'}
                                                name="tags"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        placeholder="Tags"
                                                        className="form-control cmsinputtg cmsinputborder"
                                                    />
                                                )}
                                            />}
                                        
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className='cmsinput mb-0'>
                                    <Grid item xs={12} sm={8} md={9}>
                                        {featureData &&
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                options={featureData}
                                                getOptionLabel={(option) => option.featureName}
                                                value={uniq(formDataFeatures)}
                                                onChange={(a, b) => handleFormFeatures(b)}
                                                isOptionEqualToValue={(option, value) => option.featureId == value.featureId}
                                                filterSelectedOptions
                                                noOptionsText={'Please type to get the option'}
                                                name="commodities"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        placeholder="Features"
                                                        className="form-control cmsinputtg cmsinputborder"
                                                    />
                                                )}
                                            />}

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} className=''>
                                <Grid container spacing={3} className='cmsinput datepicker'>

                                    <Grid item xs={12} className='d-flex justify-content-end '>
                                        <InputLabel className='d-flex align-items-center  pr-3'>
                                            Date
                                        </InputLabel>
                                        {datainsights && !displayLoader &&
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker label="MM/DD/YYYY" format="MM/DD/YYYY" className='cmsinputtg datepicker' defaultValue={dayjs(datainsights.insightmodel.displayDate)} onChange={(x, event) => handledate(x, event)} />
                                                </DemoContainer>
                                            </LocalizationProvider>}

                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} className='cmsinput'>

                                    <Grid item xs={12} className='d-flex justify-content-end positionRelative'>
                                        <InputLabel className='d-flex align-items-center  pr-3'>
                                            Attachments
                                        </InputLabel>
                                        <Button variant="contained" component="label" className="bg-blue-gradient smlbtn" >
                                            Upload
                                            <input hidden multiple accept="pdf/*" type="file" name="documentfile" onChange={handleFileChangeDoc} />
                                        </Button>
                                        {errorMessageDoc && <Text type='small' className="errormsg positionAbsolute pt-5 zIndex-1" id='errormsgdoc'> {errorMessageDoc} </Text>}
                                        {successMessageDoc && <Text type='small' className="successmsg positionAbsolute pt-5 zIndex-1" id='successmsgdoc'> {successMessageDoc} </Text>}
                                        </Grid>
                                        

                                </Grid>
                                <Grid container spacing={3} className='cmsinput '>
                                    <Grid item xs={12} className='d-flex justify-content-end positionRelative'>
                                        <InputLabel className='d-flex align-items-center pr-3'>
                                            Thumbnail
                                        </InputLabel>
                                        <Button variant="contained" component="label" className="bg-blue-gradient smlbtn" >
                                            Upload
                                            <input hidden accept="image/*" type="file" name="thumbnailfile" onChange={handleFileChangeThumb} />
                                        </Button>
                                        {errorMessageThumb && <Text type='small' className="errormsg positionAbsolute pt-5 zIndex-1" id='errormsgthumb'> {errorMessageThumb} </Text>}
                                        {successMessageThumb && <Text type='small' className="successmsg positionAbsolute pt-5 zIndex-1" id='successmsgthumb'>{thufile && <Text className='text-right pb-1'> {thufile} </Text>} {successMessageThumb} </Text>}
                                        {formS3Link && <Link target="_blank" className="btn-secondary viewbtn ml-2" to={"https://ci-thumbnail.s3.us-west-2.amazonaws.com/" + formS3Link}>VIEW</Link>}
                                        
                                        </Grid>
                                    

                                </Grid>
                            </Grid>


                            {datainsights && !displayLoader && <Grid item xs={12} className='cmsinput'>

                                {docfile && docfile.length > 0 &&
                                    <Grid className="whtiebgmain p-2 mb-2 cmsinputborder">
                                        {docfile && docfile.length > 0 && docfile.map((filesdata, index) => {
                                            if (filesdata.s3BodyLink != null) {
                                                return (<Text className='attchmentbtn p-1 mr-1' type='small'><Link onClick={() => handleClickfordownloaddocumentAttach(filesdata.s3BodyLink, filesdata.s3BodyFileName != null ? filesdata.s3BodyFileName : datainsights.insightmodel.title, true)}>{filesdata.s3BodyFileName != null ? filesdata.s3BodyFileName : filesdata.s3BodyLink}</Link> <Link onClick={() => handleattachmentdel(filesdata.s3BodyLink) }><FontAwesomeIcon icon={faCircleXmark} className="ml-1" /></Link></Text>)
                                            }
                                        })
                                        }
                                    </Grid>
                                }
                                
                            <TextField
                                    margin="none"
                                    fullWidth
                                    id="title"
                                    name="title"
                                    className="form-control cmsinputtg cmsinputborder mb-3 mt-1"
                                    variant='filled'
                                    placeholder='Title'
                                    defaultValue={datainsights.insightmodel.title}
                                />
                                <textarea placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry." className="formControl cmsinputtg cmsinputborder inputhtml" name="insightbody" rows={8} id="insightContentgrid" />
                            </Grid>}
                        </Grid>
                        <Grid container className='contbtn  mt-2'>
                            
                            <Button
                                type="submit"
                                disableElevation
                                variant="contained"
                                onClick={() => setFormMode("draft")}
                                className="btn-gradient mr-2"
                            >save as a draft</Button>

                            <Button
                                type="submit"
                                disableElevation
                                variant="contained"
                                onClick={() => setFormMode("preview")}
                                className="btn-gradient mr-2"
                            >Preview</Button>



                            <Button
                                type="submit"
                                disableElevation
                                variant="contained"
                                onClick={() => setFormMode("publish")}
                                className="btn-gradient "
                            >publish</Button>


                        </Grid>
                        {errorMessage && <Text type='small' className="errormsg" id='errormsg'> {errorMessage} </Text>}
                        {successMessage && <Text type='small' className="successmsg" id='successmsg'> {successMessage} </Text>}
                    
                    </Box>


                </Container>
            </Container>
            <Footer />
        </ThemeProvider>
    )
}

export default InsightCreation_Page
