import React, { useState } from "react";
import '../assets/css/form.css';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { fetchPost } from "../services/apiCalls";

export default function ContactForm() {

    const [email, setCEmail] = useState("");
    const [name, setCName] = useState("");
    const [subject, setCSubject] = useState("");
    const [message, setCMessage] = useState("");
    const [errorCMessage, setCErrorMessage] = useState("");
    const [successCMessage, setCSuccessMessage] = useState("");

    const handleSubmitContactForm = async (event) => {
        event.preventDefault();
        setCErrorMessage("");
        setCSuccessMessage("");
       
        if (email == "") {
            setCSuccessMessage("");
            setCErrorMessage("Please check your Email!");
        }
        else if (name == "") {
            setCSuccessMessage("");
            setCErrorMessage("Please check your Name!");
        }
        else if (subject == "") {
            setCSuccessMessage("");
            setCErrorMessage("Please check your subject!");
        }
        else if (message == "") {
            setCSuccessMessage("");
            setCErrorMessage("Please check your message!");
        }
        else {
            const response = await fetchPost('/api/portal/contact-form', {
                name,
                email,
                subject,
                message
            });

            if (response.status == "N" && response.message !== null) {
                setCSuccessMessage("");
                setCErrorMessage(response.message);
            }
            else {
                setCErrorMessage("");
                setCSuccessMessage(response.message);
                setCName("");
                setCSubject("");
                setCMessage("");
                setCEmail("");
            }
        }
    }

    return(
        <Grid container spacing={2} sx={{ mt: 2 }} component="form" onSubmit={handleSubmitContactForm} noValidate>
            <Grid item xs={12} md={6}>
                <input placeholder="Email*" className="formControl" name="email" autoComplete="email" value={email} onChange={e => setCEmail(e.target.value)} required />
            </Grid>
            <Grid item xs={12} md={6}>
                <input placeholder="Name*" className="formControl" name="name" value={name}  onChange={e => setCName(e.target.value)} required />
            </Grid>
            <Grid item xs={12}>
                <input placeholder="Subject" className="formControl" name="subject" value={subject} onChange={e => setCSubject(e.target.value)} required />
            </Grid>
            <Grid item xs={12}>
                <textarea label="Message" placeholder="Message" className="formControl" name="message" rows={3} value={message} onChange={e => setCMessage(e.target.value)} required />
            </Grid>
            <Grid item xs={12}>
            {errorCMessage && <Text type='small' sx={{ color: "red" }} id='errormsg'> {errorCMessage} </Text>}
            {successCMessage && <Text type='small' sx={{ color: "green" }} id='successmsg'> {successCMessage} </Text>}
            </Grid>
            <Grid item xs={12}>
                <Button type="submit" disableElevation variant="contained" className="btn-gradient">Submit</Button>
            </Grid>
        </Grid>
    );
}