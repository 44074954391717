
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../admin/include/Header.js';
import Footer from '../admin/include/Footer.js';

// import ImgBottom from '../assets/images/product-bg.png';

// import Dashboard from '../assets/components/Dashboard.js'

import Dashboard from '../assets/components/Dashboard.js'
// import SearchIcon from '@mui/material/Search';

const accessToken = localStorage.getItem('accessToken');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

//end search bar 
export default function GetProductData() {


    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="false" className="pr-0 pl-0 positionRelative">
                <Header />

       
                <Container component="main" maxWidth="lg" className='main-container' >
                       
                        <Dashboard />
                       
                    </Container>

                       
                 

              
                {/* <img src={ImgBottom} alt="Costmine Intelligence" className='innerbottom'  /> */}
                <Footer />
            </Container>
        </ThemeProvider>
    );
}