import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../include/Header.js';
import Footer from '../include/Footer.js';
import SearchIcon from '@mui/icons-material/Search';
import ImgBottom from '../assets/images/dashboard-bg.png';
import Insights from '../assets/components/insightspost.js'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { fetchGet } from "../services/apiCalls.js";
import ActionLogout from '../component/ActionLogout.js';



// import SearchIcon from '@mui/material/Search';

const accessToken = localStorage.getItem('accessToken');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

//end search bar 
export default function GetProductData() {

    const [errorMessage, setErrorMessage] = useState("");
    const [datainsights, setDataInsights] = useState([]);
    const [dataauthors, setDataAuthors] = useState([]);
    const [searchString, setSearchstring] = useState("");

    const fetchData = async () => {

        var dataauthors = await fetchGet('/api/cms/insight/GetAuthors');

        if (dataauthors.status == "E" && dataauthors.message !== null) {
            setErrorMessage("");
            ActionLogout(dataauthors.message);
        } else if (dataauthors.status == "N" && dataauthors.message !== null) {
            setErrorMessage(dataauthors.message);
        }
        else {
            setDataAuthors(dataauthors);
        }

        var searchquery = "?";

        if (searchString != "") {
            searchquery += "&search=" + searchString;
        }

        var datainsight = await fetchGet('/api/cms/insight/SearchInsight' + searchquery);

        if (datainsight.status == "E" && datainsight.message !== null) {
            setErrorMessage("");
            ActionLogout(datainsight.message);
        } else if (datainsight.status == "N" && datainsight.message !== null) {
            setErrorMessage(datainsight.message);
        }
        else {
            setDataInsights(datainsight.filter(el => el.active == true && el.mode == "publish"));
        }

    }

    const [visible, setVisible] = useState(8);

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4);
    };

    const handleChange = (event) => {
        setSearchstring(event.target.value);
    };

    const handlesearch = () => {
        fetchData();
    }

    function enterpressalert(e) {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 13) { //Enter keycode
            handlesearch();
        }
    }

 

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="false" className="pr-0 pl-0 positionRelative">
                <Header />

                <Container component="main" maxWidth="xl" className='main-container'  >

                    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }} className='instsrch'>

                        <TextField className='mb-4 searchbar'
                            type="search"
                            placeholder='Search Insight'
                            name="searchstring"
                            onChange={handleChange}
                            onKeyPress={(e) => enterpressalert(e)}
                           
                            InputProps={{
                              
                                endAdornment: (
                                    <InputAdornment position="start"  >
                                        <SearchIcon onClick={handlesearch} />
                                    </InputAdornment>

                                ),
                            }}
                           />
                    </Grid>

                    <Container maxWidth="xl" className='p-3 latestentry'>                       
                        <Grid container spacing={3} >

                            {datainsights && !errorMessage && datainsights.length > 0 && datainsights.slice(0, 4).map(insightsdata => {
                                const insightAuthordata = dataauthors.filter(el => el.userId == insightsdata.authorId);
                                return (
                                    <Insights insightAuthor={insightAuthordata[0].userName} insightDate={insightsdata.displayDate} insightTitle={insightsdata.title} insightText={insightsdata.snippettext != null ? insightsdata.snippettext : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 2"} insightId={insightsdata.id} thumbnailurl={insightsdata.s3ThumbnailLink} />
                                )
                            })
                            }

                            
                        </Grid>
                    </Container>

                    {datainsights && datainsights.length > 4 &&
                    <Container maxWidth="xl" className='whtiebg p-3 mt-4' >
                        
                        <Grid container spacing={3} >

                            {datainsights && !errorMessage && datainsights.length > 4 && datainsights.slice(4, visible).map(insightsdata => {
                                const insightAuthordata = dataauthors.filter(el => el.userId == insightsdata.authorId);
                                return (
                                    <Insights insightAuthor={insightAuthordata[0].userName} insightDate={insightsdata.displayDate} insightTitle={insightsdata.title} insightText={insightsdata.snippettext != null ? insightsdata.snippettext :"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 2"} insightId={insightsdata.id} thumbnailurl={insightsdata.s3ThumbnailLink} />
                                )
                            })
                            }

                        </Grid>

                            {datainsights && datainsights.length > 8 && visible < datainsights.length &&
                            <Grid className='text-center mt-4'><Button disableElevation variant="contained" className="btn-gradient" onClick={showMoreItems}>Load More</Button></Grid>
                            }

                    </Container>
                    }
                    
                </Container>
                <img src={ImgBottom} alt="Costmine Intelligence" className='innerbottom' />
                <Footer />
            </Container>
        </ThemeProvider>
    );
}