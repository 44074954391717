import React from 'react'
import { Link } from "react-router-dom";

import Grid from '@mui/material/Grid';

import Text from '../components/ui/atoms/Text.jsx';
import imginsights from '../images/CM-Solar.webp';
import {format} from 'date-fns';

const insightblock = (props) => {
    let id = props.insightId;
    let username = props.insightAuthor;
    let title = props.insightTitle;
    let displaydate = (props.insightDate != null ? format(new Date(props.insightDate), 'MM/dd/yyyy') : "12/01/2023");

    let insighturltemp = title + " " + id;
    let insighturl = insighturltemp.replace(/\s+/g, '-').toLowerCase();
    let thumbnailurl = props.thumbnailurl != "" ? "https://ci-thumbnail.s3.us-west-2.amazonaws.com/" + props.thumbnailurl : "https://ci-thumbnail.s3.us-west-2.amazonaws.com/13c3d650-3ab5-40b5-bdc3-d12b95e529c9.jpg";

    
    return (
        <Grid item xs={12} sm={4}>
            <Grid item className='dbinsight'>
                <Grid item className="verson" ><Text type="smaller" className="p-2">{displaydate}</Text> <Text type="smaller" className="p-2">{username}</Text></Grid>
                <Grid item className="insighthe p-2" >
                    <Link to={"/insight/" + insighturl}><Text type='smallheading'>{title}</Text></Link>
                </Grid>
                <Link to={"/insight/" + insighturl}><img src={thumbnailurl} alt="Costmine Intelligence" /></Link>
            </Grid>

        </Grid>
    )
}

insightblock.defaultProps = {
    username: "Test Author"
};

export default insightblock
