import React, { useState } from "react";
import '../assets/css/form.css';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link} from "react-router-dom";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Text from '../assets/components/ui/atoms/Text';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Header from './include/Header.js';
import Footer from './include/Footer.js';

export default function WelcomeEmail_Page() {
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Semplicita Pro'
            ],
            fontWeight: [
                '400'
            ]
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <Header />
            <Container component="main" maxWidth="lg" className='main-container'  >
                
                <Container className='whtiebgmain'>
                    <Grid xs={12}>  
                     <Link to="#"><Text type='h2' className='back d-inline-block'><FontAwesomeIcon icon={faCircleArrowLeft} size='10px' /> </Text></Link>
                <Text type='h2'className='text-gradient d-inline-block mb-3 '>WELCOME</Text>
                </Grid>
        <Grid container  component="form" noValidate className='gridbg p-0 btmrd'>
            <Box className= 'p-2  w-100' >
            <Text>Last Updated Date : 25/06/2023</Text>
            <TextField fullWidth label="SUBJECT" className="emailformControl mt-3 " id="fullWidth" />
            <textarea placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry." className="emailformControl formControl mt-3" name="message" rows={12}  required />
            <Grid item xs={12} className="prebtn">
                <Button type="submit" disableElevation variant="contained" className="btn-gradient mt-3" style={{marginRight:'5px'}}>PREVIEW</Button>
            <Button type="submit" disableElevation variant="contained" className="btn-gradient mt-3">Publish</Button>
</Grid>
            </Box>
          
        </Grid>
        </Container>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}
