import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import ActionLogout from '../component/ActionLogout.js';
import { fetchPost } from "../services/apiCalls";

const ViewDocument = () => {
    const accessToken = localStorage.getItem('accessToken');

    const [searchParams, setSearchParams] = useSearchParams();

    let DocumentLink = searchParams.get("doclink");
    let DocumentName = searchParams.get("docname");

    async function viewdocumentrequest() {

        const params = {
            s3_link: DocumentLink,
            filename: DocumentName,
            use_in_viewer: true
        };

        var data = await fetchPost('/api/portal/document/download', params);
        if (data.status == "E" && data.message !== null) {
            ActionLogout(data.message);
        } else if (data.status == "N" && data.message !== null) {
            alert(data.message);
        }
        else {
            window.location.href = data;
        }
    }

    useEffect(() => {
        viewdocumentrequest();
    }, [])

};

export default ViewDocument;