import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../include/Header.js';
import Footer from '../include/Footer.js';
import ImgBottom from '../assets/images/login-bg.png';
import Pdf from '../assets/images/demo.png';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useSearchParams } from "react-router-dom";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import Button from '@mui/material/Button';
import SignIn from './Login.js';

import PdfviewerWeb from '../component/PdfviewerWeb.js';

const accessToken = localStorage.getItem('accessToken');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});



export default function PdfWebViewer() {

    
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentProductData, setCurrentProductData] = useState([]);
    let ProductId = searchParams.get("typeid");
    let DocumentLink = searchParams.get("doclink");
    let DocumentName = searchParams.get("docname");

    const fetchData = () => {
        return fetch('/api/portal/product-dashboard', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        })
            .then((response) => response.json())
            .then(data => {
                setCurrentProductData(data.find(({ id }) => id == ProductId));
            });
    }

    useEffect(() => {
        fetchData();
    }, []);


    if (!accessToken) {
        return <SignIn />
    }
    
    return (
        
        <ThemeProvider theme={theme}>
            <Container maxWidth="false" sx={{pr:'0!important', pl:'0!important', position:'relative'}}>
            <Header/>         
            <Container component="main" maxWidth="lg"  style={{ marginTop:'30px', }}  >
            <Grid container>
            <Grid item xs={12} sm={6}>
                            <Text type="h2" sx={{ mb: .8 }} ><Link to="/dashboard" style={{ textDecoration: 'none' }}><span className='bluetext'>Data <FontAwesomeIcon icon={faAngleRight} /></span></Link></Text>
                            <Text type="h1" variant="h1" sx={{ mb: 2.8 }} ><Link to={"/data?typeid=" + ProductId} style={{ textDecoration: 'none' }}><span className='text-gray-gradient'>{currentProductData.name}</span></Link></Text> 
            </Grid>
            <Grid item xs={12} sm={6}  className='lstdt'>
                            <Link to={"/data?typeid="+ProductId}><Text className='back'><FontAwesomeIcon icon={faCircleArrowLeft} className='text-gradient' /> </Text></Link>
            
            </Grid>
            </Grid>
            <Container className='whtiebg' >
            
            <Grid container spacing={3}> 
                            <Grid item xs={12} ><text type='p'>{currentProductData.description}</text> </Grid>
           
                         
            </Grid>
            </Container>

            <Container className='whtiebg' style={{marginTop:'30px'}}>
            
            <Grid container spacing={3}> 
            <Grid item xs={12}>
            <img src={Pdf} alt="Costmine Intelligence"  style={{width:'100%', display:'none'}} />

                                <PdfviewerWeb datalink={DocumentLink} dataname={DocumentName}  />

                                

            </Grid>
                            <Grid item xs={12} className='backbutton' style={{ display: 'none' }}>
           
            <Button
                type="submit"                
                disableElevation
                variant="contained"
                className="btn-secondary"
                sx={{ mt: 2, mb: 2}}><FontAwesomeIcon icon={faCircleArrowLeft} style={{marginRight:'10px'}}/> Mauris egestas placerat  no...</Button>
                <Button
                type="submit"                
                disableElevation
                variant="contained"
                className="btn-secondary"
                sx={{ mt: 2, mb: 2  }}>Mauris egestas placerat no... <FontAwesomeIcon icon={faCircleArrowRight} style={{marginLeft:'10px'}}/></Button>
            </Grid>
                         
            </Grid>
            </Container>
            </Container>

            
            
            <img src={ImgBottom} alt="Costmine Intelligence" className='imgbottom'  style={{zIndex:'-999'}} />
            <Footer/>            
            
            </Container>
        </ThemeProvider>
    );
}