import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Header from './include/Header';
import Footer from './include/Footer.js';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import ActionLogout from '../component/ActionLogout.js';


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import CheckIsLogin from './component/CheckIsLogin.js';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


// import ReactPaginate from 'react-paginate';

import { fetchGet, fetchPost, fetchDelete, fetchPostStatus } from "../services/apiCalls";

const accessToken = localStorage.getItem('accessToken');

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle
            {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" onClick={onClose} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function Dashbaord() {

    let CurrentYear = new Date().getFullYear();
    const [open, setOpen] = React.useState(false);
    const [responseDefaultData, setResponseDefaultData] = useState();
    const [modalDescription, setModelDescription] = useState();
    const [modalTitle, setModelTitle] = useState();
    const [modalUrl, setModelUrl] = useState();
    const [modalTarget, setModelTarget] = useState();
    const [modalPid, setModelPid] = useState();
    const [modalIcon, setModalIcon] = useState('');
    const [productId, setproductId] = useState();
    const [year, setnewyear] = useState(new Date().getFullYear());
    const [newSeriesId, setNewSeriesId] = useState('');
    const [CurrentPage, setCurrentPage] = useState(0);
    const lproductID = (localStorage.getItem('productId') ? localStorage.getItem('productId') : 1);
    const lyear = (localStorage.getItem('year') ? localStorage.getItem('year') : new Date().getFullYear());
    const [dataproducts, setDataProducts] = useState([]);
    const [dataauthors, setDataAuthors] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchString, setSearchstring] = useState("");
    const [searchDate, setSearchDate] = useState("");
    const [searchAuthor, setSearchAuthor] = useState("");
    const [tagsData, setTagsData] = useState([]);
    const [authorData, setAuthorData] = useState([]);
    const [formDataTags, setFormDataTags] = useState("");
    const [authorValue, setAuthorValue] = useState("");
    
    
    

    const handleClickOpen = (e) => {
        setOpen(true);
        const datadesc = e.currentTarget.getAttribute("data-description");
        const datatit = e.currentTarget.getAttribute("data-title");
        const dataurl = e.currentTarget.getAttribute("data-url");
        const datatarget = e.currentTarget.getAttribute("data-target");
        const dataicon = e.currentTarget.getAttribute("data-icon");
        const datapid = e.currentTarget.getAttribute("data-pid");
        setModelDescription(datadesc);
        setModalIcon(dataicon);
        setModelTitle(datatit);
        setModelUrl(dataurl);
        setModelTarget(datatarget);
        setModelPid(datapid);
    };

    const handleClose = () => { setOpen(false); };
    const [responseData, setResponseData] = useState([]);
    const [dataClickedOn, setDataClickedOn] = useState([]);

    const fetchDataStart = async () => {
        
        var datastart = await fetchGet('/api/cms/insight/start');

        if (datastart.status == "E" && datastart.message !== null) {
            setErrorMessage("");
            ActionLogout(datastart.message);
        } else if (datastart.status == "N" && datastart.message !== null) {
            setErrorMessage(datastart.message);
        }
        else {
            //setStartData(datastart);
            setAuthorData(datastart.authors);
            //setFeatureData(datastart.features);
            setTagsData(datastart.tags.filter(el => el.isActive == true));

            setResponseDefaultData(datastart);
        }

    }

    const fetchData = async () => {

        var searchquery = "?";

        if (searchString != "") {
            searchquery += "&search=" + searchString;
        }
        if (searchDate != "") {
            searchquery += "&date=" + searchDate;
        }
        if (searchAuthor != "") {
            searchquery += "&authorId=" + searchAuthor;
            console.log('dsdsdsdsds');
            console.log(searchAuthor);
        }
        if (formDataTags != "") {
            var formDataTagsstring = "";

            var formDataFeaturesstring = "";

            if (formDataTags.length > 0) {

                formDataTagsstring = formDataTags.reduce((result, item) => {
                    return `${result}${item.id},`
                }, "");

                formDataTagsstring = formDataTagsstring.substring(0, formDataTagsstring.length - 1);
            }
            searchquery += "&tags=" + formDataTagsstring;
        }

        
        

        var dataauthorss = await fetchGet('/api/cms/insight/GetAuthors');

        if (dataauthorss.status == "E" && dataauthorss.message !== null) {
            setErrorMessage("");
            ActionLogout(dataauthorss.message);
        } else if (dataauthorss.status == "N" && dataauthorss.message !== null) {
            setErrorMessage(dataauthorss.message);
        }
        else {
            setDataAuthors(dataauthorss);
        }

        var data = await fetchGet('/api/cms/insight/SearchInsight' + searchquery);
        setResponseData(data.filter(el => el.active == true));
    }

    const handledelete = async (id) => {

        if (id != "") {
            var response = await fetchDelete("/api/cms/insight/delete/" + id);
            if (response.status == 200) {
                handleClickAlert();
                fetchData();
            }
        }
    }

    useEffect(() => {
        fetchData();
        fetchDataStart();
    }, [])

    const [responseProductData, setResponseProductData] = useState([]);

    const handleClickforrightside = async (productId, year) => {
        const response = await fethproductlist({
            productId,
            year
        });
        setCurrentPage(0);
        setproductId(productId);
        setnewyear(year);
        setDataClickedOn(productId);
        localStorage.setItem('productId', productId);
        localStorage.setItem('year', year);
    };

    
    async function fethproductlist(productrequest) {
        var data = await fetchPost('/api/cms/fetch-document-list', productrequest);
        setCurrentPage(0);
        setResponseProductData(data);
        let lastseriesid = data.length;
        let newseriesid = lastseriesid + 1;
        setNewSeriesId(parseInt(newseriesid));
    }


    const [Modal2, setOpenModal2] = React.useState(false);
    const [ModalView, setOpenModalView] = React.useState(false);
    const [ModalUpload, setOpenModalUpload] = React.useState(false);
    const [popupLinkId, SetPopupLinkId] = React.useState('');
    const [popups3LinkId, SetPopups3LinkId] = React.useState('');
    const [popupSeriesId, SetpopupSeriesId] = React.useState('');

    const ClickOpenModal2 = (linkid, s3linkid, seriesid) => {
        setOpenModal2(true);
        SetPopupLinkId(linkid);
        SetPopups3LinkId(s3linkid);
        SetpopupSeriesId(seriesid);
    };

    const ClickOpenModalView = (linkid) => {
        setOpenModalView(true);
        SetPopupLinkId(linkid);
    };

    const ClickOpenModalUpload = () => {
        setOpenModalUpload(true);
    };

    const CloseModal2 = () => { setOpenModal2(false); };
    const CloseModalView = () => { setOpenModalView(false); };
    const CloseModalUpload = () => { setOpenModalUpload(false); };
    const CloseModalInsightEmail = () => { CloseModalInsightEmail(false); };

    function handlePageClick({ selected: selectedpage }) {
        setCurrentPage(selectedpage);
    }

    

    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertNewUpload, setOpenAlertNewUpload] = useState(false);
    const [openAlertEditUpload, setOpenAlertEditUpload] = useState(false);
    const [openAlertEditDesc, setOpenAlertEditDesc] = useState(false);

    const handleClickAlert = () => {
        setOpenAlert(true);
    };

    const handleClickAlertNewUpload = () => {
        setOpenAlertNewUpload(true);
    };

    const handleClickAlertEditUpload = () => {
        setOpenAlertEditUpload(true);
    };

    const handleClickAlertEditDesc = () => {
        setOpenAlertEditDesc(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        setOpenAlertNewUpload(false);
        setOpenAlertEditUpload(false);
        setOpenAlertEditDesc(false);
    };

    const handledate = (x, event) => {
        let ldate = JSON.stringify(x);
        console.log('dsds');
        setSearchDate(ldate.replace(/['"]+/g, ''));
    }

    const handleFormTags = (value, e) => {
        setFormDataTags(value);
    }

    const handleSearchSubmit = async (event) => {
        console.log('search');
        event.preventDefault();
        setErrorMessage("");
        //setSuccessMessage("");
        const data = new FormData(event.currentTarget);

        console.log(data.get('searchstring'));
        /*console.log(data.get('insightbody')); 
        console.log(data.get('author')); 
        console.log(lastUpdated); 
        console.log(formDataTags); 
        console.log(formDataFeatures); */



        let searchstring = data.get('searchstring');

        fetchData();

        /*if (searchstring != "") {
            fetchData();               
        }*/
    }

    CheckIsLogin();
    return (
        <ThemeProvider theme={theme}>
            <Header />

            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity="success" className="w-100">
                    Document Deleted Successfully!
                </Alert>
            </Snackbar>

            
           
            <Container component="main" maxWidth="lg" className='main-container'>
                <Container className='whtiebg'>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        
                        <Button  fullWidth  className="uploadbtn text-white pl-2 pr-2" href='/admin/insight-creation'>
                                Add insights <FontAwesomeIcon icon={faCirclePlus} />
                            </Button>
                        </Grid>
                    </Grid>
                    <Box component="form" onSubmit={handleSearchSubmit} noValidate className='gridbg p-2 pt-1 mt-3 mb-3'>
                       
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={8}  md={9} className='cmsinput mt-1'>
                                <TextField
                                    margin="none"
                                    fullWidth
                                    id="InsightName"
                                    name="searchstring"
                                    className="form-control cmsinputtg cmsinputborder"
                                    variant='filled'
                                    placeholder='Insight Name'
                                    onChange={e => setSearchstring(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} className='cmsinput datepicker searchinsight'> 
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DemoContainer components={['DatePicker']}>
                                        <DatePicker format="MM/DD/YYYY" className='cmsinputtg emaildate w-100' onChange={(x, event) => handledate(x, event)} />
                                            </DemoContainer>
                                        </LocalizationProvider>

                            </Grid>
                            <Grid item xs={12} sm={4} md={5} className='cmsinput'>
                                {tagsData &&
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={tagsData}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(a, b) => handleFormTags(b)}
                                        isOptionEqualToValue={(option, value) => option.id == value.id}
                                        filterSelectedOptions
                                        noOptionsText={'Please type to get the option'}
                                        name="searchTag"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="Tags"
                                                className="form-control cmsinputtg cmsinputborder"
                                            />
                                        )}
                                    />}

                            </Grid>
                            <Grid item xs={12} sm={4} md={5} className='cmsinput '> 
                            

                                {authorData &&
                                    <Select
                                        className='insightselect'
                                        onChange={e => setSearchAuthor(e.target.value)}
                                        name='searchAuthor'
                                        style={{ backgroundColor: '#fff' }}
                                        displayEmpty
                                        value={searchAuthor}
                                        MenuProps={{
                                            disableScrollLock: true,
                                        }}
                                    >
                                        <MenuItem value="">Select Author</MenuItem>
                                        {authorData && authorData.map(i => {
                                            return (
                                                <MenuItem value={i.userId} >{i.userName}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                }
                               
                          

                            </Grid>
                            <Grid item xs={12} sm={4} md={2} className='cmsinput '> 
                          
                                <Button variant="contained" className="searchbtn button text-white w-100 pt-1 pb-1" type="submit" ><FontAwesomeIcon icon={faMagnifyingGlass} className='mr-1' /> Search</Button>
                          

                            </Grid>
                          
                        </Grid>
                      
                    </Box>

                    {responseData && responseData.map(insightsdata => {
                        const insightAuthordata = dataauthors.filter(el => el.userId == insightsdata.authorId);
                        console.log('here>>');
                        console.log(insightAuthordata);

                        let insighturltemp = insightsdata.title + " " + insightsdata.id;
                        let insighturl = insighturltemp.replace(/\s+/g, '-').toLowerCase();

                        return (
                            <Grid item xs={12}>
                                <Grid item className='productlist productlistnew'>
                                    <Text className='Drafttext' type='small'>{insightsdata.mode == "draft" ? "Draft" : ""}</Text>
                                    <Grid container spacing={3} >
                                        <Grid item xs={3} className='d-flex align-items-center'>
                                            <Text className="pl-2">{insightsdata.title}</Text>
                                        </Grid>
                                        <Grid item xs={3} className=' d-flex align-items-center'>
                                            <Text >{insightAuthordata[0].userName}</Text>
                                        </Grid>
                                        <Grid item xs={6} className='ilcon'>
                                            <Grid className='iconlist'>
                                                <Link to="/admin/insight-newsletter" state={{ insightId: insightsdata.id, authorName: insightAuthordata[0].userName }}><Text><FontAwesomeIcon icon={faEnvelope} className='icons' /></Text></Link>
                                                <Link to={"/insight/" + insighturl} target="_blank"><Text><FontAwesomeIcon icon={faEye} className='icons' /></Text></Link>
                                                <Link to={"/admin/insight-edit/" + insightsdata.id}><Text><FontAwesomeIcon icon={faPenToSquare} className='icons' /></Text></Link>
                                                <Link onClick={() => handledelete(insightsdata.id)}><Text className='remove'><FontAwesomeIcon icon={faTrashCan} className='icons' /></Text></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                   
                    

                    

                </Container>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}