import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from "react-router-dom";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import Header from './include/Header.js';
import Footer from './include/Footer.js';
import { format } from 'date-fns';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { fetchGet, fetchPost, fetchHtml } from "../services/apiCalls.js";
import dayjs, { Dayjs } from 'dayjs';

const accessToken = localStorage.getItem('accessToken');

const InsightCreation_Page = () => {
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Semplicita Pro'
            ],
            fontWeight: [
                '400'
            ]
        },
    });

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [openAlertNewUpload, setOpenAlertNewUpload] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(true);
    const [dataWelcomeEmail, setDataWelcome] = useState("");
    const [lastUpdated, setLastupdateDate] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const [notificationId, setNotificationId] = useState(0);
    const [switchActive, setSwitchActive] = useState(true);
    const [formMode, setFormMode] = useState("");
    const [dataPlaceHolder, setDataPlaceHolder] = useState("");
    


    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertNewUpload(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    if (location.state !== null) {
        var documentId = location.state.documentId;
        var documentName = location.state.documentName;
        var productName = location.state.productName;
        console.log(documentId);
        console.log(documentName);
        console.log(productName);
    }

    const handledate = (x, event) => {
        let ldate = JSON.stringify(x);
        setLastupdateDate(ldate.replace(/['"]+/g, ''));
    }

    const convertDateToUTC = (date) => {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    const handledeliveryDate = (x, event) => {
        var ldate = JSON.stringify(x);
        setDeliveryDate(ldate.replace(/['"]+/g, ''));
        console.log(ldate.replace(/['"]+/g, ''));
        console.log(convertDateToUTC(new Date(ldate.replace(/['"]+/g, ''))).toISOString());
    }
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        const data = new FormData(event.currentTarget);
        console.log('fdff');
        console.log(switchActive);

        let subject = data.get('subject');
        let message = data.get('message');
        let updateddate = lastUpdated;
        let realupdateddate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

        console.log(subject);
        console.log(message);
        console.log(updateddate);

        if (subject == "") {
            setErrorMessage("Please add subject");
        }
        else if (message == "") {
            setErrorMessage("Please add content");
        }
        else if (updateddate == "") {
            setErrorMessage("Please select last updated date");
        }
        else if (deliveryDate == "") {
            setErrorMessage("Please select schedule date");
        }
        else {
            setErrorMessage("");
            setSuccessMessage("");

            const documentnotification = {
                "id": notificationId,
                "documentId": documentId,
                "deliveryTime": deliveryDate,
                "isDelivered": dataWelcomeEmail.isDelivered,
                "emailSubject": subject,
                "htmlcontent": message,
                "mode": formMode,
                "active": switchActive,
                "lastUpdated": updateddate
            }

            if (formMode == "preview") {
                localStorage.setItem("displayInsightSubject", subject);
                localStorage.setItem("displayInsightBody", message);
                window.open("/admin/email-notification-preview", '_blank');


            } else {
                const resfinalupload = await handlefinaluploadtemplate(documentnotification);
            }

            

        }

    }

    function handlefinaluploadtemplate(documentnotification) {

        console.log(documentnotification);
        
        return fetch('/api/cms/document-notification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(documentnotification)
        })
            .then(data => {
                if (data.status == 200) {
                    setErrorMessage("");
                    setSuccessMessage("Document notification updated sccessfully...");
                    setOpenAlertNewUpload(true);
                    setTimeout(function () { window.location.href = "/admin/document-management" }, 3000);
                }
                else {
                    setSuccessMessage("");
                    setErrorMessage("Something went wrong. Please try again.");
                }
            });
    }

    const handleClickfordownloaddocument = async (s3_link, filename, open_new_tab) => {
        const response = await downloaddocumentrequest({
            s3_link,
            filename,
            open_new_tab
        });


    };

    async function downloaddocumentrequest(downlddocumentrequest) {
        var data = await fetchPost('/api/cms/document/download', downlddocumentrequest);
        setErrorMessage("");
        if (data.status == "E" && data.message !== null) {
            setErrorMessage("");
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessage(data.message);
        }
        else {
            setErrorMessage("");
            //get content from url
            var tempdata = await fetchHtml(data);

        }

    }

    const fetchData = async () => {

        var datawelcome = await fetchGet('/api/cms/document-notification?documentId=' + documentId);

        if (datawelcome.status == "E" && datawelcome.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
        } else if (datawelcome.status == "N" && datawelcome.message !== null) {
            setErrorMessage(datawelcome.message);
            setDisplayLoader(false);
        }
        else {
            if (datawelcome.emailSubject != null) {
                setDataWelcome(datawelcome);
                console.log('fdfdf');
                console.log(datawelcome);
                setLastupdateDate(datawelcome.lastUpdated);
                setDeliveryDate(datawelcome.deliveryTime);
                setNotificationId(datawelcome.id);
                setSwitchActive(datawelcome.active);

                var temp = await handleClickfordownloaddocument(datawelcome.s3BodyLink, datawelcome.emailSubject, true);
                setDisplayLoader(false);
            }
            
        }

        var dataplaceholder = await fetchGet('/api/cms/email-placeholder');

        if (dataplaceholder.status == "E" && dataplaceholder.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
        } else if (dataplaceholder.status == "N" && dataplaceholder.message !== null) {
            setErrorMessage(dataplaceholder.message);
            setDisplayLoader(false);
        }
        else {
            setDataPlaceHolder(dataplaceholder.filter(el => el.emailType == "Document"));
        }
    }

    const handleToggle = (name: string) => {
        setSwitchActive(!switchActive);
    };


    useEffect(() => {
        fetchData();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Header />

            <Snackbar open={openAlertNewUpload} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity="success" className="w-100">
                    Document Notification Updated Successfully!
                </Alert>
            </Snackbar>

            <Container component="main" maxWidth="lg" className='main-container'  >

                <Container className='whtiebgmain'>
                    <Grid xs={12}>
                        <Link to="/admin/document-management"><Text type='h2' className=' d-inline-block backlink mr-1'><FontAwesomeIcon icon={faCircleArrowLeft} size='10px' /> </Text></Link>
                        <Text type='h2' className='text-gradient d-inline-block mb-3 '>DOCUMENT EMAIL NOTIFICATION </Text>
                        <Stack direction="row" spacing={0} alignItems="center" className='switch'>
                            <Typography>Inactive</Typography>
                                <Switch
                                    checked={switchActive}
                                    color="primary"
                                    className='cmsinputspan'
                                    value="active"
                                    onClick={() => handleToggle('active')}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            <Typography>Active</Typography>
                            </Stack>

                    </Grid>

                    {dataWelcomeEmail && dataWelcomeEmail.emailSubject != null && !displayLoader &&
                        <Box component="form" noValidate onSubmit={handleSubmit} className='whtiebgemailfrom p-4 pt-3'>

                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={6} className='cmsinput mt-1'>
                                    <TextField
                                        margin="none"
                                        fullWidth
                                        id="DocumentName"
                                        name="cosumentName"
                                        className="form-control cmsinputtg cmsinputborder"
                                        variant='filled'
                                        placeholder='Document Name'
                                        value={documentName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className='cmsinput datepicker'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="Last updated date" format="MM/DD/YYYY" className='cmsinputtg emaildate w-100' defaultValue={dayjs(dataWelcomeEmail.lastUpdated)} onChange={(x, event) => handledate(x, event)} />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                </Grid>
                                <Grid item xs={12} sm={6} className='cmsinput mt-1'>
                                    <TextField
                                        margin="none"
                                        fullWidth
                                        id="ProductName"
                                        name="productName"
                                        className="form-control cmsinputtg cmsinputborder"
                                        variant='filled'
                                        placeholder='Product Name'
                                        value={productName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className='cmsinput datepicker '>

                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker label="Schedule Email Newsletter" format="MM/DD/YYYY HH:mm" className='cmsinputtg emaildate w-100' defaultValue={dayjs(dataWelcomeEmail.deliveryTime)} onChange={(x, event) => handledeliveryDate(x, event)} />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                    {/*<Grid item xs={12} sm={4}  className='mt-1'>  <Button variant="contained" component="label" className="bg-blue-gradient button text-white w-100 pt-1 pb-1" >Schedule</Button></Grid>*/}



                                </Grid>
                                <Grid item xs={12} className='cmsinput'>
                                    <TextField
                                        margin="none"
                                        fullWidth
                                        id="subject"
                                        name="subject"
                                        className="form-control cmsinputtg cmsinputborder mt-1"
                                        variant='filled'
                                        placeholder='Subject'
                                        defaultValue={dataWelcomeEmail.emailSubject}
                                    />

                                    <Text className='attchmentbtn p-1 mr-1 mb-3 nobg' type='small'>Placeholders : </Text>
                                    {dataPlaceHolder && !displayLoader && dataPlaceHolder.length > 0 && dataPlaceHolder.map(item => {
                                        return (< Text className='attchmentbtn p-1 mr-1 mt-3' type='small'>{item.emailPlaceHolder}</Text>)
                                    })}

                                    <textarea placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry." className="formControl cmsinputtg cmsinputborder inputhtml" name="message" rows={8} id="insightContentgrid" />
                                </Grid>
                            </Grid>
                            <Grid container className='contbtn  mt-2'>
                                <Button
                                    type="submit"
                                    disableElevation
                                    variant="contained"
                                    onClick={() => setFormMode("preview")}
                                    className="btn-gradient mr-2 samewidth"
                                >PREVIEW</Button>

                                {!switchActive &&
                                    <>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            variant="contained"
                                            onClick={() => setFormMode("draft")}
                                            className="btn-gradient mr-2 samewidth"
                                        >SAVE</Button>

                                        <Button
                                            disableElevation
                                            variant="contained"
                                            onClick={() => console.log('Publish')}
                                            className="btn-gradient samewidth disabled"
                                        >PUBLISH</Button>

                                    </>
                                }

                                {switchActive &&
                                    <>
                                        <Button
                                            disableElevation
                                            variant="contained"
                                            onClick={() => console.log('save')}
                                            className="btn-gradient mr-2 samewidth disabled"
                                        >SAVE</Button>

                                        <Button
                                            type="submit"
                                            disableElevation
                                            variant="contained"
                                            onClick={() => setFormMode("Publish")}
                                            className="btn-gradient samewidth"
                                        >PUBLISH</Button>
                                    </>}

                            </Grid>
                            {errorMessage && <Text type='small' className="errormsg" id='errormsg'> {errorMessage} </Text>}
                            {successMessage && <Text type='small' className="successmsg" id='successmsg'> {successMessage} </Text>}
                        </Box>
                    }

                    {dataWelcomeEmail.emailSubject == null && 
                        <Box component="form" noValidate onSubmit={handleSubmit} className='whtiebgemailfrom p-4 pt-3'>

                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={6} className='cmsinput mt-1'>
                                    <TextField
                                        margin="none"
                                        fullWidth
                                        id="DocumentName"
                                        name="cosumentName"
                                        className="form-control cmsinputtg cmsinputborder"
                                        variant='filled'
                                        placeholder='Document Name'
                                        value={documentName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className='cmsinput datepicker'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer components={['DateTimePicker']}>
                                            <DatePicker label="Last updated date" format="MM/DD/YYYY" className='cmsinputtg emaildate w-100' onChange={(x, event) => handledate(x, event)} />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                </Grid>
                                <Grid item xs={12} sm={6} className='cmsinput mt-1'>
                                    <TextField
                                        margin="none"
                                        fullWidth
                                        id="ProductName"
                                        name="productName"
                                        className="form-control cmsinputtg cmsinputborder"
                                        variant='filled'
                                        placeholder='Product Name'
                                        value={productName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className='cmsinput datepicker '>

                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker label="Schedule Email Newsletter" format="MM/DD/YYYY HH:mm" className='cmsinputtg emaildate w-100' onChange={(x, event) => handledeliveryDate(x, event)} />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                    {/*<Grid item xs={12} sm={4}  className='mt-1'>  <Button variant="contained" component="label" className="bg-blue-gradient button text-white w-100 pt-1 pb-1" >Schedule</Button></Grid>*/}



                                </Grid>
                                <Grid item xs={12} className='cmsinput'>
                                    <TextField
                                        margin="none"
                                        fullWidth
                                        id="subject"
                                        name="subject"
                                        className="form-control cmsinputtg cmsinputborder mb-3 mt-1"
                                        variant='filled'
                                        placeholder='Subject'
                                        
                                    />
                                    <textarea placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry." className="formControl cmsinputtg cmsinputborder inputhtml" name="message" rows={8} />
                                </Grid>
                            </Grid>
                            <Grid container className='contbtn  mt-2'>
                                <Button
                                    type="submit"
                                    disableElevation
                                    variant="contained"
                                    onClick={() => setFormMode("preview")}
                                    className="btn-gradient mr-2 samewidth"
                                >PREVIEW</Button>

                                {!switchActive &&
                                    <>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            variant="contained"
                                            onClick={() => setFormMode("draft")}
                                            className="btn-gradient mr-2 samewidth"
                                        >SAVE</Button>

                                        <Button
                                            disableElevation
                                            variant="contained"
                                            onClick={() => console.log('Publish')}
                                            className="btn-gradient samewidth disabled"
                                        >PUBLISH</Button>

                                    </>
                                }

                                {switchActive &&
                                    <>
                                        <Button
                                            disableElevation
                                            variant="contained"
                                            onClick={() => console.log('save')}
                                            className="btn-gradient mr-2 samewidth disabled"
                                        >SAVE</Button>

                                        <Button
                                            type="submit"
                                            disableElevation
                                            variant="contained"
                                            onClick={() => setFormMode("Publish")}
                                            className="btn-gradient samewidth"
                                        >PUBLISH</Button>
                                    </>}

                            </Grid>
                            {errorMessage && <Text type='small' className="errormsg" id='errormsg'> {errorMessage} </Text>}
                            {successMessage && <Text type='small' className="successmsg" id='successmsg'> {successMessage} </Text>}
                        </Box>
                    }
                </Container>
            </Container>
            <Footer />
        </ThemeProvider>
    )
}

export default InsightCreation_Page
