import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppTheme, SplashScreen } from 'react-windows-ui';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ActiveForgotPassword from './pages/ActiveForgotPassword';
import Dashboard from './pages/Dashboard';
import PostPage from './pages/PostPage';
import PostPagePreview from './pages/PostPagePreview';
import Data from './pages/Data';
import Faqs from './pages/Faqs';
import ConfirmPassword from './pages/ConfirmPassword';
import DocumentViewer from './pages/DocumentViewer';
import CMSLogin from './admin/Login';
import CMSDashboard from './admin/Dashboard';
import DocumentManagement from './admin/DocumentManagement';
import InsightList from './admin/InsightList';
import Insights from './pages/Insights';
import InsightCreation from './admin/InsightCreation';
import InsightEdit from './admin/InsightEdit';
import InsightNewsletter from './admin/InsightNewsletter'
import InsightNewsletterPreview from './admin/InsightNewsletterPreview'
import WelcomeEmail from './admin/WelcomeEmail'
import ResetPassword from './admin/ResetPassword'
import EmailNotification from './admin/EmailNotification'
import EmailNotificationPreview from './admin/EmailNotificationPreview'
import EmailPreview from './admin/EmailPreview'
import ViewDocument from "./pages/ViewDocument";
import DownloadDocument from "./pages/DownloadDocument";
import ProtectedRoute from "./pages/ProtectedRoute";
import ProtectedRouteAdmin from "./pages/ProtectedRouteAdmin";



import './App.css';
import './assets/css/styles.css'
import PageNotFound from './component/404Page.js';
import Dashbordmodel from './pages/Dashbord-model';


function App() {
    const [splash, setSplash] = useState(true);
    useEffect(() => {
        setSplash(false);
    }, [])
    return (      
        <Router>
            <SplashScreen
                //duration={1000} // adjust how long it takes after render 
                isVisible={splash}
                title=""
            />
            <AppTheme
                //added AppTheme to make app reactive with theme
                scheme="system" // available props - 'light', 'dark' or 'system'
            />
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/activation/forgot-password" element={<ActiveForgotPassword />} />

                <Route exact path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

                <Route exact path="/data/*" element={<ProtectedRoute><Data /></ProtectedRoute>} />
                <Route exact path="/view-document/*" element={<ProtectedRoute><ViewDocument /></ProtectedRoute>} />
                <Route exact path="/download-document/*" element={<ProtectedRoute><DownloadDocument /></ProtectedRoute>} />
                <Route exact path="/insight/*" element={<ProtectedRoute><PostPage /></ProtectedRoute>} />
                <Route exact path="/insight-preview/*" element={<ProtectedRoute><PostPagePreview /></ProtectedRoute>} />
                <Route exact path="/dashbord-model" element={<Dashbordmodel />} />

                <Route exact path="/faqs" element={<Faqs />} />
                <Route exact path="/confirm-password" element={<ConfirmPassword />} />
                <Route exact path="/document-viewer" element={<DocumentViewer />} />
                <Route exact path="/admin/login" element={<CMSLogin />} />
                <Route exact path="/admin/dashboard" element={<ProtectedRouteAdmin><CMSDashboard /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/document-management" element={<ProtectedRouteAdmin><DocumentManagement /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/insight-list" element={<ProtectedRouteAdmin><InsightList /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/insight-creation" element={<ProtectedRouteAdmin><InsightCreation /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/insight-edit/*" element={<ProtectedRouteAdmin><InsightEdit /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/insight-newsletter" element={<ProtectedRouteAdmin><InsightNewsletter /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/insight-newsletter-preview" element={<ProtectedRouteAdmin><InsightNewsletterPreview /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/welcome-email" element={<ProtectedRouteAdmin><WelcomeEmail /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/reset-password" element={<ProtectedRouteAdmin><ResetPassword /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/email-notification" element={<ProtectedRouteAdmin><EmailNotification /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/email-notification-preview" element={<ProtectedRouteAdmin><EmailNotificationPreview /></ProtectedRouteAdmin>} />
                <Route exact path="/admin/email-preview" element={<ProtectedRouteAdmin><EmailPreview /></ProtectedRouteAdmin>} />
                <Route exact path="/insights" element={<ProtectedRoute><Insights /></ProtectedRoute>} />
                
                <Route exact path="*" element={<PageNotFound />} />
            </Routes>
        </Router>      
    );
}

export default App;
