import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import logo from '../assets/images/logo.png';
import Text from '../assets/components/ui/atoms/Text.jsx';
import ImgBottom from '../assets/images/login-bg.png';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { fetchPost } from "../services/apiCalls";

const accessToken = localStorage.getItem('accessToken');
const userrole = localStorage.getItem('userrole');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

export default function SignIn() {

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [togglepassword, setTogglePassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [buttonlabel, setButtonLabel] = useState('Login');
    
    const togglePasswordHide = () => {
        setTogglePassword(!togglepassword);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        
        if (username == "") {
            setSuccessMessage("");
            setErrorMessage("Please check your Email!");
        }
        else if (password == "") {
            setSuccessMessage("");
            setErrorMessage("Please check your Password!");
        }
        else {
            setButtonLabel("Signing in...");
            const response = await fetchPost('/api/portal/login', {
                username,
                password
            });

            if (response.accessToken === undefined || response.accessToken === null) {
                setSuccessMessage("");
                setErrorMessage(response.message);
                setButtonLabel("Login");
            }
            else {
                setErrorMessage("");
                setSuccessMessage("");

               

                if (response.role == "Analyst") {

                    localStorage.setItem('accessToken', response.accessToken);
                    localStorage.setItem('user', username);
                    localStorage.setItem('userrole', response.role);

                    window.location.href = "/admin/dashboard";
                }
                else {
                    setSuccessMessage("");
                    setErrorMessage("Only analyst can access this section");
                    setButtonLabel("Login");
                }
            }
        }
};

    function isLogin() {
        if (accessToken && userrole == "Analyst") {
            navigate("/admin/dashboard");
        }
    }

    useEffect(() => {
        isLogin();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box className="loginbox">
                    <img src={logo} alt='Costmine Intelligence' />
                    <Box className="box-container p-6 mt-5" component="form" onSubmit={handleSubmit} noValidate >
                        <Box className="positionRelative zIndex1">
                            {errorMessage && <Text type='small' className="alertmsg errormsg" id='errormsg'> {errorMessage} </Text>}
                            {successMessage && <Text type='small' className="alertmsg successmsg" id='successmsg'> {successMessage} </Text>}
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                className="form-control mb-3"
                                variant='filled'
                                onChange={e => setUsername(e.target.value)}
                            />
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={togglepassword ? "text" : "password"}
                                id="password"
                                autoComplete="current-password"
                                className="form-control"
                                variant="filled"
                                onChange={e => setPassword(e.target.value)}
                                InputProps={
                                    {
                                        endAdornment: (
                                            <InputAdornment position="end"> {
                                                togglepassword ? (
                                                    <Visibility className="cursor_pointer"
                                                        onClick={togglePasswordHide}
                                                    />
                                                ) : (
                                                    <VisibilityOff onClick={togglePasswordHide}
                                                    />
                                                )
                                            }
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            />
                            <Button
                                type="submit"
                                fullWidth
                                disableElevation
                                variant="contained"
                                className="btn-gradient mt-3 mb-2"                                
                            >
                                {buttonlabel}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Typography align='center'>
                                        <Link to="/forgot-password" className="text-link" variant="body">Forgot password?</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <img src={ImgBottom} alt="Costmine Intelligence" className='imgbottom' />
        </ThemeProvider>
    );
}