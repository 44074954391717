import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import logo from '../assets/images/logo.png';
import ImgBottom from '../assets/images/login-bg.png';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { fetchPost } from "../services/apiCalls";

const accessToken = localStorage.getItem('accessToken');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

export default function ForgotPassword() {

    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [buttonlabel, setButtonLabel] = useState('Reset Password');
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        
        if (username == "") {
            setSuccessMessage("");
            setErrorMessage("Please check your Email!");
        }
        else {
            setButtonLabel("Resetting Password...");
            const response = await fetchPost('/api/portal/forgot-password', username); 
            
            if (response.status == "Y" && response.message !== null) {
                setErrorMessage("");
                setSuccessMessage(response.message);
                setButtonLabel("Reset Password");
            }
            else {
                setSuccessMessage("");
                setErrorMessage(response.message);
                setButtonLabel("Reset Password");
            }
        }
    };

    function isLogin() {
        if (accessToken) {
            navigate("/dashboard");
        }
    }

    useEffect(() => {
        isLogin();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box className="loginbox">
                    <img src={logo} alt='Costmine Intelligence' />
                    <Box className="box-container  p-6 mt-5" component="form" onSubmit={handleSubmit} noValidate >
                        <Box className="positionRelative zIndex1 text-center">
                            {errorMessage && <Text type='small' className="alertmsg errormsg" id='errormsg'> {errorMessage} </Text>}
                            {successMessage && <Text type='small' className="alertmsg successmsg" id='successmsg'> {successMessage} </Text>}
                            <Text type='h2' ><span className='text-gradient'>Forgot Password?</span></Text>
                            <Typography align="center" className="mb-3 mt-1">Please enter your email ID to receive your login details.</Typography>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                className="form-control"
                                variant='filled'
                                onChange={e => setUsername(e.target.value)}                               
                            />
                            <Button
                                type="submit"
                                fullWidth
                                disableElevation
                                variant="contained"
                                className="btn-gradient mt-3 mb-2"                                
                            >
                                {buttonlabel}
                            </Button>
                        </Box>
                    </Box>
                </Box>  
            </Container>
            <img src={ImgBottom} alt="Costmine Intelligence" className='imgbottom' />
        </ThemeProvider>
    );
}