import React from "react";
import CheckIsLogin from '../component/CheckIsLogin.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { Box } from "@mui/material";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import logo from '../assets/images/footer-logo.png';
import LinkedIn from '../assets/images/in.png';
import Facebook from '../assets/images/facebook.png';
import Twitter from '../assets/images/twitter.png';
import YouTube from '../assets/images/youtube.png';
import Header from '../include/Header.js';
import Footer from '../include/Footer.js';
import ContactForm from "../form/ContactForm.js";
import '../assets/css/accordian.css';
import Text from '../assets/components/ui/atoms/Text.jsx';
import ImgBottom from '../assets/images/product-bg.png';
import Faqsimg from '../assets/images/faqsimg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '600'
        ]
    },
});

const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                ".Mui-expanded & > .collapsIconWrapper": { display: "none" },
                ".expandIconWrapper": { display: "none" },
                ".Mui-expanded & > .expandIconWrapper": { display: "block" }
            }}
        >
            <div className="expandIconWrapper"></div>
            <div className="collapsIconWrapper"></div>
        </Box>
    );
};

export default function Faqs() {

    CheckIsLogin();

    const [expanded, setExpanded] = React.useState('panelA1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <ThemeProvider theme={theme} >
            <Container maxWidth="false" className="pr-0 pl-0 positionRelative">
                <Header />
                <Container component="main" maxWidth="lg" className='main-container'  >
                    <Text type="h2" className="dtahe" ><span className='bluetext'>Support  <FontAwesomeIcon icon={faAngleRight} /></span></Text>
                    <Text type="h1" variant="h1" className="mb-3 " ><span className='text-gray-gradient'>FAQs</span></Text>
                    <Box className='whtiebg p-3'>
                        <Text type="h2" className="text-gradient mb-1">Products</Text>
                        <Accordion className="accoMain mt-0" expanded={expanded === 'panelA1'} onChange={handleChange('panelA1')} >
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="accoTitle"
                            >
                                <Text type="productname">
                                    Why cant I access everything?  
                                </Text>
                            </AccordionSummary>
                            <AccordionDetails className="accoBody">
                                <Text type="p" className="mb-2">
                                    Your company may not have purchased access to all products. If you think that is a mistake or you dont have access, but you would like it, please email <Link to="mailto:support@costmine.com" >support@costmine.com</Link> and we will help add it to your package.  
                                </Text>
                                <Text type="p">
                                    This is specifically for access to PDFs. You will notice that there are still links to our other products, so you can still login.
                                </Text>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="accoMain" expanded={expanded === 'panelA2'} onChange={handleChange('panelA2')}>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                                className="accoTitle"
                            >
                                <Text type="productname">
                                    How do I switch the PDF view in two page view?
                                </Text>
                            </AccordionSummary>
                            <AccordionDetails className="accoBody">
                                <Text type="p" className="mb-2">
                                    The Mine and Mill Equipment Cost Guide is meant to be read in two page view.  To view it on your screen click on view, then make sure you are looking at the document in 2 page view.  Click on the three-dot icon in the top right corner of the screen and select  two page view.   From here, select the piece of equipment and read across the two pages.  The page on the left shows the capital cost of the equipment and the right shows the operating cost.
                                </Text>
                                <Text type="p" className="mb-2">
                                    You are able to do this with any of the PDF documents you have access to.
                                </Text> 
                                <img src={Faqsimg} alt="Faqs"  />
                            </AccordionDetails>
                        </Accordion>
                        
                        <Accordion className="accoMain" expanded={expanded === 'panelA3'} onChange={handleChange('panelA3')}>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel1bh-content"
                                id="panel2bh-header"
                                className="accoTitle"
                            >
                                <Text type="productname">
                                Why don’t all of the documents in Mining Cost Service have the current year?
                                </Text>
                            </AccordionSummary>
                            <AccordionDetails className="accoBody">
                                <Text type="p" >
                                Mining Cost Service is continuously updated throughout the year.  One section is updated approximately every month.  By the end of the year all of the sections will have the same year. Selecting the current year on the dashboard will give you all of the data that is up to date.  When a new document is available the users will be notified. 
                                </Text>
                               
                            </AccordionDetails>
                        </Accordion>
                        

                        
                    </Box>
                    <Box id="contact">
                        <Text id="contactform" type="h1" variant="h3"  className="pt-5 mb-3"><span className='text-gray-gradient'>Contact Us</span></Text>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={7} md={8} >
                                <Box className='whtiebg p-3' >
                                    <Text type="h2" className="text-gradient mb-1">How Can We Help?</Text>
                                    <Text type="p">Have questions about our products? We’re here to help you. Let’s talk.</Text>
                                    <Text type="small" className="mt-1 bluehe" >Fields with * are compulsory .</Text>
                                    <ContactForm/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} >
                                <Box className='contact-detials' >
                                    <Box className="positionRelative zIndex2 p-2"><img src={logo} alt='Costmine Intelligence' className="faqlogo" /><br /><br />
                                        <b>SPOKANE (HEAD OFFICE)</b><br/>
                                        100 N Mullan Rd Ste<br />
                                        102 Spokane Valley<br />
                                        Washington 99206, USA<br /><br />
                                        P: 509 328 8023<br/>
                                        F: 509 328 2028<br/><br/>
                                       
                                        <Link to="mailto:support@costmine.com" >support@costmine.com</Link><br/><br/>
                                        <img src={LinkedIn} alt='LinkedIn' className="ml-1 mr-1" />
                                        <img src={Facebook} alt='Facebook'  className="ml-1 mr-1"/>
                                        <img src={Twitter} alt='Twitter'  className="ml-1 mr-1"/>
                                        <img src={YouTube} alt='YouTube'  className="ml-1 mr-1"/>                                   
                                        

                                       
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <img src={ImgBottom} alt="Costmine Intelligence" className='innerbottom'  />
                <Footer/>
            </Container>
        </ThemeProvider>
    )
}