import React from 'react'
import { Navigate, useLocation } from "react-router-dom"

const ProtectedRouteAdmin = ({ children }) => {
    const accessToken = localStorage.getItem('accessToken');
    let location = useLocation();

    localStorage.removeItem("currentpath");

    if (accessToken == "" || accessToken == "undefined" || accessToken == null) {
        return <Navigate to="/admin/login" replace />
    }
    else {
        return children;
    }
};

export default ProtectedRouteAdmin;