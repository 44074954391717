import React, { useState,useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import ActionLogout from '../component/ActionLogout.js';
import { fetchPost } from "../services/apiCalls";
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../include/Header.js';
import Footer from '../include/Footer.js';
import Box from '@mui/material/Box';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

const DownloadDocument = () => {
    const accessToken = localStorage.getItem('accessToken');

    const [displayLoader, setDisplayLoader] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    let DocumentLink = searchParams.get("doclink");
    let DocumentName = searchParams.get("docname");

    async function downloaddocumentrequest() {

        const params = {
            s3_link: DocumentLink,
            filename: DocumentName,
            open_new_tab: true
        };

        var data = await fetchPost('/api/portal/document/download', params);
        if (data.status == "E" && data.message !== null) {
            ActionLogout(data.message);
        } else if (data.status == "N" && data.message !== null) {
            alert(data.message);
        }
        else {
            setTimeout(function () {
                //window.location.href = data;
                setDisplayLoader(false);
                //window.close();
            },5000);
            
        }
    }

    useEffect(() => {
        downloaddocumentrequest();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="false" className="pr-0 pl-0 positionRelative">
                <Header />
                {displayLoader &&
                    <Box className='loader'>
                        <CircularProgress />
                    </Box>}
                {!displayLoader &&
                    <Box className='loader'>
                        Download Done
                    </Box>}
                <Footer />
            </Container>
        </ThemeProvider>
    );

};

export default DownloadDocument;