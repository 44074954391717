import React, { useState, useEffect } from 'react';
import CheckIsLogin from '../component/CheckIsLogin.js';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../include/Header.js';
import Footer from '../include/Footer.js';
import ImgBottom from '../assets/images/product-bg.png';
import D1 from '../assets/images/1.png';
import { format } from 'date-fns';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ActionLogout from '../component/ActionLogout.js';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Mcsicon from '../assets/images/mining-cost-service.png';
import Ecsicon from '../assets/images/equipment-cost-calculator.png';
import Ghlicon from '../assets/images/gold-heap-leach.png';
import Rcgicon from '../assets/images/reclamation.png';
import Lbricon from '../assets/images/labor.png';
import Srficon from '../assets/images/sherpa-surface.png';
import Undicon from '../assets/images/sherpa-underground.png';
import Mnpricon from '../assets/images/sherpa-mineral-processing.png';
import Miicon from '../assets/images/mining-intelligence.png';
import ReactPaginate from 'react-paginate';
import { fetchPost, fetchGet } from "../services/apiCalls";


const accessToken = localStorage.getItem('accessToken');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

export default function GetProductData() {

    let currentdata = window.location.pathname;

    let ProductId = currentdata.split(/[-]+/).pop();

    const navigate = useNavigate();

    if (ProductId == "/data") {
        navigate("/dashboard");
    }

    CheckIsLogin();

    const location = useLocation();

    const iconList = Object
        .keys(Icons)
        .filter(key => key !== "fas" && key !== "prefix")
        .map(icon => Icons[icon]);

    library.add(...iconList);

    let CurrentYear = new Date().getFullYear();
    const [responseDefaultData, setResponseDefaultData] = useState();
    const [responseFeatureAccessData, setResponseFeatureAccessData] = useState();
    const [Year, setyear] = useState(new Date().getFullYear());
    const [displayLoader, setDisplayLoader] = useState(true);
    const [responseProductData, setResponseProductData] = useState([]);
    const [responseLauncherData, setResponseLauncherData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageDoc, setErrorMessageDoc] = useState("");
    const [featureAccessName, setFeatureAccessName] = useState("");
    const [CurrentPage, setCurrentPage] = useState(0);  
    const [dataProducts, setDataProducts] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const fetchDataStart = async () => {

        let DocMode = searchParams.get("mode");
        let DocumentLink = searchParams.get("doclink");
        let DocumentName = searchParams.get("docname");

        if (DocMode == "view") {
            const params = {
                s3_link: DocumentLink,
                filename: DocumentName,
                use_in_viewer: true
            };

            var datav = await fetchPost('/api/portal/document/download', params);
            if (datav.status == "E" && datav.message !== null) {
                ActionLogout(datav.message);
            } else if (datav.status == "N" && datav.message !== null) {
                alert(datav.message);
            }
            else {
                window.location.href = datav;
            }
        }
        else if (DocMode == "download") {
            const params = {
                s3_link: DocumentLink,
                filename: DocumentName,
                open_new_tab: true
            };

            var datad = await fetchPost('/api/portal/document/download', params);
            if (datad.status == "E" && datad.message !== null) {
                ActionLogout(datad.message);
            } else if (datad.status == "N" && datad.message !== null) {
                alert(datad.message);
            }
            else {
                window.location.href = datad;
            }
        }

        var resstart = await fetchPost('/api/cms/document/update/start');
        setResponseDefaultData(resstart);

        var data = await fetchGet('/api/portal/product-dashboard');

        if (data.status == "E" && data.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
            ActionLogout(data.message);
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessage(data.message);
            setDisplayLoader(false);
        }
        else {
            const tempdata = data.filter(el => el.id == ProductId);
            setDataProducts(tempdata);
            setDisplayLoader(false);
        }

         var resaccess = await fetch('/api/cms/GetFeaturesBasedonProductAndUser?ProductId=' + ProductId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        })
            .then(response => {
                if (response.status == 401) {
                    ActionLogout();
                }
                return response.json()
            })
            .then(data => {
                if (data.length > 0) {
                    setResponseFeatureAccessData(data);
                    //const isfeatureaccess = data.find(({ featureName }) => featureName == "MCS - Table of Contents");
                    var isfeatureaccess = "";
                    if (ProductId == 5) {
                        isfeatureaccess = data.find(({ featureName }) => featureName == "MCS - Archived Years");
                    }
                    else {
                        isfeatureaccess = data.find(({ featureName }) => featureName == "MME - Archived Years");
                    }
                    
                    if (isfeatureaccess !== undefined) {
                        setFeatureAccessName(isfeatureaccess.featureName);
                    }
                    else {
                        setFeatureAccessName("");
                    }
                    
                    
                }
                
            });
            
    }

    async function fethproductlist(productrequest) {
        var data = await fetchPost('/api/portal/fetch-document-list', productrequest);
        setErrorMessage("");
        setErrorMessageDoc("");

        if (data.status == "E" && data.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
            ActionLogout(data.message);
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessage(data.message);
            setDisplayLoader(false);
        }
        else {
            setCurrentPage(0);
            setResponseProductData(data.documents);
            if (data.launchers.length > 0) {
                setResponseLauncherData(data.launchers);
            }
            setDisplayLoader(false);
        }
            
    }

    const handleClickforrightside = async (productId, year) => {
        setCurrentPage(0);
        if (year == undefined) {
            year = new Date().getFullYear();
        }
        const response = await fethproductlist({
            ProductId,
            year
        });
    };
    
    const handleClickfordownloaddocument = (s3_link, filename, open_new_tab) => {
        const response = downloaddocumentrequest({
            s3_link,
            filename,
            open_new_tab
        });


    };

    async function downloaddocumentrequest(downlddocumentrequest) {
        var data = await fetchPost('/api/portal/document/download', downlddocumentrequest);
        setErrorMessageDoc("");
        if (data.status == "E" && data.message !== null) {
            setErrorMessageDoc("");
            ActionLogout(data.message);
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessageDoc(data.message);
        }
        else {
            setErrorMessageDoc("");
            window.location.href = data;
        }
                
}
    function changeyear(e) {
        setyear(e.target.value);
        handleClickforrightside(ProductId, e.target.value);
    }

    const ReadMore = ({ children }) => {
        const text = children != undefined ? children : "";
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <div className="text datadiscription">
                {(text.length > 150) ? <p>{isReadMore ? text.substring(0, 150) : text}
                    <span onClick={toggleReadMore} className="text-link purpletext">
                        {isReadMore ? " Read More" : " Read less"}
                    </span></p> : <p>{text}</p>}

            </div>
        );
    };


    function handlePageClick({ selected: selectedpage }) {
        setCurrentPage(selectedpage);
    }  

    const PER_PAGE = 21;
    const offset = CurrentPage * PER_PAGE;
    const currentpagedata = responseProductData.slice(offset, offset + PER_PAGE);
    const pageCount = Math.ceil(responseProductData.length / PER_PAGE); 
    

    useEffect(() => {
        fetchDataStart();
        handleClickforrightside();
        setCurrentPage(0);
    }, [])
    
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="false" className="pr-0 pl-0 positionRelative">
                <Header />
                {displayLoader &&
                    <Box className='loader'>
                        <CircularProgress />
                    </Box>}
                
                <Container component="main" maxWidth="lg" className='main-container'>
                    
            <Grid container>
                        
                        <Grid item xs={12} sm={6}>
                            <Text type="h2" className="dtahe"><Link to="/dashboard" className='txtdecnon'><span className='bluetext'>Data <FontAwesomeIcon icon={faAngleRight} /></span></Link></Text>
                            {dataProducts && dataProducts.length>0 && < Text type="h1" className="mb-3 "><span className='text-gray-gradient'>{dataProducts[0].name}</span></Text>} 
                            
            </Grid>
            <Grid item xs={12} sm={6} className='lstdt'>
                            <Text type="small" >Last Updated {format(new Date(), 'MM/dd/yyyy')}</Text>             
            <Select        
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className='dropdownstyle current'
            value={Year}                                
            onChange={changeyear}
            MenuProps={{
                disableScrollLock: true,
            }}
        >
            {responseDefaultData && responseFeatureAccessData && featureAccessName != "" && responseDefaultData.years.map(i => {
                return (
                    <MenuItem value={i}>{i == CurrentYear ? "Current" : i}</MenuItem>
                )
            })}

            {responseDefaultData && responseFeatureAccessData && featureAccessName == "" && responseDefaultData.years.slice(0,2).map(i => {
                return (
                    <MenuItem value={i}>{i == CurrentYear ? "Current" : i}</MenuItem>
                )
            })}
                                
        </Select>
                            
            </Grid>
                    </Grid>
                    {errorMessage && <Container className='whtiebg' ><Text type='small' className="errormsg" id='errormsg'> {errorMessage} </Text></Container>}
                    {responseProductData && !displayLoader && !errorMessage &&
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={8} md={9}> 
                                <Container maxWidth="lg" className='whtiebg' >{errorMessageDoc && <Text type='small' className="errormsg" id='errormsg'> {errorMessageDoc} </Text>}
                                {dataProducts && dataProducts.length > 0 && <ReadMore>{dataProducts[0].description}</ReadMore>}
                                </Container>
                                
                                <Container className='whtiebg mt-4' maxWidth="lg">
                                <Grid container spacing={3}> 
                                        {responseProductData && currentpagedata && currentpagedata.map(document => {
                                        if (document.active) {
                                            return (
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Grid item className={document.access_Readonly ? "documentbox" : "documentbox lockeddocument"}>
                                                        <Grid item className="verson" ><Text type="smaller" className="p-1 opacity-0">v1.3</Text> <Text type="smaller" className="p-1">{format(new Date(document.lastUpdated), 'MM/dd/yyyy')}</Text></Grid>
                                                            {
                                                                document.access_Readonly ? 
                                                                <Grid item className="view" >
                                                                    <Text type="smaller" className="p-1">
                                                                        <Link to={"/view-document?doclink=" + document.link + "&docname=" + document.name} target="_blank">
                                                                    <FontAwesomeIcon icon={faEye} className='dnicon' /> VIEW </Link> 
                                                                </Text>
                                                                    <Text type="smaller" className="p-1">
                                                                        <Link to="javascript://" onClick={() => handleClickfordownloaddocument(document.link, document.name, true)}>
                                                                            <FontAwesomeIcon icon={faFilePdf} className='dnicon' /> DOWNLOAD
                                                                        </Link>
                                                                </Text>
                                                                </Grid>
                                                                : <Grid item className="view" >
                                                                    <FontAwesomeIcon icon="fa-solid fa-lock"  />
                                                                  </Grid>
                                                            }
                                                        <img src={document.thumbnailKey ? "https://ci-thumbnail.s3.us-west-2.amazonaws.com/" + document.thumbnailKey : D1} alt="Costmine Intelligence" />
                                                        <Grid item className="documentheading bg-blue-gradient" ><Text type="productname" >{document.name}</Text></Grid>
                                                        
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    })}
                                        {(responseProductData.length == 0 && !displayLoader && !errorMessage) ? <Grid item xs={12} className='placeholder'>
                                            <Text type='p' > We apologize, but there are currently no documents available. Please check back later for updates.</Text>
                                        </Grid> : ''}
                                    </Grid>
                                </Container>

                                {responseProductData && currentpagedata && <ReactPaginate
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    forcePage={CurrentPage}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />}
                 </Grid>
                            {responseLauncherData && !displayLoader && !errorMessage &&
                                <Grid item xs={12} sm={4} md={3}>
                                    {responseLauncherData.map(launchers => {
                                        if (launchers.url != "" && launchers.access == true) {
                                            let launchi = launchers.icon;
                                            let includdelink = true;
                                            let iconnew = "";
                                            if (launchi == "McsiconNew") { iconnew = Mcsicon; }
                                            if (launchi == "EcsiconNew") { iconnew = Ecsicon; }
                                            if (launchi == "GhliconNew") { iconnew = Ghlicon; }
                                            if (launchi == "RcgiconNew") { iconnew = Rcgicon; }
                                            if (launchi == "LbriconNew") { iconnew = Lbricon; }
                                            if (launchi == "SrficonNew") { iconnew = Srficon; }
                                            if (launchi == "UndiconNew") { iconnew = Undicon; includdelink = false; }
                                            if (launchi == "MnpriconNew") { iconnew = Mnpricon; includdelink = false; }
                                            if (launchi == "MiiconNew") { iconnew = Miicon; }
                                            return (includdelink == true ? <Button
                                                type="link"
                                                fullWidth
                                                disableElevation
                                                variant="contained"
                                                className="btn-inactive launchbtn text-transform-none mb-3"
                                                onClick={() => window.open(launchers.url, '_blank')}
                                                ><img src={iconnew} alt="Mining Cost Service" className='launchicon' />{launchers.name}</Button> : <Button
                                                    type="link"
                                                    fullWidth
                                                    disableElevation
                                                    variant="contained"
                                                    className="btn-inactive launchbtn text-transform-none mb-3 crsdft"
                                                    ><img src={iconnew} alt="Mining Cost Service" className='launchicon' />{launchers.name}</Button>)
                                        }
                                    })}
                                </Grid>
                            }
                        </Grid>
                    }
            </Container>
            <img src={ImgBottom} alt="Costmine Intelligence" className='innerbottom'  />
            <Footer/>            
            </Container>
        </ThemeProvider>
    );
}