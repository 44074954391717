import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import CheckIsLogin from '../component/CheckIsLogin.js';
import ActionLogout from '../component/ActionLogout.js';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Header from '../include/Header.js';
import Footer from '../include/Footer.js';
import ImgBottom from '../assets/images/dashboard-bg.png';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { fetchGet } from "../services/apiCalls.js";

import InsightBlock from '../assets/components/insightblock.js'

import { getIcon } from "../lib/icons";

const accessToken = localStorage.getItem('accessToken');
const productdashboardmenu = localStorage.getItem('productdashboardmenu');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle
            {...other} >
            {children}
            {onClose ? (
                <IconButton aria-label="close" onClick={onClose} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function SignInd() {

    const location = useLocation();
    
    const [open, setOpen] = React.useState(false);
    const [modalDescription, setModelDescription] = useState();
    const [modalDataUrl, setModelDataUrl] = useState("");
    const [modalTitle, setModelTitle] = useState();
    const [modalUrl, setModelUrl] = useState();
    const [modalTarget, setModelTarget] = useState();
    const [modalUrlType, setModalUrlType] = useState('');
    const [modalIcon, setModalIcon] = useState('');
    const [displayLoader, setDisplayLoader] = useState(true);
    const [responseData, setResponseData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [locationMenu, setLocationMenu] = useState(false);
    const [dataproducts, setDataProducts] = useState([]);
    const [modelsproducts, setModelsProducts] = useState([]);
    const [toolsproducts, setToolsProducts] = useState([]);
    const [datainsights, setDataInsights] = useState([]);
    const [datainsightsall, setDataInsightsAll] = useState([]);
    const [dataauthors, setDataAuthors] = useState([]);
    

    const handleClickOpen = (e) => {
        setOpen(true);
        const datadesc = e.currentTarget.getAttribute("data-description");
        const datatit = e.currentTarget.getAttribute("data-title");
        const dataurl = e.currentTarget.getAttribute("data-url");
        const datatarget = e.currentTarget.getAttribute("data-target");
        const dataurltype = e.currentTarget.getAttribute("data-urltype");
        const dataicon = e.currentTarget.getAttribute("data-icon");

        let datalinktemp1 = datatit + " " + dataurl;
        let datalink1 = datalinktemp1.replace(/\s+/g, '-').toLowerCase(); 

        setModelDataUrl("/data/" + datalink1);
        setModelDescription(datadesc);
        setModalIcon(dataicon);
        setModelTitle(datatit);
        setModelUrl(dataurl);
        setModelTarget(datatarget);
        setModalUrlType(dataurltype);
    };

    const handleClose = () => { setOpen(false); };
    const mapToolsData = (data) => {
        const tools = data.filter(el => el.type == 'Tools')
        const filtered = tools.filter(({ id }) => id !== 18 && id !== 19)

        const trialTool = tools.find(x => x.id === 19);
        const usTool = tools.find(x => x.id === 18);
        const canTool = tools.find(x => x.id === 20);

        if(usTool?.access || trialTool?.access || canTool?.access) {
            if(usTool?.access) {
                usTool.url = `${usTool.url}redirect?redirectToken=us`
                filtered.push(usTool)
            }
            if(trialTool?.access) {
                trialTool.url = `${trialTool.url}redirect?redirectToken=trial`
                filtered.push(trialTool)
            }
            if (canTool?.access) {
                canTool.url = `${canTool.url}redirect?redirectToken=can`
            }

        } else {
            filtered.push(usTool);
        }

        return filtered;
    };
    const fetchData = async () => {
        const fetchProducts = fetchGet('/api/portal/product-dashboard');
        const fetchAuthors = fetchGet('/api/cms/insight/GetAuthors');
        const fetchInsights = fetchGet('/api/cms/insight/SearchInsight');

        const [data, dataauthors, datainsight] = await Promise.all([fetchProducts, fetchAuthors, fetchInsights])
        
        if (data.status == "E" && data.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
            ActionLogout(data.message);
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessage(data.message);
            setDisplayLoader(false);
        }
        else {
            setResponseData(data);
            if (productdashboardmenu == null) {
                localStorage.setItem('productdashboardmenu', JSON.stringify(data));
            }
            const dataProducts = data.filter(el => el.type == 'Data' && el.id !== 8)
            setDataProducts(dataProducts);
            setModelsProducts(data.filter(el => el.type == 'Models'));
            
            const toolsData = mapToolsData(data);
            setToolsProducts(toolsData);
            
            setDisplayLoader(false);
        }

        
        if (dataauthors.status == "E" && dataauthors.message !== null) {
            setErrorMessage("");
            ActionLogout(dataauthors.message);
        } else if (dataauthors.status == "N" && dataauthors.message !== null) {
            setErrorMessage(dataauthors.message);
        }
        else {
            setDataAuthors(dataauthors);
        }

        
        if (datainsight.status == "E" && datainsight.message !== null) {
            setErrorMessage("");
            ActionLogout(datainsight.message);
        } else if (datainsight.status == "N" && datainsight.message !== null) {
            setErrorMessage(datainsight.message);
        }
        else {
            setDataInsights(datainsight.filter(el => el.active == true).slice(0, 3));
        }

        

    }

    useEffect(() => {
        fetchData();
        if (location.state !== undefined && location.state !== null) {
            setLocationMenu(true);
        }
    }, [])


    
    CheckIsLogin();

    return (
        
        <ThemeProvider theme={theme}>
            <Container maxWidth="false" className="pr-0 pl-0 positionRelative">
                {locationMenu && <Header />}
                {responseData && datainsights && !displayLoader && !locationMenu && <Header productdashboard={responseData} />}
                {displayLoader &&
                    <Box className='loader'>
                        <CircularProgress />
                        
                    </Box>}
                <Container component="main" maxWidth="lg" className='main-container'  >

                <Container className='greybg p-3' >
                        <Grid container className='mb-2'>
                            <Grid item xs={6} ><Text type='h2' className=" ">INSIGHTS</Text></Grid>
                            <Grid item xs={6} ><Link to="/insights"><Text type='smallheading' className="readmoreinsight" >Read more insights <FontAwesomeIcon icon={faAngleRight} /></Text></Link></Grid>
                        </Grid>
                        <Grid container spacing={3} >
                            {responseData && datainsights && dataauthors && !displayLoader && !errorMessage && datainsights.map((insightsdata, idx) => {
                                const insightAuthordata = dataauthors.filter((el) => el.userId == insightsdata.authorId);
                                return (
                                    <InsightBlock key={`data-row-${idx}`} insightAuthor={insightAuthordata[0].userName} insightDate={insightsdata.displayDate} insightTitle={insightsdata.title} insightId={insightsdata.id} thumbnailurl={insightsdata.s3ThumbnailLink} />
                                    )
                                })
                            }
                        </Grid>
                </Container>
                    
                    {errorMessage && <Container className='whtiebg' ><Text type='small' className="errormsg"   id='errormsg'> {errorMessage} </Text></Container>}
                    {responseData && datainsights && dataproducts && !displayLoader &&
                        <Container className='whtiebg mt-4' >
                            <Text type='h2' className="bluehe mb-2">Data </Text>
                            <Grid container spacing={3}>

                                {dataproducts.map((productsdata, idx) => {
                                    const { id: pid, access, name, description, knowMoreURL } = productsdata;                                   

                                    let iconnew = getIcon(pid);

                                    let datalinktemp = `${name} ${pid}`;
                                    let datalink = datalinktemp.replace(/\s+/g, '-').toLowerCase();                                         

                                    const clsName = access ? 'bg-blue-gradient dbbox activebox' : 'dbbox disabled';

                                    return (
                                        <Grid key={`data-grid-row-${idx}`} item xs={6} sm={3} md={2}>
                                            <Grid item className={clsName}>
                                            {access ?
                                                <>
                                                    <Link onClick={handleClickOpen} className='infoicon' data-target='_self' data-icon={iconnew} data-url={pid} data-title={name} data-description={description}><FontAwesomeIcon icon={faCircleInfo} /></Link>
                                                    <Link to={"/data/" + datalink} className='dsbdtlink'><Text type='small'> <img src={iconnew} alt={name} className='dbicon' />{name}</Text></Link>
                                                </>                                                
                                                :
                                                <>
                                                    <Link onClick={handleClickOpen} data-target='_blank' data-urltype="knowmore" data-icon={iconnew} data-url={knowMoreURL} data-title={name} data-description={description} className="inactivelink"> <Text type='small'><img src={iconnew} alt={name} className='dbicon' />{name}</Text>
                                                    </Link> 
                                                </>                                            
                                            }
                                            </Grid>                                            
                                        </Grid>
                                    )
                                    
                                })}
                            </Grid>
                        </Container>
                    }
                    </Container>

                    <Container component="main" maxWidth="lg" className="mt-4" >
                    {responseData && !displayLoader && !errorMessage &&
                        <Container className='whtiebg' >
                            <Text type='h2' className="greenhe mb-2">Models</Text>
                            <Grid container spacing={3}>

                                {modelsproducts.map(({id: pid, access, name, description, knowMoreURL, url}, idx) => {
                                        
                                    let includdenolink = false;
                                    const iconnew = getIcon(pid);                                    
                                    const classname = access ? "bg-green-gradient dbbox activebox" : "dbbox disabled";                               
                                    
                                    return (
                                        <Grid key={`tools-grid-row-${idx}`} item xs={6} sm={3} md={2}>
                                            <Grid item className={classname} >
                                                {access ? 
                                                    <>
                                                        <Link onClick={handleClickOpen} className='infoicon' data-target='_blank' data-icon={iconnew} data-urltype={includdenolink == true ? "nolink" : ""} data-url={url} data-title={name} data-description={description}><FontAwesomeIcon icon={faCircleInfo} /></Link>
                                                        {includdenolink == true ? <Link onClick={handleClickOpen} data-target='_blank' data-icon={iconnew} data-urltype="nolink" data-url={url} data-title={name} data-description={description} className='dsbdtlink'><Text type='small'> <img src={iconnew} alt={name} className='dbicon' />{name}</Text></Link> : <Link to={url} target="_blank" className='dsbdtlink'><Text type='small'> <img src={iconnew} alt={name} className='dbicon' />{name}</Text></Link>}
                                                    </>  
                                                    : <Link onClick={handleClickOpen} data-target='_blank' data-urltype="knowmore" data-icon={iconnew} data-url={knowMoreURL} data-title={name} data-description={description} className="inactivelink"><Text type='small'><img src={iconnew} alt={name} className='dbicon' />{name}</Text></Link>
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Container>
                    }
                    </Container>

                <Container component="main" maxWidth="lg" className="mt-4" >
                    {responseData && !displayLoader && !errorMessage &&
                        <Container className='whtiebg' >
                            <Text type='h2' className="purplehe mb-2" >Tools</Text>
                            <Grid container spacing={3}>

                                {toolsproducts.map(({ access, id: pid, url: purl, knowMoreURL, name, description }, idx) => {
                                        const iconnew = getIcon(pid);
                                        let classname = access ? "bg-purple-gradient dbbox activebox" : "dbbox disabled";

                                        return (
                                            <Grid key={`tools-grid-row-${idx}`} item xs={6} sm={3} md={2}>
                                                <Grid item className={classname} >
                                                    {access ? <>
                                                        <Link onClick={handleClickOpen} className='infoicon' data-target='_blank' data-icon={iconnew} data-url={purl} data-title={name} data-description={description}>
                                                            <FontAwesomeIcon icon={faCircleInfo} />
                                                        </Link>
                                                        <Link to={purl} target="_blank" className='dsbdtlink'>
                                                            <Text type='small'> <img src={iconnew} alt={name} className='dbicon' />{name}</Text>
                                                        </Link>
                                                    </> : <>
                                                        <Link onClick={handleClickOpen} data-target='_blank' data-urltype="knowmore" data-icon={iconnew} data-url={knowMoreURL} data-title={name} data-description={description} className="inactivelink"> 
                                                            <Text type='small'><img src={iconnew} alt={name} className='dbicon' />{name}</Text>
                                                        </Link>                                                     
                                                    </>}

                                                </Grid>
                                            </Grid>

                                        )
                                })}

                            </Grid>
                        </Container>
                    }
                    </Container>

                <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth MuiIconButton-root className="dsbmdl">
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className='modalhe' ><img src={modalIcon} alt={modalTitle} className="modalicon" /><text type='h2' >{modalTitle}</text></BootstrapDialogTitle>
                        <DialogContent >
                            <div className="mb-2">
                                {modalDescription && <Text type="p">{modalDescription}</Text>}
                            </div>
                            <Typography gutterBottom className="text-center">
                                {modalTarget == "_blank" ? (modalUrlType == "nolink" ? <label className="button btn-secondary d-inline-block mt-4">Coming soon..</label> :
                                    <Button
                                        type="url"
                                        disableElevation
                                        variant="contained"
                                        className="btn-gradient mt-3 mb-2"
                                        onClick={() => window.open(modalUrl, modalTarget)}
                                    >
                                        {modalUrlType == "knowmore" ? 'LEARN MORE' : 'EXPLORE THE PRODUCT'}
                                    </Button>
                                ) : (
                                    <Link className="button btn-gradient d-inline-block mt-4" to={modalDataUrl}>EXPLORE THE PRODUCT</Link>
                                )}
                            </Typography>
                        </DialogContent>

                    </BootstrapDialog>

                    <img src={ImgBottom} alt="Costmine Intelligence" className='innerbottom' />
                    <Footer />
            </Container>
        </ThemeProvider>
    );
}