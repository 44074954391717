import * as React from 'react';
import { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Text from '../assets/components/ui/atoms/Text.jsx';
import '../assets/css/navbar.css'

import { getIcon } from "../lib/icons";

export default function MobileMenu(props) {
    
    const [MobileNavbar, setMobileNavbar] = React.useState(null);
    const handleOpenNavMenu = (event) => { setMobileNavbar(event.currentTarget); };
    const handleCloseNavMenu = () => { setMobileNavbar(null); };

    const [expanded, setExpanded] = React.useState('');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [dataproducts, setDataProducts] = useState([]);
    const [modelsproducts, setModelsProducts] = useState([]);
    const [toolsproducts, setToolsProducts] = useState([]);

    const productdashboardmenu = localStorage.getItem('productdashboardmenu');

    const fetchData = (data) => {
        if (data) {
            setDataProducts(data.filter(el => el.type === 'Data' && el.id !== 8));
            setModelsProducts(data.filter(el => el.type === 'Models'));
            setToolsProducts(data.filter(el => el.type === 'Tools'));
        }
    }

    useEffect(() => {
        fetchData(JSON.parse(productdashboardmenu));
    }, [])

    return(
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', justifyContent:'end'}}} >
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit" 
                className='pr-0'                
            >
                <MenuIcon  className='menufontsize'/>
            </IconButton>

            <Menu
                id="menu-mobile"
                className="sliding-menu"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflowY: 'scroll',
                            backdropFilter:'blur(3px)',                                  
                            borderRadius: 0,
                            backgroundColor: 'rgba(32,30,30,.85)',
                            color: '#FFFFFF',
                            left: '0 !important',
                            right: '0 !important',
                            width: '100%',
                            height: 'calc(100vh - 68px)',
                            mx: 0,
                            px: 2,
                            py: 5,
                            mt: 6.55,
                            pt:0,
                            pb:0,
                        },
                    }
                }}
                keepMounted
                open={Boolean(MobileNavbar)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: 'block', md: 'none', }, }}
            >

                <Accordion className="nav-item mt-2"  expanded={expanded === 'NavItem1'} onChange={handleChange('NavItem1')}>
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="nav-link"
                    >
                        <Link to="/dashboard" state={{ productdashboardmenu: props.productdashboardmenu }}>Dashboard</Link>
                    </AccordionSummary>
                </Accordion>

                <Accordion className="nav-item" expanded={expanded === 'NavItem2'} onChange={handleChange('NavItem2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='text-white' />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="nav-link"
                    >
                        All Products
                    </AccordionSummary>
                    <AccordionDetails className="dropdown-menu">
                        <Text type="p" className="mb-1">Data</Text>

                        {dataproducts && dataproducts.map((productsdata, idx) => {
                            if (productsdata.id !== 8) {

                                let pid = productsdata.id;
                                const iconnew = getIcon(pid);

                                let datalinktemp = productsdata.name + " " + productsdata.id;
                                let datalink = datalinktemp.replace(/\s+/g, '-').toLowerCase(); 
                                
                                if (productsdata.access) {
                                    return (
                                        <Link key={`link-item-${idx}`} to={"/data/" + datalink} className="dropdown-item discntr" onClick={() => setTimeout(function () { window.location.reload() }, 300)} >
                                            <ListItemIcon className='bg-blue-gradient drpicon'>
                                                <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                            </ListItemIcon>
                                            {productsdata.name}
                                        </Link>
                                    )
                                }
                                else {
                                    return (
                                        <Text key={`link-item-${idx}`} className="dropdown-item discntr" >
                                            <ListItemIcon className='drpicon disicon'>
                                                <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                            </ListItemIcon>
                                            {productsdata.name}
                                        </Text>
                                    )
                                }
                            }
                        })
                        }

                        <Text type="p" className="mb-1 mt-2">Models</Text>

                        {modelsproducts && modelsproducts.map((productsdata, idx) => {

                            let pid = productsdata.id;
                            let includdenolink = true;
                            const iconnew = getIcon(pid);
                            
                            if (pid === 10 || pid === 1) { includdenolink = false; }
                            
                            if (productsdata.access) {
                                if (includdenolink) {
                                    return (
                                        <Link component="div" key={`link-item-${idx}`} to={productsdata.url} target="_blank" className="dropdown-item discntr" >
                                            <ListItemIcon className='drpicon disicon'>
                                                <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                            </ListItemIcon>
                                            {productsdata.name}
                                        </Link>
                                    )
                                } else {
                                    return (
                                        <Grid key={`link-item-${idx}`} className="dropdown-item discntr" >
                                            <ListItemIcon className='drpicon disicon'>
                                                <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                            </ListItemIcon>
                                            {productsdata.name}
                                        </Grid>
                                    )
                                }

                            }
                            else {
                                return (
                                    <div key={`link-item-${idx}`} className="dropdown-item discntr" >
                                        <ListItemIcon className='drpicon disicon'>
                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                        </ListItemIcon>
                                        {productsdata.name}
                                    </div>
                                )
                            }
                        })
                        }
                    
                        <Text type="p" className="mb-1 mt-2">Tools</Text> 

                        {toolsproducts && toolsproducts.map((productsdata, idx) => {

                            let pid = productsdata.id;
                            let iconnew = getIcon(pid);

                            
                            if (productsdata.access) {
                                return (
                                    <Link key={`link-item-${idx}`} to={productsdata.url} target="_blank" className="dropdown-item discntr">
                                        <ListItemIcon className='drpicon disicon'>
                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                        </ListItemIcon>{productsdata.name}
                                    </Link>
                                )
                            }
                            else {
                                return (
                                    <div key={`link-item-${idx}`} className="dropdown-item discntr">
                                        <ListItemIcon className='drpicon disicon'>
                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                        </ListItemIcon>{productsdata.name}
                                    </div>
                                )
                            }
                        })
                        }

                    </AccordionDetails>
                </Accordion>

                <Accordion className="nav-item" expanded={expanded === 'NavItemA3'} onChange={handleChange('NavItemA3')}>
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="nav-link"
                    >
                        <Link to="/insights" >Insights</Link>
                    </AccordionSummary>
                </Accordion>

                <Accordion className="nav-item" expanded={expanded === 'NavItemA4'} onChange={handleChange('NavItemA3')}>
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="nav-link"
                    >
                        <Link to="/faqs" state={{ productdashboardmenu: props.productdashboardmenu }}>Support</Link>
                    </AccordionSummary>
                </Accordion>
            </Menu> 
        </Box>
    )
}