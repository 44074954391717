import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../assets/images/logo.png';
import ImgBottom from '../assets/images/login-bg.png';
import Text from '../assets/components/ui/atoms/Text.jsx';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { fetchPost } from "../services/apiCalls";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ],
    },
  });
  
export default function ConfirmPassword() {

    const location = useLocation();
    const navigate = useNavigate();

    let activationCode = "";

    if (location.state !== null) {
        activationCode = location.state.activationCode;
    }
    else {
        navigate("/forgot-password");
    }
    
    if (activationCode == "" || activationCode == null) {
        navigate("/forgot-password");
    }

    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [togglepassword, setTogglePassword] = useState(false);
    const [togglepasswordconfirm, setTogglePasswordConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [buttonlabel, setButtonLabel] = useState('Reset Password');

    const togglePasswordHide = () => {
        setTogglePassword(!togglepassword);
    };

    const togglePasswordConfirmHide = () => {
        setTogglePasswordConfirm(!togglepasswordconfirm);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");

        if (password == "") {
            setSuccessMessage("");
            setErrorMessage("Please check your Password!");
        }
        else if (confirmpassword == "") {
            setSuccessMessage("");
            setErrorMessage("Please check your Confirm Password!");
        }
        else if (password != confirmpassword) {
            setSuccessMessage("");
            setErrorMessage("Password and Confirm Password Not Matched!");
        }
        else {
            setButtonLabel("Resetting Password...");
            const response = await fetchPost('/api/portal/reset-password', { activationCode, password }); 
            
            if (response.status == "Y" && response.message !== null) {
                setErrorMessage("");
                setSuccessMessage(response.message);
                navigate(
                    '/login',
                    {
                        state: {
                            'successmsg': response.message
                        }
                    }
                );
            }
            else {
                setSuccessMessage("");
                setErrorMessage(response.message);
                setButtonLabel("Reset Password");
            }
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box className="loginbox">
                    <img src={logo} alt='Costmine Intelligence' />
                    <Box className="box-container  p-6 mt-5" component="form" onSubmit={handleSubmit} noValidate >
                        <Box className="positionRelative zIndex1 text-center">
                            {errorMessage && <Text type='small' className="alertmsg errormsg" id='errormsg'> {errorMessage} </Text>}
                            {successMessage && <Text type='small' className="alertmsg successmsg" id='successmsg'> {successMessage} </Text>}
                            <Text type='h2'  ><span className='text-gradient'>Confirm Password</span></Text>
                            <TextField
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                type={togglepassword ? "text" : "password"}
                                name="password"
                                autoComplete=""
                                autoFocus
                                className="form-control mt-2 mb-1"
                                onChange={e => setPassword(e.target.value)}
                                variant='filled'                               
                                InputProps={
                                    {
                                        endAdornment: (
                                            <InputAdornment position="end"> {
                                                togglepassword ? (
                                                    <Visibility className="cursor_pointer"
                                                        onClick={togglePasswordHide}
                                                    />
                                                ) : (
                                                    <VisibilityOff onClick={togglePasswordHide}
                                                    />
                                                )
                                            }
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            />
                            <TextField
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type={togglepasswordconfirm ? "text" : "password"}
                                id="confirmPassword"
                                autoComplete=""
                                className="form-control"
                                onChange={e => setConfirmPassword(e.target.value)}
                                variant="filled"
                                InputProps={
                                    {
                                        endAdornment: (
                                            <InputAdornment position="end"> {
                                                togglepasswordconfirm ? (
                                                    <Visibility className="cursor_pointer"
                                                        onClick={togglePasswordConfirmHide}
                                                    />
                                                ) : (
                                                    <VisibilityOff onClick={togglePasswordConfirmHide}
                                                    />
                                                )
                                            }
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            />
                            <Button
                                type="submit"
                                fullWidth
                                disableElevation
                                variant="contained"
                                className="btn-gradient mt-3 mb-2"
                               
                            >
                                {buttonlabel}
                            </Button>
                            
                        </Box>
                    </Box>
                </Box>  
            </Container>
            <img src={ImgBottom} alt="Costmine Intelligence" className='imgbottom' />
        </ThemeProvider>
    );
}