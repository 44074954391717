import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import WebViewer from '@pdftron/webviewer';

const accessToken = localStorage.getItem('accessToken');

function PdfviewerWeb(documentdata) {

    const viewer = useRef(null);
    const [pdfViewerLink, setPdfViewerLink] = useState([]);
    let DocumentLink = documentdata.datalink;
    let DocumentName = documentdata.dataname;

    const params = {
        s3_link: DocumentLink,
        filename: DocumentName,
        use_in_viewer: true
    };

    const fetchData = () => {
        return fetch('/api/portal/document/download', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(params)
        })
            .then((response) => response.json())
            .then(data => {
                setPdfViewerLink(data);

                WebViewer(
                    {
                        path: '/webviewer/lib',
                        initialDoc: data,
                    },
                    viewer.current,
                ).then((instance) => {
                    const { docViewer } = instance;
                    // you can now call WebViewer APIs here...
                });


            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (

        <Grid className="MyComponent">
            <Grid className="webviewer" ref={viewer} style={{ height: "100vh" }}></Grid>
        </Grid>

    );
}


export default PdfviewerWeb;