import Mcsicon from '../assets/images/mining-cost-service.png';
import Ecsicon from '../assets/images/equipment-cost-calculator.png';
import Ghlicon from '../assets/images/gold-heap-leach.png';
import Rcgicon from '../assets/images/reclamation.png';
import Lbricon from '../assets/images/labor.png';
import Srficon from '../assets/images/sherpa-surface.png';
import Undicon from '../assets/images/sherpa-underground.png';
import Mnpricon from '../assets/images/sherpa-mineral-processing.png';
import Miicon from '../assets/images/mining-intelligence.png';
import Apxicon from '../assets/images/apex.png';

export const getIcon = (id) => {
    const iconsObject = {
        1: Lbricon,
        2: Lbricon,
        4: Ghlicon,
        5: Mcsicon,
        6: Ecsicon,
        7: Rcgicon,
        9: Srficon,
        10: Undicon,
        11: Rcgicon,
        12: Mnpricon,
        14: Apxicon,
        15: Miicon,
        17: Ecsicon,
        18: Lbricon,
        19: Lbricon,
        20: Lbricon
    }
    return iconsObject[id] || Mcsicon;
}