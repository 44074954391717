import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { InputLabel } from '@mui/material';
import { format } from 'date-fns';
import Text from '../../assets/components/ui/atoms/Text.jsx';
import { Link } from 'react-router-dom';
import { fetchGet } from "../../services/apiCalls";

const accessToken = localStorage.getItem('accessToken');

function DocumentDataView(popupLinkId) {

    const [responseData, setResponseData] = useState();

    const fetchDataDoc = async () => {
        var response = await fetchGet('/api/cms/document/meta?documentId=' + popupLinkId.data);
        if (response.document === undefined || response.document === null) {
        }
        else {
            setResponseData(response);
        }
    }

    useEffect(() => {
        fetchDataDoc();
    }, [])

    return (            

        <Container className='viewdocs'>
            {responseData && 
                <>
                <Grid container  className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Document Name
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>             
              
               <Text>
                {responseData ? responseData.document.name : ''}
               </Text>
              </Grid>
              </Grid>

            <Grid container  className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
                    <InputLabel>
                        Thumbnail
                    </InputLabel>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Text>
                        {responseData ? '' : 'NA'}
                        {responseData && responseData.document.thumbnailKey && <Link to={"https://ci-thumbnail.s3.us-west-2.amazonaws.com/" + responseData.document.thumbnailKey} target="_blank" className="btn-secondary   viewbtn" >View</Link>}
                    </Text>
                    

                </Grid>
            </Grid>


              <Grid container  className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Commodity
               </InputLabel>
              </Grid>

                <Grid item xs={12} sm={8} md={9}>
                    
                        {responseData && responseData.commodityDocRelations.map(i => {
                            return (
                                <Grid className="viewmeta">{i.commidityName_ReadOnly}</Grid>  
                            )
                        })}
                   
              
              </Grid>
              </Grid>

              <Grid container className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Region
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    <Text>
                        {responseData && responseData.regionDocRelations.map(i => {
                            return (
                                <Grid className = "viewmeta" > { i.regionName_ReadOnly }</Grid>
                            )
                        })}
                    </Text>
              </Grid>
              </Grid>

              <Grid container   className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Processing Tech
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    <Text>
                        {responseData && responseData.processingTechDocs.map(i => {
                            return (
                                <Grid className = "viewmeta" > { i.processingTechName_ReadOnly }</Grid>
                            )
                        })}
                    </Text>
              </Grid>
              </Grid>

              <Grid container   className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Geology
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    <Text>
                        {responseData && responseData.geologyDocRelations.map(i => {
                            return (
                                <Grid className = "viewmeta" > { i.geologyName_ReadOnly }</Grid>
                            )
                        })}
                    </Text>
              </Grid>
              </Grid>

              <Grid container   className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Topic
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    <Text>
                        {responseData && responseData.topicDocRelations.map(i => {
                            return (
                                <Grid className = "viewmeta" > { i.topicName_ReadOnly }</Grid>
                            )
                        })}
                    </Text>
              </Grid>
              </Grid>

              <Grid container   className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Year
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    <Text>{responseData ? responseData.document.year : ''}</Text>
              </Grid>
              </Grid>

              <Grid container   className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Feature List
               </InputLabel>
              </Grid>
                <Grid item xs={12} sm={8} md={9}>
                    <Text><Grid className="viewmeta" >{responseData ? responseData.feature.featureName : ''}</Grid></Text>
              </Grid>
              </Grid>


              <Grid container   className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Updated Date
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>           
                    <Text>{responseData ? format(new Date(responseData.document.lastUpdated), 'MM/dd/yyyy') : '-'}</Text>
              </Grid>
              </Grid>

              <Grid container  className='cmsinput'>
                <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Analyst Involved
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    <Text>
                        {responseData && responseData.analysts.map(i => {
                            return (
                                <Grid className="viewmeta" > {i.name}</Grid>
                            )
                        })}
                    </Text>  
                    
              </Grid>
            </Grid>
            </>
             }

              

              </Container>
           
    );
}


export default DocumentDataView;