import ActionLogout from '../component/ActionLogout.js';

const accessToken = localStorage.getItem('accessToken');

export const fetchPost = async (url, credentials) => {
    var res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        body: JSON.stringify(credentials)
    })
        .then(data => {
            if (data.status == 401) {
                ActionLogout();
            }
            else {
                return data.json();
            }
        })
    return res;

}

export const fetchGet = async (url) => {
    var res = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
    })
        .then(response => {
            if (response.status == 401) {
                ActionLogout();
            }
            else {
                return response.json();
            }
        })
    return res;
}

export function fetchHtml(url) {
    fetch(url)
        .then((response) => {
            return response.text();
        })
        .then((html) => {
            document.getElementById("insightContentgrid").innerHTML = html
            return html;
        });
}

export const fetchDelete = async (url) => {
    var res = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
    })
        .then(response => {
            if (response.status == 401) {
                ActionLogout();
            }
            else {
                return response;
            }
        })
    return res;
}

export const fetchPostStatus = async (url, credentials) => {
    var res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        body: JSON.stringify(credentials)
    })
        .then(data => {
            if (data.status == 401) {
                ActionLogout();
            }
            else {
                return data;
            }
        })
    return res;

}