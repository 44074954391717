import React, { useState } from "react";
// import '../assets/css/form.css';
import '../css/form.css';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import Text from '../assets/components/ui/atoms/Text.jsx';
import Text from '../components/ui/atoms/Text';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Box from '@mui/material/Box';

export default function WelcomeEmail() {



    return (


        <Grid container spacing={2} sx={{ mt: 2 }} component="form" noValidate className='whtiebgemailfrom'>
            <Box className= 'p-2'>
            <Text>Last Updated Date : 25/06/2023</Text>
            <TextField fullWidth label="SUBJECT" className="emailformControl mt-3 " id="fullWidth" />
            <textarea placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry." className="emailformControl formControl mt-3" name="message" rows={3}  required />
            <Grid item xs={12} className="prebtn">
                <Button type="submit" disableElevation variant="contained" className="btn-gradient mt-3" style={{marginRight:'5px'}}>PREVIEW</Button>
            <Button type="submit" disableElevation variant="contained" className="btn-gradient mt-3">SEND</Button>
</Grid>
          

            </Box>
          
        </Grid>
    );
}