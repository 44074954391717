import React from 'react';
import Text from '../../assets/components/ui/atoms/Text.jsx';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FooterLogo from '../../assets/images/footer-logo.png';


export default function Footer() {
  return (
        <Container component="footer" maxWidth="false" className='footerstrip' >
            <Grid container>
              <Grid item xs={12} sm={6} className='copylogo discntr'> 
                  <img src={FooterLogo} alt="Costmine Intelligence" />
                  <Link to="https://www.glacierrig.com/policies/" target="_blank" className="text-link"> <Text type='small'>Policies</Text> </Link> <Link to="/faqs" className="text-link"> <Text type='small'>Contact</Text> </Link>
                 </Grid>
                 <Grid item xs={12} sm={6} className='copytext'> 
                 <Link to="#" className="text-link"> <Text type='small'>©InfoMine USA, Inc.</Text> </Link>
                 </Grid>                
            </Grid>          
            </Container>
  )
}
