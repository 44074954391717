import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import logo from '../assets/images/logo.png';
import Text from '../assets/components/ui/atoms/Text.jsx';
import ImgBottom from '../assets/images/login-bg.png';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { fetchPost } from "../services/apiCalls";

const accessToken = localStorage.getItem('accessToken');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

export default function SignIn() {

    const navigate = useNavigate();
    const location = useLocation();
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [togglepassword, setTogglePassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [buttonlabel, setButtonLabel] = useState('Login');

    let currentpath = localStorage.getItem("currentpath");

    const togglePasswordHide = () => {
        setTogglePassword(!togglepassword);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        
        if (username == "") {
            setSuccessMessage("");
            setErrorMessage("Please check your Email!");
        }
        else if (password == "") {
            setSuccessMessage("");
            setErrorMessage("Please check your Password!");
        }
        else {
            setButtonLabel("Signing in...");
            const response = await fetchPost('/api/portal/login', {
                username,
                password
            });

            if (response.accessToken === undefined || response.accessToken === null)
            {
                    setErrorMessage(response.message);
                    setSuccessMessage("");
                    setButtonLabel("Login");
            }
            else
            {
                setErrorMessage("");
                setSuccessMessage("");

                localStorage.setItem('accessToken', response.accessToken);
                localStorage.setItem('user', username);
                localStorage.setItem('userrole', response.role);
                

                if (currentpath === null) {
                    window.location.href = "/dashboard";
                }
                else {
                    window.location.href = currentpath;    
                }

                
            }
        }
    };

    function isLogin() {
        if (accessToken) {
            navigate("/dashboard");
        }
    }

    useEffect(() => {
        isLogin();
        if (location.state !== null) {
            setErrorMessage("");
            setSuccessMessage(location.state.successmsg);
        }
        if (location.search !== "" && location.search == "?ex") {
            setSuccessMessage("");
            setErrorMessage("Your subscription is expired");
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box className="loginbox">
                    <img src={logo} alt='Costmine Intelligence' />                   
                    
                    <Box className="box-container  p-5 mt-4" component="form" onSubmit={handleSubmit} noValidate >
                        <Box className="positionRelative zIndex1">
                            {errorMessage && <Text type='small' className="alertmsg errormsg" id='errormsg'> {errorMessage} </Text>}
                            {successMessage && <Text type='small' className="alertmsg successmsg" id='successmsg'> {successMessage} </Text>}
                            <TextField                               
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="username"
                                autoComplete="email"
                                autoFocus
                                className="form-control mb-3"
                                variant='filled'
                                onChange={e => setUsername(e.target.value)}                               
                            />
                            <TextField                              
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={togglepassword ? "text" : "password"}
                                id="password"
                                autoComplete="current-password"
                                className="form-control"
                                onChange={e => setPassword(e.target.value)}
                                variant="filled"
                                InputProps={
                                    {
                                        endAdornment: (
                                            <InputAdornment position="end"> {
                                                togglepassword ? (
                                                    <Visibility className="cursor_pointer"
                                                        onClick={togglePasswordHide}
                                                    />
                                                ) : (
                                                    <VisibilityOff onClick={togglePasswordHide}
                                                    />
                                                )
                                            }
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            />
                            <Button
                                type="submit"
                                fullWidth
                                disableElevation
                                variant="contained"
                                className="btn-gradient mt-3 mb-2"
                            >
                                {buttonlabel}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Typography align='center'>
                                        <Link to="/forgot-password" className="text-link" variant="body">Forgot password?</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                       
                    </Box>
                </Box>  
                <Text type='small' className="mt-3 lgcnt">Please contact <a href="mailto:support@costmine.com" className="attchmentlink">support@costmine.com</a> if you have any questions or run into any issues.</Text>
            </Container>
            <img src={ImgBottom} alt="Costmine Intelligence" className='imgbottom' />
        </ThemeProvider>
    );
}