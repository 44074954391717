import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './FontStyle.css';
import App from './App';

import './assets/fonts/SemplicitaPro-Light.woff2';
import './assets/fonts/SemplicitaPro-Regular.woff2';
import './assets/fonts/SemplicitaPro-Medium.woff2';
import './assets/fonts/SemplicitaPro-SemiBold.woff2';
import './assets/fonts/SemplicitaPro-Bold.woff2';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App /> 
);

