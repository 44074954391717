import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Header from './include/Header.js';
import Footer from './include/Footer.js';
import { format } from 'date-fns';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { fetchGet, fetchPost, fetchDelete, fetchHtml } from "../services/apiCalls.js";
import dayjs, { Dayjs } from 'dayjs';

const accessToken = localStorage.getItem('accessToken');

const InsightNewsletter_Page = () => {
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Semplicita Pro'
            ],
            fontWeight: [
                '400'
            ]
        },
    });

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [openAlertNewUpload, setOpenAlertNewUpload] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(true);
    const [dataWelcomeEmail, setDataWelcome] = useState("");
    const [lastUpdated, setLastupdateDate] = useState("");
    const [switchActive, setSwitchActive] = useState(false);
    const [formMode, setFormMode] = useState("");
    const [dataPlaceHolder, setDataPlaceHolder] = useState("");
    const [notificationId, setNotificationId] = useState("");
    

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertNewUpload(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    let insightId = "";
    let authorName = "";
    if (location.state !== null) {
        insightId = location.state.insightId;
        authorName = location.state.authorName;
        console.log(insightId);
        console.log(authorName);
    }

    const handledate = (x, event) => {
        let ldate = JSON.stringify(x);
        setLastupdateDate(ldate.replace(/['"]+/g, ''));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        const data = new FormData(event.currentTarget);
        console.log('fdff');
        console.log(switchActive);

        let subject = data.get('subject');
        let message = data.get('message');
        let updateddate = lastUpdated;
        let realupdateddate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

        console.log(formMode);

        console.log(subject);
        console.log(message);
        console.log(updateddate);

        if (subject == "") {
            setErrorMessage("Please add subject");
        }
        else if (message == "") {
            setErrorMessage("Please add content");
        }
        else if (updateddate == "") {
            setErrorMessage("Please select schedule date");
        }
        else {
            setErrorMessage("");
            setSuccessMessage("");

            const insightnotification = {
                "insightId": parseInt(insightId),
                "deliveryTime": updateddate,
                "isDelivered": dataWelcomeEmail.isDelivered,
                "emailSubject": subject,
                "htmlcontent": message,
                "mode": formMode,
                "active": switchActive,
                "url": "string",
                "lastUpdated": realupdateddate
            }

            if (formMode == "preview") {
                localStorage.setItem("displayInsightSubject", subject);
                localStorage.setItem("displayInsightBody", message);
                window.open("/admin/insight-newsletter-preview", '_blank');


            } else {
                const resfinalupload = await handlefinaluploadtemplate(insightnotification);
            }


            

        }

    }

    function handlefinaluploadtemplate(insightnotification) {

        console.log(insightnotification);
        //setOpenAlertNewUpload(true);

        return fetch('/api/cms/insight-notification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(insightnotification)
        })
            .then(data => {
                if (data.status == 200) {
                    setErrorMessage("");
                    setSuccessMessage("Insight notification updated sccessfully...");
                    setOpenAlertNewUpload(true);
                    //setTimeout(function () { window.location.href = "/admin/insight-list" }, 3000);
                }
                else {
                    setSuccessMessage("");
                    setErrorMessage("Something went wrong. Please try again.");
                }
            });
    }

    const handleClickfordownloaddocument = async (s3_link, filename, use_in_viewer) => {
        const response = await downloaddocumentrequest({
            s3_link,
            filename,
            use_in_viewer
        });


    };

    async function downloaddocumentrequest(downlddocumentrequest) {
        var data = await fetchPost('/api/portal/insight/download', downlddocumentrequest);
        setErrorMessage("");
        if (data.status == "E" && data.message !== null) {
            setErrorMessage("");
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessage(data.message);
        }
        else {
            setErrorMessage("");
            //get content from url
            var tempdata = await fetchHtml(data);

        }

    }

    const fetchData = async () => {

        var datawelcome = await fetchGet('/api/cms/insight-notification?InsightId=' + insightId);

        if (datawelcome.status == "E" && datawelcome.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
        } else if (datawelcome.status == "N" && datawelcome.message !== null) {
            setErrorMessage(datawelcome.message);
            setDisplayLoader(false);
        }
        else {
            if (datawelcome.emailSubject != null) {
                setDataWelcome(datawelcome);
                console.log('fdfdf');
                console.log(datawelcome);
                setNotificationId(datawelcome.id);
                setLastupdateDate(datawelcome.deliveryTime);
                setSwitchActive(datawelcome.active);

                var temp = await handleClickfordownloaddocument(datawelcome.s3BodyLink, datawelcome.emailSubject, true);
                setDisplayLoader(false);
            }
            
        }

        var dataplaceholder = await fetchGet('/api/cms/email-placeholder');

        if (dataplaceholder.status == "E" && dataplaceholder.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
        } else if (dataplaceholder.status == "N" && dataplaceholder.message !== null) {
            setErrorMessage(dataplaceholder.message);
            setDisplayLoader(false);
        }
        else {
            setDataPlaceHolder(dataplaceholder.filter(el => el.emailType == "Insight"));
        }


    }

    const handleToggle = async (name: string) => {
        setSwitchActive(!switchActive);
        
        /*if (switchActive == true) {
            var response = await fetchDelete("/api/cms/insight-notification/" + notificationId);
            if (response.status == 200) {
                setOpenAlertNewUpload(true);
            }
        }*/
    };


    useEffect(() => {
        fetchData();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Header />

            <Snackbar open={openAlertNewUpload} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity="success" className="w-100">
                    Insight Notification Updated Successfully!
                </Alert>
            </Snackbar>

            <Container component="main" maxWidth="lg" className='main-container'>

                <Container className='whtiebgmain'>
                    <Grid xs={12}>
                        <Link to="/admin/insight-list"><Text type='h2' className=' d-inline-block backlink mr-1'><FontAwesomeIcon icon={faCircleArrowLeft} size='10px' /> </Text></Link>
                        <Text type='h2' className='text-gradient d-inline-block mb-3 '>INSIGHT NEWSLETTER</Text>
                        <Stack direction="row" spacing={0} alignItems="center" className='switch'>
                                <Typography>Inactive</Typography>
                                <Switch
                                    checked={switchActive}
                                    color="primary"
                                    className='cmsinputspan'
                                    value="active"
                                    onClick={() => handleToggle('active')}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography>Active</Typography>
                            </Stack>

                    </Grid>

                    {dataWelcomeEmail && !displayLoader && 
                    <Box component="form" noValidate onSubmit={handleSubmit} className='whtiebgemailfrom p-4'>


                        <Grid container spacing={3}>
                           
                            <Grid item xs={6} className='mt-2'><Text>Author: {authorName}</Text>
                            </Grid>
                            <Grid item xs={6} className='cmsinput datepicker'>

                          
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4} className='mt-1'></Grid>
                                        <Grid item xs={12} sm={8} >
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer components={['DateTimePicker ']}>
                                                    <DateTimePicker label="Schedule Email Newsletter" format="MM/DD/YYYY HH:mm" className='cmsinputtg emaildate w-100' defaultValue={dayjs(dataWelcomeEmail.deliveryTime)} onChange={(x, event) => handledate(x, event)} />
                                            </DemoContainer>
                                            </LocalizationProvider>


                                        </Grid>

                                        {/*<Grid item xs={12} sm={4}  className='mt-1'>  <Button variant="contained" component="label" className="bg-blue-gradient button text-white w-100 pt-1 pb-1" >Schedule</Button></Grid>*/}
                                        
                                        </Grid>


                            </Grid>

                            <Grid item xs={12} className='cmsinput'>
                            <TextField
                                    margin="none"
                                    fullWidth
                                    id="subject"
                                    name="subject"
                                    className="form-control cmsinputtg cmsinputborder mt-1"
                                    variant='filled'
                                    placeholder='Subject'
                                    defaultValue={dataWelcomeEmail.emailSubject}
                                />

                                    <Text className='attchmentbtn p-1 mr-1 mb-3 nobg' type='small'>Placeholders : </Text>
                                    {dataPlaceHolder && !displayLoader && dataPlaceHolder.length > 0 && dataPlaceHolder.map(item => {
                                        return (< Text className='attchmentbtn p-1 mr-1 mt-3' type='small'>{item.emailPlaceHolder}</Text>)
                                    })}

                                <textarea placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry." className="formControl cmsinputtg cmsinputborder inputhtml" name="message" rows={8} id="insightContentgrid" />
                            </Grid>
                        </Grid>
                        <Grid container className='contbtn mt-2'>
                                
                                <Button
                                    type="submit"
                                    disableElevation
                                    variant="contained"
                                    onClick={() => setFormMode("preview")}
                                    className="btn-gradient mr-2 samewidth"
                                >PREVIEW</Button>

                                {!switchActive &&
                                    <>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            variant="contained"
                                            onClick={() => setFormMode("draft")}
                                            className="btn-gradient mr-2 samewidth"
                                        >SAVE</Button>

                                        <Button
                                            disableElevation
                                            variant="contained"
                                            onClick={() => console.log('Publish')}
                                            className="btn-gradient samewidth disabled"
                                        >PUBLISH</Button>

                                    </>
                                }

                                {switchActive &&
                                    <>
                                        <Button
                                            disableElevation
                                            variant="contained"
                                            onClick={() => console.log('save')}
                                            className="btn-gradient mr-2 samewidth disabled"
                                        >SAVE</Button>

                                        <Button
                                            type="submit"
                                            disableElevation
                                            variant="contained"
                                            onClick={() => setFormMode("Publish")}
                                            className="btn-gradient samewidth"
                                        >PUBLISH</Button>
                                    </>}
                          
                         
                            </Grid>

                            {errorMessage && <Text type='small' className="errormsg" id='errormsg'> {errorMessage} </Text>}
                            {successMessage && <Text type='small' className="successmsg" id='successmsg'> {successMessage} </Text>}

                    </Box>
                    }

                    {!dataWelcomeEmail && displayLoader &&
                        <Box component="form" noValidate onSubmit={handleSubmit} className='whtiebgemailfrom p-4'>


                            <Grid container spacing={3}>

                                <Grid item xs={6} className='mt-2'><Text>Author: {authorName}</Text>
                                </Grid>
                                <Grid item xs={6} className='cmsinput datepicker'>


                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4} className='mt-1'></Grid>
                                        <Grid item xs={12} sm={8} >
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer components={['DateTimePicker ']}>
                                                    <DateTimePicker label="Schedule Email Newsletter test" format="MM/DD/YYYY HH:mm" className='cmsinputtg emaildate w-100' onChange={(x, event) => handledate(x, event)} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                       
                                        {/*<Grid item xs={12} sm={4}  className='mt-1'>  <Button variant="contained" component="label" className="bg-blue-gradient button text-white w-100 pt-1 pb-1" >Schedule</Button></Grid>*/}

                                    </Grid>


                                </Grid>

                                <Grid item xs={12} className='cmsinput'>
                                    <TextField
                                        margin="none"
                                        fullWidth
                                        id="subject"
                                        name="subject"
                                        className="form-control cmsinputtg cmsinputborder mb-3 mt-1"
                                        variant='filled'
                                        placeholder='Subject'
                                        
                                    />
                                    <textarea placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry." className="formControl cmsinputtg cmsinputborder inputhtml" name="message" rows={8}  />
                                </Grid>
                            </Grid>
                            <Grid container className='contbtn mt-2'>
                                <Button
                                    type="submit"
                                    disableElevation
                                    variant="contained"
                                    onClick={() => setFormMode("preview")}
                                    className="btn-gradient mr-2 samewidth"
                                >PREVIEW</Button>

                                {!switchActive &&
                                    <>
                                    <Button
                                        type="submit"
                                        disableElevation
                                        variant="contained"
                                        onClick={() => setFormMode("draft")}
                                        className="btn-gradient mr-2 samewidth"
                                    >SAVE</Button>

                                    <Button
                                        disableElevation
                                        variant="contained"
                                        onClick={() => console.log('Publish')}
                                        className="btn-gradient samewidth disabled"
                                    >PUBLISH</Button>

                                    </>
                                    }

                                {switchActive &&
                                    <>
                                    <Button
                                        disableElevation
                                        variant="contained"
                                        onClick={() => console.log('save')}
                                        className="btn-gradient mr-2 samewidth disabled"
                                    >SAVE</Button>

                                    <Button
                                        type="submit"
                                        disableElevation
                                        variant="contained"
                                        onClick={() => setFormMode("Publish")}
                                        className="btn-gradient samewidth"
                                        >PUBLISH</Button>
                                    </>}


                            </Grid>

                            {errorMessage && <Text type='small' className="errormsg" id='errormsg'> {errorMessage} </Text>}
                            {successMessage && <Text type='small' className="successmsg" id='successmsg'> {successMessage} </Text>}

                        </Box>
                    }
                </Container>
            </Container>
            <Footer />
        </ThemeProvider>
    )
}

export default InsightNewsletter_Page
