import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../assets/images/logo.png';
import ImgBottom from '../assets/images/login-bg.png';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { fetchPost } from "../services/apiCalls";

const accessToken = localStorage.getItem('accessToken');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

export default function ActiveForgotPassword() {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    
    let ActivationCode = searchParams.get("code");

    const handleSubmit = async () => {
        if (ActivationCode == "" || ActivationCode == null) {
            navigate("/forgot-password");
        }
        else {
            setErrorMessage("");
            setSuccessMessage("");

            const response = await fetchPost('/api/portal/activation-code', ActivationCode);
            
            if (response.firstName === undefined || response.firstName === null) {
                setSuccessMessage("");
                setErrorMessage(response.message);
            }
            else {
                setErrorMessage("");
                setSuccessMessage("Activation code confirmed.");
                navigate(
                    '/confirm-password',
                    {
                        state: {
                            'activationCode': ActivationCode
                        }
                    }
                );
            }

        }
        
    };

    function isLogin() {
        if (accessToken) {
            navigate("/dashboard");
        }
    }

    useEffect(() => {
        isLogin();
        handleSubmit();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box className="loginbox">
                    <img src={logo} alt='Costmine Intelligence' />
                    <Box className="box-container" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5, p: 6 }}>
                        <Box sx={{ position: 'relative', zIndex: 1 }}>
                            <Text type='h2' sx={{textAlign: 'center', marginBottom:'15px'}} ><span className='text-gradient'>Verify Activation Code</span></Text>
                            {errorMessage && <Text type='small' sx={{ color: "red" }} id='errormsg'> {errorMessage} </Text>}
                            {successMessage && <Text type='small' sx={{ color: "green" }} id='successmsg'> {successMessage} </Text>}
                        </Box>
                    </Box>
                </Box>  
            </Container>
            <img src={ImgBottom} alt="Costmine Intelligence" className='imgbottom' />
        </ThemeProvider>
    );
}