import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Header from './include/Header';
import Footer from './include/Footer.js';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import DocumentData from './component/DocumentData.js'
import DocumentDataView from './component/DocumentDataView.js'
import DocumentDataUpload from './component/DocumentDataUpload.js'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import CheckIsLogin from './component/CheckIsLogin.js';
import ActionLogout from '../component/ActionLogout.js';
import ReactPaginate from 'react-paginate';
import Mcsicon from '../assets/images/mining-cost-service.png';
import Ecsicon from '../assets/images/equipment-cost-calculator.png';
import Ghlicon from '../assets/images/gold-heap-leach.png';
import Rcgicon from '../assets/images/reclamation.png';
import Lbricon from '../assets/images/labor.png';
import { fetchGet, fetchPost, fetchDelete, fetchPostStatus } from "../services/apiCalls";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const accessToken = localStorage.getItem('accessToken');

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle
            {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" onClick={onClose} >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export default function Dashbaord() {

    let CurrentYear = new Date().getFullYear();
    const [open, setOpen] = React.useState(false);
    const [responseDefaultData, setResponseDefaultData] = useState();
    const [modalDescription, setModelDescription] = useState();
    const [modalTitle, setModelTitle] = useState();
    const [modalUrl, setModelUrl] = useState();
    const [modalTarget, setModelTarget] = useState();
    const [modalPid, setModelPid] = useState();
    const [modalIcon, setModalIcon] = useState('');
    const [productId, setproductId] = useState();
    const [year, setnewyear] = useState(new Date().getFullYear());
    const [productDescription, setProductDescription] = useState(''); 
    const [descriptionErrorMsg, setDescriptionErrorMsg] = useState(''); 
    const [descriptionSuccMsg, setDescriptionSuccMsg] = useState(''); 
    const [newSeriesId, setNewSeriesId] = useState(''); 
    const [CurrentPage, setCurrentPage] = useState(0);  
    const lproductID = (localStorage.getItem('productId') ? localStorage.getItem('productId') : 1);
    const lyear = (localStorage.getItem('year') ? localStorage.getItem('year') : new Date().getFullYear()); 
    const [dataproducts, setDataProducts] = useState([]);
    const [productName, setProductName] = useState("");

    const handleClickOpen = (e) => {
        setOpen(true);
        const datadesc = e.currentTarget.getAttribute("data-description");
        const datatit = e.currentTarget.getAttribute("data-title");
        const dataurl = e.currentTarget.getAttribute("data-url");
        const datatarget = e.currentTarget.getAttribute("data-target");
        const dataicon = e.currentTarget.getAttribute("data-icon");
        const datapid = e.currentTarget.getAttribute("data-pid");
        setModelDescription(datadesc);
        setModalIcon(dataicon);
        setModelTitle(datatit);
        setModelUrl(dataurl);
        setModelTarget(datatarget);
        setModelPid(datapid);
    };

    const handleClose = () => { setOpen(false); };
    const [responseData, setResponseData] = useState([]);
    const [dataClickedOn, setDataClickedOn] = useState([]);

    const fetchDataStart = async () => {
        var response = await fetchPost('/api/cms/document/update/start');
        setResponseDefaultData(response);
    }

    let datac = '';

    const fetchData = async () => {
        var data = await fetchGet('/api/cms/dashboard-start');
        setResponseData(data);
        setDataProducts(data.filter(el => el.type == 'Data'));
        //dataproducts
        //setProductName
        const currentproduct = data.filter(el => el.id == lproductID);
        setProductName(currentproduct[0].name);
        // console.log('currentdata');
        // console.log(currentproduct);
    }

    useEffect(() => {
        fetchData();
        fetchDataStart();
    }, [])

    const [responseProductData, setResponseProductData] = useState([]);

    const handleClickforrightside = async(productId, year) => {
        const response = await fethproductlist({
            productId,
            year
        });
        setCurrentPage(0);
        setproductId(productId);
        setnewyear(year);
        setDataClickedOn(productId);
        localStorage.setItem('productId', productId);
        localStorage.setItem('year', year);
    };

    async function fethproductlist(productrequest) {
        var data = await fetchPost('/api/cms/fetch-document-list', productrequest);
        setCurrentPage(0);
        setResponseProductData(data);
        let lastseriesid = data.length;
        let newseriesid = lastseriesid + 1;
        setNewSeriesId(parseInt(newseriesid));
    }

    const handleClickforviewdocument = (s3_link, filename, use_in_viewer) => {
        const params = {
            s3_link: s3_link,
            filename: filename,
            use_in_viewer: true
        };
        const response = downloaddocumentrequest(params, '_blank');
    };

    const handleClickfordownloaddocument = (s3_link, filename, open_new_tab) => {
        const response = downloaddocumentrequest({
            s3_link,
            filename,
            open_new_tab
        }, '_self');
        
    };

    async function downloaddocumentrequest(downlddocumentrequest, target) {
        var data = await fetchPost('/api/cms/document/download', downlddocumentrequest);
        window.open(data, target);
    }
    const [selectedyear, setyear] = useState(lyear);


    function changeyear(e) {
        setyear(e.target.value);
        setnewyear(e.target.value);
        localStorage.setItem('year', e.target.value);
        handleClickforrightside(productId, e.target.value);
    }

    const [Modal2, setOpenModal2] = React.useState(false);
    const [ModalView, setOpenModalView] = React.useState(false);
    const [ModalUpload, setOpenModalUpload] = React.useState(false);
    const [popupLinkId, SetPopupLinkId] = React.useState('');
    const [popups3LinkId, SetPopups3LinkId] = React.useState('');
    const [popupSeriesId, SetpopupSeriesId] = React.useState('');

    const ClickOpenModal2 = (linkid, s3linkid, seriesid) => {
        setOpenModal2(true);
        SetPopupLinkId(linkid);
        SetPopups3LinkId(s3linkid);
        SetpopupSeriesId(seriesid);
    };

    const ClickOpenModalView = (linkid) => {
        setOpenModalView(true);
        SetPopupLinkId(linkid);
    };

    const ClickOpenModalUpload = () => {
        setOpenModalUpload(true);
    };

    const CloseModal2 = () => { setOpenModal2(false); };
    const CloseModalView = () => { setOpenModalView(false); };
    const CloseModalUpload = () => { setOpenModalUpload(false); };

    function handlePageClick({ selected: selectedpage }) {
        setCurrentPage(selectedpage);
    }

    const PER_PAGE = 21;
    const offset = CurrentPage * PER_PAGE;
    const currentpagedata = responseProductData.slice(offset, offset + PER_PAGE);
    const pageCount = Math.ceil(responseProductData.length / PER_PAGE); 

    useEffect(() => {
        handleClickforrightside(lproductID, lyear);
        setCurrentPage(0);
    }, [])


    const handledelete = async (id) => {
        
        if (id != "") {
            var response = await fetchDelete("/api/cms/document/" + id);
            if (response.status == 200) {
                handleClickAlert();
                handleClickforrightside(productId, lyear);
            }
        }
    }


    async function documentseries(documentlist) {
        var response = await fetchPost('/api/cms/document-series', documentlist);
    }
    const dragItem = useRef();
    const dragOverItem = useRef();
    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const drop = (e) => {
        const copyListItems = [...responseProductData];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        const response = [];
        dragItem.current = null;
        dragOverItem.current = null;
        setResponseProductData(copyListItems);
        for (let i = 0; i < copyListItems.length; i++) {
            response.push({
                documentId: copyListItems[i].id, seriesId: i+1
            });
        }
        documentseries(response);
    };

    async function handleSaveDescription(event) {

        if (productDescription == "") {
            setDescriptionErrorMsg("Please edit product description");
        }
        else {
            const updatedescres = await fetchPostStatus('/api/portal/product', {
                id: modalPid,
                description: productDescription
            });

            if (updatedescres.status == 200) {
                setDescriptionErrorMsg("");
                setDescriptionSuccMsg("");
                fetchData();
                handleClickAlertEditDesc();
                handleClose();
            }
            else {
                setDescriptionErrorMsg("Something went wrong. Please try again.");
                setDescriptionSuccMsg("");
            }

        }
    }

    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertNewUpload, setOpenAlertNewUpload] = useState(false);
    const [openAlertEditUpload, setOpenAlertEditUpload] = useState(false);
    const [openAlertEditDesc, setOpenAlertEditDesc] = useState(false);

    const handleClickAlert = () => {
        setOpenAlert(true);
    };

    const handleClickAlertNewUpload = () => {
        setOpenAlertNewUpload(true);
    };

    const handleClickAlertEditUpload = () => {
        setOpenAlertEditUpload(true);
    };

    const handleClickAlertEditDesc = () => {
        setOpenAlertEditDesc(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        setOpenAlertNewUpload(false);
        setOpenAlertEditUpload(false);
        setOpenAlertEditDesc(false);
    };

    CheckIsLogin();

    return (
        <ThemeProvider theme={theme}>
            <Header />

            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity="success" className="w-100">
                        Document Deleted Successfully!
                    </Alert>
                </Snackbar>

            <Snackbar open={openAlertNewUpload} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity="success" className="w-100">
                    Document Uploaded Successfully!
                </Alert>
            </Snackbar>

            <Snackbar open={openAlertEditUpload} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity="success" className="w-100">
                    Document Updated Successfully!
                </Alert>
            </Snackbar>

            <Snackbar open={openAlertEditDesc} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity="success" className="w-100">
                    Product Description Updated Successfully!
                </Alert>
            </Snackbar>
            

            <Container component="main" maxWidth="lg" className='main-container'  >
                <Container className='whtiebg'>
                    
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Button variant="contained" fullWidth
                                component="label" className="uploadbtn"
                                onClick={() => ClickOpenModalUpload()} >
                                Upload document <FontAwesomeIcon icon={faCirclePlus} />
                            </Button>
                        </Grid>

                        <Grid item xs={12} className='lstdt'>
                            <Text type="p" >Year selected: </Text>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className='dropdownstyle current ml-1'                                
                                value={selectedyear}
                                onChange={changeyear}
                                MenuProps={{
                                    disableScrollLock: true,
                                }}
                            >
                                {responseDefaultData && (productId == 5 || productId == 6) && responseDefaultData.years.map(i => {
                                    return (
                                        <MenuItem value={i}>{i == CurrentYear ? "Current" : i}</MenuItem>
                                    )

                                })}

                                {responseDefaultData && productId != 5 && productId != 6 && responseDefaultData.years.slice(0, 2).map(i => {
                                    return (
                                        <MenuItem value={i}>{i == CurrentYear ? "Current" : i}</MenuItem>
                                    )

                                })}
                                
                            </Select>
                        </Grid>

                        <Grid item xs={4} sm={3} md={2}>

                            {responseData && dataproducts && dataproducts.map(productsdata => {
                                if (productsdata.id !== 8) {

                                    let pid = productsdata.id;
                                    let iconnew = Mcsicon;

                                    if (pid == 1) { iconnew = Lbricon; }
                                    if (pid == 2) { iconnew = Lbricon; }
                                    if (pid == 4) { iconnew = Ghlicon; }
                                    if (pid == 5) { iconnew = Mcsicon; }
                                    if (pid == 6) { iconnew = Ecsicon; }
                                    if (pid == 7) { iconnew = Rcgicon; }

                                    if (productsdata.active == true) {
                                        return (
                                            <Grid item className={dataClickedOn == productsdata.id ? 'dataproducts bg-gradient' : 'dataproducts'}>
                                                <Link onClick={handleClickOpen} className='infoicon' data-title={productsdata.name} data-icon={iconnew} data-pid={productsdata.id} data-description={productsdata.description}><FontAwesomeIcon icon={faCircleInfo} /></Link>
                                                <Link onClick={() => { handleClickforrightside(productsdata.id, selectedyear); setProductName(productsdata.name); }} className='databoxlink'><Text>
                                                    <img src={iconnew} alt={productsdata.name} /><br />{productsdata.name}
                                                </Text></Link>
                                            </Grid>
                                        )
                                    }
                                }
                            })
                            }
                        </Grid>

                        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="dsbmdl">
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className='text-blue-gradient modalhe' ><img src={modalIcon} alt={modalTitle} className="modalicon" /><text type='h2' >{modalTitle}</text></BootstrapDialogTitle>
                            
                               <DialogContent >
                                <Typography gutterBottom>
                                    <TextField
                                        margin="none"
                                        id="DataText"
                                        name="DataText"
                                        defaultValue={modalDescription}
                                        className="form-control edittext"
                                        variant='filled'
                                        rows={4}
                                        maxRows={8}
                                        onChange={e => setProductDescription(e.target.value)} 
                                        multiline

                                    />
                                </Typography>

                                <Typography gutterBottom  className='text-center'>
                                    {descriptionErrorMsg && <Text type='small' className='errormsg' id='errormsg'> {descriptionErrorMsg} </Text>}
                                    {descriptionSuccMsg && <Text type='small' className='successmsg' id='successmsg'> {descriptionSuccMsg} </Text>}
                                    <Button
                                    type="url"
                                    disableElevation
                                    variant="contained"
                                        className="btn-gradient mt-3 mb-2"
                                        onClick={(event) => handleSaveDescription()}
                                       >Save</Button></Typography>
                            </DialogContent>

                        </BootstrapDialog>

                        <BootstrapDialog onClose={CloseModal2} aria-labelledby="customized-dialog-title" open={Modal2} fullScreen className='editmodal dsbmdl'>
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseModal2} className="text-blue-gradient"><Text type='h2'>Edit Document</Text></BootstrapDialogTitle>
                            <DialogContent dividers >
                                <DocumentData data={popupLinkId} dataproductid={productId} dataproductlinkid={popups3LinkId} dataseriesid={popupSeriesId} CloseModal2={() => { setOpenModal2(false); handleClickforrightside(productId, lyear); }} handleClickAlertEditUpload={() => { setOpenAlertEditUpload(true); }} />
                            </DialogContent>
                          
                        </BootstrapDialog>

                        <BootstrapDialog onClose={CloseModalView} aria-labelledby="customized-dialog-title" open={ModalView} fullScreen className='editmodal dsbmdl'>
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseModalView} className="text-blue-gradient"><Text type='h2'>View Document</Text></BootstrapDialogTitle>
                            <DialogContent dividers >
                                <DocumentDataView data={popupLinkId} />
                            </DialogContent>
                           
                        </BootstrapDialog>

                        <BootstrapDialog onClose={CloseModalUpload} aria-labelledby="customized-dialog-title" open={ModalUpload} fullScreen className='editmodal dsbmdl'>
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseModalUpload} className="text-blue-gradient"><Text type='h2'>Upload Document</Text></BootstrapDialogTitle>
                            <DialogContent dividers >
                                <DocumentDataUpload dataproductid={productId} datanewseriesid={newSeriesId} CloseModalUpload={() => { setOpenModalUpload(false); handleClickforrightside(productId, lyear); }} handleClickAlertNewUpload={() => { setOpenAlertNewUpload(true);}}  />
                            </DialogContent>
                           
                        </BootstrapDialog>
                       
                        <Grid item xs={8} sm={9} md={10}>
                           
                            {responseProductData && currentpagedata && currentpagedata.map((i, index) => {
                                return (
                                    <Grid item className='productlist'>
                                        <Box className='move'> <FontAwesomeIcon icon={faEllipsisVertical} className='famove' /></Box>
                                        <Grid container spacing={3}  onDragStart={(e) => dragStart(e, index)}
                                            onDragEnter={(e) => dragEnter(e, index)}
                                            onDragEnd={drop}
                                            key={index}>
                                            <Grid item xs={12} md={7}>
                                                <Grid item className='dcname'><Text>{i.name}</Text></Grid>
                                            </Grid>
                                            <Grid item xs={12} md={5} className='ilcon'>
                                                <Grid className='iconlist'>
                                                    <Link to='/admin/email-notification' state={{ documentId: i.id, documentName: i.name, productName: productName }}><Text><FontAwesomeIcon icon={faEnvelope} className='icons' /></Text></Link>
                                                    <Link onClick={() => handleClickforviewdocument(i.link, i.name, true)}><Text><FontAwesomeIcon icon={faFilePdf} className='icons' /></Text></Link>
                                                    <Link onClick={() => handleClickfordownloaddocument(i.link, i.name, true)}><Text><FontAwesomeIcon icon={faFileArrowDown} className='icons' /></Text></Link>
                                                    <Link onClick={() => ClickOpenModalView(i.id)}><Text><FontAwesomeIcon icon={faEye} className='icons' /></Text></Link>
                                                    <Link onClick={() => ClickOpenModal2(i.id, i.link, i.series)}><Text><FontAwesomeIcon icon={faPenToSquare} className='icons' /></Text></Link>
                                                    <Link onClick={() => handledelete(i.id)}><Text className='remove'><FontAwesomeIcon icon={faTrashCan} className='icons' /></Text></Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}

                            {(responseProductData.length == 0) ? <Grid item xs={12} className='placeholder'>
                                <text type='p' > Currently no are documents available. Please upload.</text>
                            </Grid> : ''}

                            {responseProductData && currentpagedata && <ReactPaginate
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                forcePage={CurrentPage}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pageCount}
                                previousLabel="<"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />}

                        </Grid>

                    </Grid>
                </Container>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}