
import Typography from '@mui/material/Typography';

export default function Text({ type = "default", children, sx = {}, className = ""}) {

    const textTypes = {
        h1: { fontWeight: '500', fontSize: '28px', lineHeight: '32px', textTransform: 'uppercase', ...sx },
        h2: {fontWeight: '500', fontSize: '20px', lineHeight: '24px', textTransform: 'uppercase', ...sx },
        h3: { fontWeight: '500', fontSize: '18px', lineHeight: '20px', ...sx },
        p: {fontWeight: '400', fontSize: '16px', lineHeight: '20px', ...sx },
        success: {fontWeight: '400', fontSize: '13px', lineHeight: '16px', padding: '7px', border: '1px solid #6B9E41', borderRadius: '5px', color: '#6B9E41', textAlign: 'center', marginBottom: '20px'},
        small: {fontWeight: '500', fontSize: '12px', lineHeight: '14px', ...sx },
        smaller: {fontWeight: '500', fontSize: '11px', lineHeight: '13px', ...sx},
        smlight: {fontWeight: '500', fontSize: '10px', lineHeight: '12px', ...sx},
        menu: {fontWeight: '500', fontSize: '12px', lineHeight: '17px', ...sx},
        smallheading: {fontWeight: '600', fontSize: '14px', lineHeight: '16px', ...sx },
        productname: {fontWeight: '600', fontSize: '13px', lineHeight: '16px', color: '#fff', padding: '5px 10px 7px 10px', ...sx },
        insightname: {
            fontWeight: '500', 
            fontSize: '16px', 
            lineHeight: '20px', 
            color: '#fff', 
            padding: '5px 10px 7px 10px', 
            ...sx
        },
        label: { fontWeight: '400', fontSize: '0.875rem', lineHeight: '17px' },
        default: { ...sx }   
    }
    const sxProps = textTypes[type];
    
    return <Typography className={className} sx={sxProps}>{children}</Typography>
    
}
