import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faEnvelopesBulk, faLightbulb, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Grid from '@mui/material/Grid';
import Text from './ui/atoms/Text.jsx';
import { Link } from "react-router-dom";

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Tab from '../components/Tab.js'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Insightblock = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Grid container spacing={3} >

            <Grid item xs={12} sm={6}>
                <Link to="/admin/document-management" className='linkunderlineremove'><Grid item className='whtiebg mainbox'>
                    <Grid className='documentmang'>
                        <FontAwesomeIcon className=' documenttext' icon={faEnvelopeOpenText} /> <br />
                        <Text type="h2" className="p-2  texthovercolor ">DOCUMENT <br /> MANAGEMENT</Text></Grid>

                </Grid>
                </Link>
            </Grid>
            <Grid item xs={12} sm={6} >
                <Link to="#" className='linkunderlineremove'><Grid item className='whtiebg mainbox' >
                    <Grid className='documentmang' onClick={handleClickOpen}>
                        <FontAwesomeIcon className=' documenttext' icon={faEnvelopesBulk} /> <br />
                        <Text type="h2" className="p-2  texthovercolor ">EMAIL TEMPLATE <br />MANAGEMENT</Text></Grid>

                          
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        maxWidth="lg"
                        className='dsbmdl'
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Email Template Management                       
                         </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                            <Tab />
                            </Typography>

                        </DialogContent>
                     
                    </BootstrapDialog>



                </Grid>
                </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Link to="#" className='linkunderlineremove'><Grid item className='whtiebg mainbox'>
                    <Grid className='documentmang'>
                        <FontAwesomeIcon className=' documenttext' icon={faLightbulb} /> <br />
                        <Text type="h2" className="p-2  texthovercolor ">INSIGHT <br /> MANAGEMENT</Text></Grid>

                </Grid>
                </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Link to="#" className='linkunderlineremove'><Grid item className='whtiebg mainbox'>
                    <Grid className='documentmang'>
                        <FontAwesomeIcon className=' documenttext' icon={faMagnifyingGlass} /> <br />
                        <Text type="h2" className="p-2  texthovercolor ">AUDIT LOG <br /> MANAGEMENT</Text></Grid>

                </Grid>
                </Link>
            </Grid>
        </Grid>
    )
}

export default Insightblock
