import React from 'react'
import { Navigate, useLocation } from "react-router-dom"

const ProtectedRoute = ({ children }) => {
    const accessToken = localStorage.getItem('accessToken');
    let location = useLocation();

    if (accessToken == "" || accessToken == "undefined" || accessToken == null) {
        let currenpath = (location.pathname + location.search).substr(1);
        localStorage.setItem("currentpath", currenpath);
        return <Navigate to="/" replace />
    }
    else {
        localStorage.removeItem("currentpath");
        return children;
    }
};

export default ProtectedRoute;