import React, { useState, useEffect, ChangeEvent } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Text from '../../assets/components/ui/atoms/Text.jsx';
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { fetchGet, fetchPost } from "../../services/apiCalls";

const accessToken = localStorage.getItem('accessToken');

function uniq(a) {
    return Array.from(new Set(a));
}

function DocumentData(popupLinkId) {

    const [responseData, setResponseData] = useState();
    const [responseDefaultData, setResponseDefaultData] = useState();
    const [autocompleteCommodity, setAutocompleteCommodity] = useState([]);
    const [autocompleteAnalysts, setAutocompleteAnalysts] = useState([]);
    const [autocompleteRegion, setAutocompleteRegion] = useState([]);
    const [autocompleteProcess, setAutocompleteProcess] = useState([]);
    const [autocompleteGeo, setAutocompleteGeo] = useState([]);
    const [autocompleteTopic, setAutocompleteTopic] = useState([]);
    const [resonseYear, setResponseYear] = useState([]);
    const [resonseFeature, setResponseFeature] = useState([]);
    const [resonseAnalyst, setResponseAnalyst] = useState([]);
    const [resonseAnalystSelected, setAutocompleteAnalystsSelected] = useState([]);
    const [resonseThumbnailKey, setResonseThumbnailKey] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessageThumb, setErrorMessageThumb] = useState("");
    const [successMessageThumb, setSuccessMessageThumb] = useState("");
    const [lastUpdated, setLastupdateDate] = useState("");
    const [formDataCommidity, setFormDataCommidity] = useState("");
    const [formDataAnalyst, setFormDataAnalyst] = useState("");
    const [formDataRegion, setFormDataRegion] = useState("");
    const [formDataProcessing, setFormDataProcessing] = useState("");
    const [formDataGeology, setFormDataGeology] = useState("");
    const [formDataTopic, setFormDataTopic] = useState("");
    const [formS3Link, setFormS3Link] = useState("");
    const [thufile, setThuFile] = useState('');
    
    let testana = [];
    const fetchDataStart = async () => {
        var data = await fetchPost('/api/cms/document/update/start');
        setResponseDefaultData(data);
        data.analysts.map(i => {
            const newoptana = testana.push({ id: i.analystUserId, name: i.name });
        });
        setAutocompleteAnalysts(uniq(testana));
}

    useEffect(() => {
        fetchDataStart();
    }, []);

    
    let test = [];
    let testreg = [];
    let testproc = [];
    let testgeo = [];
    let testtop = [];
    let testana1 = [];
    

    const fetchDataDoc = async () => {
        var data = await fetchGet('/api/cms/document/meta?documentId=' + popupLinkId.data);
        setResponseData(data);
        setResponseYear(data.document.year);
        setResponseFeature(data.document.featureId);
        setResponseAnalyst(data.analysts[0].analystUserId);
        setResonseThumbnailKey(data.document.thumbnailKey);
        setFormS3Link(data.document.thumbnailKey);
        setLastupdateDate(data.document.lastUpdated);

        data.commodityDocRelations.map(i => {
            const newopt = test.push({ id: i.commodityId, name: i.commidityName_ReadOnly });
        });

        setAutocompleteCommodity(uniq(test));
        setFormDataCommidity(uniq(test));

        if (formDataCommidity.length == 0) {
            fetchDataCom('A');
        }

        data.regionDocRelations.map(i => {
            const newopt1 = testreg.push({ id: i.regionId, name: i.regionName_ReadOnly });
        });

        setAutocompleteRegion(uniq(testreg));
        setFormDataRegion(uniq(testreg));

        if (formDataRegion.length == 0) {
            fetchDataReg('A');
        }

        data.processingTechDocs.map(i => {
            const newopt2 = testproc.push({ id: i.processingTechId, name: i.processingTechName_ReadOnly });
        });

        setAutocompleteProcess(uniq(testproc));
        setFormDataProcessing(uniq(testproc));

        if (formDataProcessing.length == 0) {
            fetchDataProc('A');
        }

        data.geologyDocRelations.map(i => {
            const newopt3 = testgeo.push({ id: i.geologyId, name: i.geologyName_ReadOnly });
        });

        setAutocompleteGeo(uniq(testgeo));
        setFormDataGeology(uniq(testgeo));

        if (formDataGeology.length == 0) {
            fetchDataGeo('A');
        }

        data.topicDocRelations.map(i => {
            const newopt4 = testtop.push({ id: i.topicId, name: i.topicName_ReadOnly });
        });

        setAutocompleteTopic(uniq(testtop));
        setFormDataTopic(uniq(testtop));

        if (formDataTopic.length == 0) {
            fetchDataTop('A');
        }

        data.analysts.map(i => {
            const newopt5 = testana1.push({ id: i.analystUserId, name: i.name });
        });

        setAutocompleteAnalystsSelected(uniq(testana1));
        setFormDataAnalyst(uniq(testana1));

    }

    useEffect(() => {
        fetchDataDoc();
    }, [])
    
    const fetchDataCom = async (keyword) => {
        var data = await fetchGet('/api/cms/commodity/' + keyword);
        data.map(i => {
            var indexA = test.findIndex(x => x.name == i.name); 
            if (indexA === -1) {
                const newopt = test.push({ id: i.id, name: i.name });
            } 
        });
        setAutocompleteCommodity(uniq(test));
    }

    const fetchDataReg = async (keyword) => {
        var data = await fetchGet('/api/cms/region/' + keyword);
        data.map(i => {
            var indexB = test.findIndex(x => x.name == i.name);
            if (indexB === -1) {
                const newoptreg = testreg.push({ id: i.id, name: i.name });
            } 
        });
        setAutocompleteRegion(uniq(testreg));
    }

    const fetchDataProc = async (keyword) => {
        var data = await fetchGet('/api/cms/processing-tech/' + keyword);
        data.map(i => {
            var indexC = test.findIndex(x => x.name == i.name);
            if (indexC === -1) {
                const newoptproc = testproc.push({ id: i.id, name: i.name });
            } 
        });
        setAutocompleteProcess(uniq(testproc));
    }

    
    const fetchDataGeo = async (keyword) => {
        var data = await fetchGet('/api/cms/geology/' + keyword);
        data.map(i => {
            var indexD = test.findIndex(x => x.name == i.name);
            if (indexD === -1) {
                const newoptgeo = testgeo.push({ id: i.id, name: i.name });
            } 
        });
        setAutocompleteGeo(uniq(testgeo));
    }

    const fetchDataTop = async (keyword) => {
        var data = await fetchGet('/api/cms/topic/' + keyword);
        data.map(i => {
            var indexE = test.findIndex(x => x.name == i.name);
            if (indexE === -1) {
                const newopttop = testtop.push({ id: i.id, name: i.name });
            } 
        });
        setAutocompleteTopic(uniq(testtop));
    }

    const handlecommodity = (event) => {
        if (event.target.value.length > 0) {
            const commodityres = fetchDataCom(event.target.value);
        }
    }

    const handleregion = (event) => {
        if (event.target.value.length > 0) {
            const regres = fetchDataReg(event.target.value);
        }
    }

    const handleprocessing = (event) => {
        if (event.target.value.length > 0) {
            const procres = fetchDataProc(event.target.value);
        }
    }

    const handlegeology = (event) => {
        if (event.target.value.length > 0) {
            const geores = fetchDataGeo(event.target.value);
        }
    }

    const handletopic = (event) => {
        if (event.target.value.length > 0) {
            const topres = fetchDataTop(event.target.value);
        }
    }

    const handleFormCommodity = (value, e) => {
        setFormDataCommidity(value);
    }

    const handleFormAnalyst = (value, e) => {
        setFormDataAnalyst(value);
    }

    const handleFormRegion = (value, e) => {
        setFormDataRegion(value);
    }

    const handleFormProcessing = (value, e) => {
        setFormDataProcessing(value);
    }

    const handleFormGeology = (value, e) => {
        setFormDataGeology(value);
    }

    const handleFormTopic = (value, e) => {
        setFormDataTopic(value);
    }

    const handledate = (x, event) => {
        let ldate = JSON.stringify(x);
        setLastupdateDate(ldate.replace(/['"]+/g, ''));
        
    }

    function handleuploadthumbnailstep1(filetype) {
        return fetch('/api/cms/document/upload/thumbnail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(filetype)
        })
            .then(response => {
                if (response.status == 401) {
                    setSuccessMessageThumb("");
                    setErrorMessageThumb("Something went wrong. Please try again. " + response.message);
                }
                return response.json()
            })
            .then(data => {
                setFormS3Link(data.s3_link);
                if (data.link != "") {
                    return data;
                }
            })
            .catch((err) => console.error(err));
    }

    function handleuploadthumbnailstep2(link, thumbnailfile) {
        return fetch(link, {
            method: 'PUT',
            async: true,
            body: thumbnailfile,
        })
            .then(res => {
                if (res.status == 200) {
                    setSuccessMessageThumb("Thumbnail uploaded successfully");
                    setErrorMessageThumb("");
                    return res.status;
                }
                else {
                    setSuccessMessageThumb("");
                    setErrorMessageThumb("Something went wrong. Please try again. " + res.message);
                }
            })
            .catch((err) => console.error(err));
    }

    function handlefinaluploaddocument(newdocumentdata) {
        return fetch('/api/cms/document/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(newdocumentdata)
        })
            .then(data => {
                if (data.status == 200) {
                    setErrorMessage("");
                    setSuccessMessage("");
                    popupLinkId.handleClickAlertEditUpload();
                    popupLinkId.CloseModal2();
                }
                else {
                    setSuccessMessage("");
                    setErrorMessage("Something went wrong. Please try again.");
                }
            });
    }

    let filenamethumb = "";
    const handleFileChangeThumb = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let newfile = e.target.files[0];
            setThuFile(newfile.name);
            let filetype_temp = newfile.type.split('/');
            let filetype = filetype_temp[1];
            
            if (filetype == 'jpg' || filetype == 'jpeg' || filetype == 'png') {
                let resthumb1 = await handleuploadthumbnailstep1(filetype);
                let resthumb2 = await handleuploadthumbnailstep2(resthumb1.link, newfile);
                filenamethumb = resthumb1.s3_link;
                setErrorMessageThumb("");
                setSuccessMessageThumb("Document Uploaded Successfully.");
            }
            else {
                setSuccessMessageThumb("");
                setErrorMessageThumb("Only jpg, jpeg and png files allowed.");
            }
        }
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        const data = new FormData(event.currentTarget);
        let name = data.get('name');
        let thumbnailfile = data.get('thumbnailfile');
        let commodity = formDataCommidity;
        let geology = formDataGeology;
        let processing = formDataProcessing;
        let region = formDataRegion;
        let topic = formDataTopic;
        let year = data.get('year');
        let featureId = data.get('featureId');
        let updateddate = lastUpdated;
        let realupdateddate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        let analyst = formDataAnalyst;

        if (name == "") {
            setErrorMessage("Please enter document name");
        }
        else if (year == "") {
            setErrorMessage("Please select year");
        }
        else if (featureId == "") {
            setErrorMessage("Please select feature list");
        }
        else if (updateddate == "") {
            setErrorMessage("Please select updated date");
        }
        else if (analyst == "") {
            setErrorMessage("Please select analyst");
        }
        else {

            const newdocumentdata = {
              "document": {
                "id": popupLinkId.data,
                "name": name,
                "link": popupLinkId.dataproductlinkid,
                "series": parseInt(popupLinkId.dataseriesid),
                "year": year,
                "featureId": parseInt(featureId),
                "type": "pdf",
                "description": "N/A",
                "lastUpdated": updateddate,
                "realLastUpdated": realupdateddate,
                "active": true,
                "thumbnailKey": formS3Link
              },
                "commodities": (commodity.length > 0) ? uniq(commodity.map(item => item.id)) : [],
                "geologies": (geology.length > 0) ? uniq(geology.map(item => item.id)) : [],
                "processingTechs": (processing.length > 0) ? uniq(processing.map(item => item.id)) : [],
                "regions": (region.length > 0) ? uniq(region.map(item => item.id)) : [],
                "topics": (topic.length > 0) ? uniq(topic.map(item => item.id)) : [],
                "analystUserIds": (analyst.length > 0) ? uniq(analyst.map(item => item.id)) : []
            };

            const resfinalupload = await handlefinaluploaddocument(newdocumentdata);

    }

    };

    return (            

        <Container className="positionRelative">
            <Box component="form" onSubmit={handleSubmit} noValidate >
                <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr" >
               <InputLabel required={true}>
               Document Name
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>             
        
                        {responseData &&
                            <TextField
                                margin="none"
                                fullWidth
                                id="DocumentName"
                                name="name"
                                autoFocus
                                defaultValue={responseData ? responseData.document.name : ''}
                                className="form-control cmsinputtg cmsinputborder"
                                variant='filled'

                            />}
              </Grid>
              </Grid>

            <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                        <InputLabel required={true}>
                        Thumbnail
                    </InputLabel>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                        <Grid className='tumbname'><Button variant="contained" component="label" className="btn-gradient smlbtn">
                        Upload New Thumbnail
                        <input hidden accept="image/*" name="thumbnailfile" multiple type="file" onChange={handleFileChangeThumb} />
                        </Button>&nbsp;&nbsp;&nbsp;
                            {responseData && responseData.document.thumbnailKey && <Link to={"https://ci-thumbnail.s3.us-west-2.amazonaws.com/" + responseData.document.thumbnailKey} target="_blank" className="btn-secondary   viewbtn" >View</Link>}&nbsp;{thufile && <Text> {thufile} </Text>}</Grid>
                        {errorMessageThumb && <Text type='small' className="errormsg" id='errormsgthumb'> {errorMessageThumb} </Text>}
                        {successMessageThumb && <Text type='small' className="successmsg" id='successmsgthumb'> {successMessageThumb} </Text>}
                        

                </Grid>
            </Grid>


              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                    <InputLabel>
                        Commodity
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    {responseData && 
                    <Autocomplete
                    multiple
                    id="tags-standard"
                    options={uniq(autocompleteCommodity)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={uniq(formDataCommidity)}
                    onChange={(a, b) => handleFormCommodity(b)}
                    onKeyUp={handlecommodity}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterSelectedOptions
                    noOptionsText={'Please type to get the option'}
                    name="commodities"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"           
                        placeholder="Type here"
                        className="form-control cmsinputtg cmsinputborder"
                      />
                    )}
                  />}
              
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Region
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    {responseData &&
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={uniq(autocompleteRegion)}
                            getOptionLabel={(option) => option.name}
                            defaultValue={uniq(formDataRegion)}
                            onChange={(a, b) => handleFormRegion(b)}
                            onKeyUp={handleregion}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            filterSelectedOptions
                            noOptionsText={'Please type to get the option'}
                            name="regions"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Type here"
                                    className="form-control cmsinputtg cmsinputborder"
                                />
                            )}
                        />}
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Processing Tech
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    {responseData &&
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={uniq(autocompleteProcess)}
                            getOptionLabel={(option) => option.name}
                            defaultValue={uniq(formDataProcessing)}
                            onChange={(a, b) => handleFormProcessing(b)}
                            onKeyUp={handleprocessing}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            filterSelectedOptions
                            noOptionsText={'Please type to get the option'}
                            name="processingTechs"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Type here"
                                    className="form-control cmsinputtg cmsinputborder"
                                />
                            )}
                        />}
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Geology
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    {responseData &&
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={uniq(autocompleteGeo)}
                            getOptionLabel={(option) => option.name}
                            defaultValue={uniq(formDataGeology)}
                            onChange={(a, b) => handleFormGeology(b)}
                            onKeyUp={handlegeology}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            filterSelectedOptions
                            noOptionsText={'Please type to get the option'}
                            name="geologies"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Type here"
                                    className="form-control cmsinputtg cmsinputborder"
                                />
                            )}
                        />}
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Topic
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    {responseData &&
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={uniq(autocompleteTopic)}
                            getOptionLabel={(option) => option.name}
                            defaultValue={uniq(formDataTopic)}
                            onChange={(a, b) => handleFormTopic(b)}
                            onKeyUp={handletopic}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            filterSelectedOptions
                            noOptionsText={'Please type to get the option'}
                            name="topics"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Type here"
                                    className="form-control cmsinputtg cmsinputborder"
                                />
                            )}
                        />}
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                        <InputLabel required={true}>
               Year 
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                        <Select
                            labelId="demo-simple-select-label"
                            name="year"
                            id="demo-simple-select"
                            className='cmsdropdown'
                            value={resonseYear}
                            onChange={e => setResponseYear(e.target.value)}
        >
                            {responseDefaultData && (popupLinkId.dataproductid == 5 || popupLinkId.dataproductid == 6) && responseDefaultData.years.map(i => {
                            return (
                                <MenuItem value={i}>{i}</MenuItem>
                            )
                            })}

                            {responseDefaultData && popupLinkId.dataproductid != 5 && popupLinkId.dataproductid != 6 && responseDefaultData.years.slice(0,2).map(i => {
                                return (
                                    <MenuItem value={i}>{i}</MenuItem>
                                )
                            })}

                        </Select>
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                        <InputLabel required={true}>
               Feature List 
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
              <Select        
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            className='cmsdropdown'
                            name="featureId"
                            value={resonseFeature}
                    >
                        
                        {responseDefaultData && responseDefaultData.features.map(i => {
                            if (popupLinkId.dataproductid == i.productId) {
                        return (
                            <MenuItem value={i.featureId}>{i.featureName}</MenuItem>
                                )
                            }
                    })}

               </Select>

              </Grid>
              </Grid>


              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                        <InputLabel required={true}>
               Last Updated Date 
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>           
                        {responseData && <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Date" format="MM/DD/YYYY" className='cmsinputtg datepicker' defaultValue={dayjs(responseData.document.lastUpdated)} onChange={(x, event) => handledate(x, event)}
                                />
                            </DemoContainer>
                        </LocalizationProvider>}
      

              </Grid>
              </Grid>

                <Grid container spacing={3} className='cmsinput lastauto'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                        <InputLabel required={true}>
               Analyst Involved 
               </InputLabel>
              </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        {responseDefaultData && responseData &&
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={uniq(autocompleteAnalysts)}
                                getOptionLabel={(option) => option.name}
                                onChange={(a, b) => handleFormAnalyst(b)}
                                defaultValue={uniq(resonseAnalystSelected)}
                                isOptionEqualToValue={(option, value) => option.id == value.id}
                                filterSelectedOptions
                                name="analysts"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Type here"
                                        className="form-control cmsinputtg cmsinputborder"
                                    />
                                )}
                            />}

                    </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
              <Grid item xs={12} sm={4} md={3} >             
              </Grid>
              
                </Grid>

                <Grid item className='savecont'>
                    <Grid item xs={12} className='sendbtndiv'>
                        {errorMessage && <Text type='small' className="errormsg" id='errormsg'> {errorMessage} </Text>}
                        {successMessage && <Text type='small' className="successmsg" id='successmsg'> {successMessage} </Text>}
                        <Button
                            type="submit"
                            disableElevation
                            variant="contained"
                            className="btn-gradient"
                            >SAVE</Button>
                    </Grid>
                </Grid>
                
            </Box>
              </Container>
           
    );

}

export default DocumentData;