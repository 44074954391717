import React, { useEffect } from 'react';
import {  useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../admin/include/Header.js';
import Footer from '../admin/include/Footer.js';
import Dashboard from '../admin/component/Dashboard.js'


const accessToken = localStorage.getItem('accessToken');
const userrole = localStorage.getItem('userrole');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});


export default function GetProductData() {

    const navigate = useNavigate();
    function isLogin() {
        if (accessToken && userrole == "Analyst") {
            navigate("/admin/dashboard");
        }
        else {
            window.location.href = "/admin/login";
        }
    }

    useEffect(() => {
        isLogin();
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="false" className="pr-0 pl-0 positionRelative">
                <Header />

       
                <Container component="main" maxWidth="lg" className='main-container' >
                       
                        <Dashboard />
                       
                    </Container>                 
                 

              
                
                <Footer />
            </Container>
        </ThemeProvider>
    );
}