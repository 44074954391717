import React, { useState, useEffect, ChangeEvent } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Text from '../../assets/components/ui/atoms/Text.jsx';
import { format } from 'date-fns';

const accessToken = localStorage.getItem('accessToken');

function uniq(a) {
    return Array.from(new Set(a));
}

function DocumentDataUpload(popupLinkId) {

    let newseriesid = popupLinkId.datanewseriesid;
    const [responseDefaultData, setResponseDefaultData] = useState();
    const [autocompleteCommodity, setAutocompleteCommodity] = useState([]);
    const [autocompleteRegion, setAutocompleteRegion] = useState([]);
    const [autocompleteProcess, setAutocompleteProcess] = useState([]);
    const [autocompleteGeo, setAutocompleteGeo] = useState([]);
    const [autocompleteTopic, setAutocompleteTopic] = useState([]);
    const [autocompleteAnalysts, setAutocompleteAnalysts] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessageDoc, setErrorMessageDoc] = useState("");
    const [successMessageDoc, setSuccessMessageDoc] = useState("");
    const [errorMessageThumb, setErrorMessageThumb] = useState("");
    const [successMessageThumb, setSuccessMessageThumb] = useState("");
    const [lastUpdated, setLastupdateDate] = useState("");
    const [formDataCommidity, setFormDataCommidity] = useState("");
    const [formDataRegion, setFormDataRegion] = useState("");
    const [formDataProcessing, setFormDataProcessing] = useState("");
    const [formDataGeology, setFormDataGeology] = useState("");
    const [formDataTopic, setFormDataTopic] = useState("");
    const [formDataAnalyst, setFormDataAnalyst] = useState("");
    const [formDocS3Link, setFormDocS3Link] = useState("");
    const [formS3Link, setFormS3Link] = useState("");

    const [docfile, setDocFile] = useState('');
    const [thufile, setThuFile] = useState('');
    
    let testana = [];
    const fetchDataStart = () => {
        return fetch('/api/cms/document/update/start', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        })
            .then((response) => response.json())
            .then(data => {
                setResponseDefaultData(data);
                data.analysts.map(i => {
                    const newoptana = testana.push({ id: i.analystUserId, name: i.name });
                });
                setAutocompleteAnalysts(uniq(testana));
            });
    }

    useEffect(() => {
        fetchDataStart();
    }, []);

    let testcom = [];
    const fetchDataCom = (keyword) => {
        return fetch('/api/cms/commodity/' + keyword, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then((response) => response.json())
            .then(data => {
                data.map(i => {
                    var indexA = testcom.findIndex(x => x.name == i.name);
                    if (indexA === -1) {
                        const newopt = testcom.push({ id: i.id, name: i.name });
                    }
                });
                setAutocompleteCommodity(uniq(testcom));
            });
    }

    useEffect(() => {
        fetchDataCom('A');
    }, [])

    let testreg= [];
    const fetchDataReg = (keyword) => {
        return fetch('/api/cms/region/' + keyword, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then((response) => response.json())
            .then(data => {
                data.map(i => {
                    var indexB = testreg.findIndex(x => x.name == i.name);
                    if (indexB === -1) {
                        const newoptreg = testreg.push({ id: i.id, name: i.name });
                    } 
                });
                setAutocompleteRegion(uniq(testreg));
            });
    }

    useEffect(() => {
        fetchDataReg('A');
    }, [])

    let testproc = [];
    const fetchDataProc = (keyword) => {
        return fetch('/api/cms/processing-tech/' + keyword, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then((response) => response.json())
            .then(data => {
                data.map(i => {
                    var indexC = testproc.findIndex(x => x.name == i.name);
                    if (indexC === -1) {
                        const newoptproc = testproc.push({ id: i.id, name: i.name });
                    }
                });
                setAutocompleteProcess(uniq(testproc));
            });
    }

    useEffect(() => {
        fetchDataProc('M');
    }, [])

    let testgeo = [];
    const fetchDataGeo = (keyword) => {
        return fetch('/api/cms/geology/' + keyword, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then((response) => response.json())
            .then(data => {
                data.map(i => {
                    var indexD = testgeo.findIndex(x => x.name == i.name);
                    if (indexD === -1) {
                        const newoptgeo = testgeo.push({ id: i.id, name: i.name });
                    } 
                });
                setAutocompleteGeo(uniq(testgeo));
            });
    }

    useEffect(() => {
        fetchDataGeo('B');
    }, [])

    let testtop = [];
    const fetchDataTop = (keyword) => {
        return fetch('/api/cms/topic/' + keyword, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then((response) => response.json())
            .then(data => {
                data.map(i => {
                    var indexE = testtop.findIndex(x => x.name == i.name);
                    if (indexE === -1) {
                        const newopttop = testtop.push({ id: i.id, name: i.name });
                    } 
                });
                setAutocompleteTopic(uniq(testtop));
            });
    }

    useEffect(() => {
        fetchDataTop('L');
    }, [])

    const handlecommodity = (event) => {
        if (event.target.value.length > 0) {
            const commodityres = fetchDataCom(event.target.value);
        }
    }

    const handleregion = (event) => {
        if (event.target.value.length > 0) {
            const regres = fetchDataReg(event.target.value);
        }
    }

    const handleprocessing = (event) => {
        if (event.target.value.length > 0) {
            const procres = fetchDataProc(event.target.value);
        }
    }

    const handlegeology = (event) => {
        if (event.target.value.length > 0) {
            const geores = fetchDataGeo(event.target.value);
        }
    }

    const handletopic = (event) => {
        if (event.target.value.length > 0) {
            const topres = fetchDataTop(event.target.value);
        }
    }

    const handleFormCommodity = (value, e) => {
        setFormDataCommidity(value);
    }

    const handleFormAnalyst = (value, e) => {
        setFormDataAnalyst(value);
    }

    const handleFormRegion = (value, e) => {
        setFormDataRegion(value);
    }

    const handleFormProcessing = (value, e) => {
        setFormDataProcessing(value);
    }

    const handleFormGeology = (value, e) => {
        setFormDataGeology(value);
    }

    const handleFormTopic = (value, e) => {
        setFormDataTopic(value);
    }

    const handledate = (x, event) => {
        let ldate = JSON.stringify(x);
        setLastupdateDate(ldate.replace(/['"]+/g, ''));
    }

    function handleuploaddocumentstep1(filetype) {
        return fetch('/api/cms/document/upload/step-1', {
            method: 'POST',
            headers: {
                 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(filetype)
        })
            .then(response => {
                if (response.status == 401) {
                    //throw new Error("Server responds with error! Accesstoken.");
                    setSuccessMessageDoc("");
                    setErrorMessageDoc("Something went wrong. Please try again. " + response.message);
                }
                return response.json()
            })
            .then(data => {
                setFormDocS3Link(data.s3_link);
                if (data.link != "") {
                    return data;
                }
            })
            .catch((err) => console.error(err));
    }

    function handleuploaddocumentstep2(link, documentfile) {
        return fetch(link, {
            method: 'PUT',
            body: documentfile,
        })
            .then(res => {
                if (res.status == 200) {
                    setSuccessMessageDoc("Document uploaded successfully");
                    setErrorMessageDoc("");
                    return res.status;
                } else {
                    setSuccessMessageDoc("");
                    setErrorMessageDoc("Something went wrong. Please try again. " + res.message);
                }

            })
            .catch((err) => console.error(err));
    }
   
    function handleuploadthumbnailstep1(filetype) {
        return fetch('/api/cms/document/upload/thumbnail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(filetype)
        })
            .then(response => {
                if (response.status == 401) {
                    //throw new Error("Server responds with error! Accesstoken.");
                    setSuccessMessageThumb("");
                    setErrorMessageThumb("Something went wrong. Please try again. " + response.message);
                }
                return response.json()
            })
            .then(data => {
                setFormS3Link(data.s3_link);
                if (data.link != "") {
                    return data;
                }
            })
            .catch((err) => console.error(err));
    }

    function handleuploadthumbnailstep2(link, thumbnailfile) {
        return fetch(link, {
            method: 'PUT',
            async: true,
            body: thumbnailfile,
        })
            .then(res => {
                if (res.status == 200) {
                    setSuccessMessageThumb("Thumbnail uploaded successfully");
                    setErrorMessageThumb("");
                    return res.status;
                }
                else {
                    setSuccessMessageThumb("");
                    setErrorMessageThumb("Something went wrong. Please try again. " + res.message);
                }
            })
            .catch((err) => console.error(err));
    }

    let filenamedoc = "";
    const handleFileChangeDoc = async (e) => {
        if (e.target.files) {
            setErrorMessageDoc("");
            setSuccessMessageDoc("");
            let newfile = e.target.files[0];
            setDocFile(newfile.name);
            let filetype_temp = newfile.type.split('/');
            let filetype = filetype_temp[1];
            
            if (filetype != 'mp4' || filetype != 'flv') {

                let resdoc1 = await handleuploaddocumentstep1(filetype);
                let resdoc2 = await handleuploaddocumentstep2(resdoc1.link, newfile);

                filenamedoc = resdoc1.s3_link;

                setErrorMessageDoc("");
                setSuccessMessageDoc("Document Uploaded Successfully.");

            }
            else {
                setSuccessMessageDoc("");
                setErrorMessageDoc("File extension not allowed.");
            }
        }
    };

    function handlefinaluploaddocument(newdocumentdata) {
        return fetch('/api/cms/document/upload/step-2', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(newdocumentdata)
        })
            .then(data => {
                if (data.status == 200) {
                    setErrorMessage("");
                    setSuccessMessage("");
                    popupLinkId.handleClickAlertNewUpload();
                    popupLinkId.CloseModalUpload();
                }
                else {
                    setSuccessMessage("");
                    setErrorMessage("Something went wrong. Please try again.");
                }
            });
    }

    let filenamethumb = "";
    const handleFileChangeThumb = async (e) => {
        if (e.target.files) {
            setErrorMessageThumb("");
            setSuccessMessageThumb("");
            let newfile = e.target.files[0];
            setThuFile(newfile.name);
            let filetype_temp = newfile.type.split('/');
            let filetype = filetype_temp[1];
            
            if (filetype == 'jpg' || filetype == 'jpeg' || filetype == 'png') {

                let resthumb1 = await handleuploadthumbnailstep1(filetype);
                let resthumb2 = await handleuploadthumbnailstep2(resthumb1.link, newfile);

                filenamethumb = resthumb1.s3_link;

                setErrorMessageThumb("");
                setSuccessMessageThumb("Thumbnail Uploaded Successfully.");

            }
            else {
                setSuccessMessageThumb("");
                setErrorMessageThumb("Only jpg, jpeg and png files allowed.");
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        const data = new FormData(event.currentTarget);        

        let name = data.get('name');
        let thumbnailfile = data.get('thumbnailfile');
        let documentfile = data.get('documentfile');
        let commodity = formDataCommidity;
        let geology = formDataGeology;
        let processing = formDataProcessing;
        let region = formDataRegion;
        let topic = formDataTopic;
        let year = data.get('year');
        let featureId = data.get('featureId');
        let updateddate = lastUpdated;
        let realupdateddate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        let analyst = formDataAnalyst;
        
        if (name == "") {
            setErrorMessage("Please enter document name");
        }
        else if (documentfile.name == "") {
            setErrorMessage("Please select document");
        }
        else if (thumbnailfile.name == "") {
            setErrorMessage("Please select thumbnail");
        }
        else if (year == "") {
            setErrorMessage("Please select year");
        }
        else if (featureId == "") {
            setErrorMessage("Please select feature list");
        }
        else if (updateddate == "") {
            setErrorMessage("Please select updated date");
        }
        else if (formDataAnalyst == "") {
            setErrorMessage("Please select analyst");
        }
        else {

             const newdocumentdata = {
                "document": {
                    "name": name,
                    "link": formDocS3Link,
                    "series": parseInt(newseriesid),
                    "year": year,
                    "featureId": parseInt(featureId),
                    "type": "pdf",
                    "description": "N/A",
                    "lastUpdated": updateddate,
                    "realLastUpdated": realupdateddate,
                    "active": true,
                    "thumbnailKey": formS3Link
                },
                "commodities": (commodity.length > 0) ? uniq(commodity.map(item => item.id)):[],
                "geologies": (geology.length > 0) ? uniq(geology.map(item => item.id)) : [],
                "processingTechs": (processing.length > 0) ? uniq(processing.map(item => item.id)) : [],
                "regions": (region.length > 0) ? uniq(region.map(item => item.id)) : [],
                "topics": (topic.length > 0) ? uniq(topic.map(item => item.id)) : [],
                "analystUserIds": (analyst.length > 0) ? uniq(analyst.map(item => item.id)) : []
             };

            const resfinalupload = await handlefinaluploaddocument(newdocumentdata);
        }
    };

    return (            

        <Container className="positionRelative">
            <Box component="form" onSubmit={handleSubmit} noValidate  >

                

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                <InputLabel required={true}>
               Document Name 
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>             
              
              <TextField
                margin="none"                
                fullWidth
                id="DocumentName"                        
                autoFocus
                name='name'
                className="form-control cmsinputtg cmsinputborder"
                variant='filled'  
                        
                />
              </Grid>
              </Grid>

            <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                       <InputLabel required={true}>
                        File 
                    </InputLabel>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                        <Grid className='tumbname'>
                            <Button variant="contained" component="label" className="btn-gradient smlbtn" >
                            Upload
                            <input hidden accept="pdf/*" type="file" name="documentfile" onChange={handleFileChangeDoc} />
                        </Button>{docfile && <Text>{docfile}</Text>}</Grid>
                        {errorMessageDoc && <Text type='small' className="errormsg" id='errormsgdoc'> {errorMessageDoc} </Text>}
                        {successMessageDoc && <Text type='small' className="successmsg" id='successmsgdoc'> {successMessageDoc} </Text>}

                </Grid>
            </Grid>

            <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                      <InputLabel required={true}>
                        Thumbnail 
                    </InputLabel>
                </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        <Grid className='tumbname'> <Button variant="contained" component="label" className="btn-gradient smlbtn">
                            Upload
                            <input hidden accept="image/*" name="thumbnailfile" type="file" onChange={handleFileChangeThumb} />
                        </Button>{thufile && <Text> {thufile} </Text>}</Grid>
                        {errorMessageThumb && <Text type='small' className="errormsg" id='errormsgthumb'> {errorMessageThumb} </Text>}
                        {successMessageThumb && <Text type='small' className="successmsg" id='successmsgthumb'> {successMessageThumb} </Text>}

                </Grid>
            </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                    <InputLabel>
                        Commodity 
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                    {autocompleteCommodity && 
                    <Autocomplete
                    multiple
                    id="tags-standard"
                    options={uniq(autocompleteCommodity)}
                    getOptionLabel={(option) => option.name}
                    onChange={(a, b) => handleFormCommodity(b)}
                    onKeyUp={handlecommodity}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    filterSelectedOptions
                    noOptionsText={'Please type to get the option'}
                    name="commodities"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"           
                        placeholder="Type here"
                        className="form-control cmsinputtg cmsinputborder"
                      />
                    )}
                  />}
              
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Region
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                        {autocompleteRegion &&
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={uniq(autocompleteRegion)}
                            getOptionLabel={(option) => option.name}
                            onChange={(a, b) => handleFormRegion(b)}
                            onKeyUp={handleregion}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            filterSelectedOptions
                            noOptionsText={'Please type to get the option'}
                            name="regions"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Type here"
                                    className="form-control cmsinputtg cmsinputborder"
                                />
                            )}
                        />}
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Processing Tech
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                        {autocompleteProcess &&
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={uniq(autocompleteProcess)}
                            getOptionLabel={(option) => option.name}
                            onChange={(a, b) => handleFormProcessing(b)}
                            onKeyUp={handleprocessing}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            filterSelectedOptions
                            noOptionsText={'Please type to get the option'}
                            name="processingTechs"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Type here"
                                    className="form-control cmsinputtg cmsinputborder"
                                />
                            )}
                        />}
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Geology
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                        {autocompleteGeo &&
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={uniq(autocompleteGeo)}
                            getOptionLabel={(option) => option.name}
                            onChange={(a, b) => handleFormGeology(b)}
                            onKeyUp={handlegeology}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            filterSelectedOptions
                            noOptionsText={'Please type to get the option'}
                            name="geologies"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Type here"
                                    className="form-control cmsinputtg cmsinputborder"
                                />
                            )}
                        />}
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel>
               Topic
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                        {autocompleteTopic &&
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={uniq(autocompleteTopic)}
                            getOptionLabel={(option) => option.name}
                            onChange={(a, b) => handleFormTopic(b)}
                            onKeyUp={handletopic}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            filterSelectedOptions
                            noOptionsText={'Please type to get the option'}
                            name="topics"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Type here"
                                    className="form-control cmsinputtg cmsinputborder"
                                />
                            )}
                        />}
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                 <InputLabel required={true}>
               Year
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
              <Select        
          labelId="demo-simple-select-label"
          name="year"
          id="demo-simple-select"
          className='cmsdropdown'      
        >
                            {responseDefaultData && (popupLinkId.dataproductid == 5 || popupLinkId.dataproductid == 6) && responseDefaultData.years.map(i => {
                            return (
                                <MenuItem value={i}>{i}</MenuItem>
                            )
                        })}

                            {responseDefaultData && popupLinkId.dataproductid != 5 && popupLinkId.dataproductid != 6 && responseDefaultData.years.slice(0,2).map(i => {
                                return (
                                    <MenuItem value={i}>{i}</MenuItem>
                                )
                            })}

                        </Select>
              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
                  <InputLabel required={true}>
               Feature List 
               </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
              <Select        
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                className='cmsdropdown'
                name="featureId"
                    >
                        
                        {responseDefaultData && responseDefaultData.features.map(i => {
                            if (popupLinkId.dataproductid == i.productId) {
                                return (
                                    <MenuItem value={i.featureId}>{i.featureName}</MenuItem>
                                )
                            }
                            
                    })}

               </Select>

              </Grid>
              </Grid>


                <Grid container spacing={3} className='cmsinput datepicker'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel required={true}>
               Last Updated Date
               </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>           
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Date" format="MM/DD/YYYY" className='cmsinputtg datepicker' onChange={(x, event) => handledate(x, event)} />
                            </DemoContainer>
              </LocalizationProvider>
      

              </Grid>
              </Grid>

              <Grid container spacing={3} className='cmsinput lastauto'>
                    <Grid item xs={12} sm={4} md={3} className="discntr">
               <InputLabel required={true}>
               Analyst Involved 
               </InputLabel>
              </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        {autocompleteAnalysts &&
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={uniq(autocompleteAnalysts)}
                                getOptionLabel={(option) => option.name}
                                onChange={(a, b) => handleFormAnalyst(b)}
                                isOptionEqualToValue={(option, value) => option.id == value.id}
                                filterSelectedOptions
                                name="analysts"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Type here"
                                        className="form-control cmsinputtg cmsinputborder"
                                    />
                                )}
                            />}

                    </Grid>

                    

              </Grid>

              <Grid container spacing={3} className='cmsinput'>
              <Grid item xs={12} sm={4} md={3} >             
                    </Grid>
                   
                </Grid>
               

                <Grid item  className='savecont'>
                    <Grid item xs={12} className='sendbtndiv'>
                        {errorMessage && <Text type='small' className="errormsg" id='errormsg'> {errorMessage} </Text>}
                        {successMessage && <Text type='small' className="successmsg" id='successmsg'> {successMessage} </Text>}
                        <Button
                            type="submit"
                            disableElevation
                            variant="contained"
                            className="btn-gradient"
                            >SAVE</Button>
                    </Grid>
                </Grid>
            </Box>           

          
              </Container>
           
    );

}

export default DocumentDataUpload;