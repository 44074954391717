import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CheckIsLogin from '../component/CheckIsLogin.js';
import ActionLogout from '../component/ActionLogout.js';
import Header from '../include/Header.js';
import Footer from '../include/Footer.js';
import ImgBottom from '../assets/images/product-bg.png';
import Grid from '@mui/material/Grid';
import Text from '../assets/components/ui/atoms/Text.jsx';
import imginsights from '../assets/images/CM-Solar.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import InsightBlock from '../assets/components/insightblock.js'
import { Link, useLocation } from "react-router-dom";
import { fetchGet, fetchGetWIAuth, fetchHtml } from "../services/apiCalls.js";
import { fetchPost } from "../services/apiCalls";
import { format } from 'date-fns';

// import SearchIcon from '@mui/material/Search';

const accessToken = localStorage.getItem('accessToken');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

//end search bar 
export default function GetProductData() {

    CheckIsLogin();

    const location = useLocation();

    let currentinsightpath = window.location.pathname;
    var insightid = currentinsightpath.split(/[-]+/).pop();

    const displayDate = localStorage.getItem('displayDate-' + insightid);
    const displayTitle = localStorage.getItem('displayTitle-' + insightid);
    const displayContent = localStorage.getItem('displayContent-' + insightid);
    const displayAuthor = localStorage.getItem('displayAuthor-' + insightid);
    //const insightPreviews = JSON.parse(localStorage.getItem('insightPreviews'));

    

    //const currentinsight = insightPreviews.filter(el => el.insightid = insightid);

    //console.log("data");
    //console.log(currentinsight);
    //console.log(currentinsight.slice(-1).pop());
    
    if (location.state !== null) {
        //ProductId = location.state.typeid;
        //currentProductName = location.state.currentProductName;
        ///currentProductDescription = location.state.currentProductDescription;
    }

    useEffect(() => {
        //console.log("here");
        //console.log(insightPreviews);
        document.getElementById("insightContentgrid").innerHTML = displayContent;
    }, [])

    
    
    


    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="false" className="pr-0 pl-0 positionRelative">
                <Header />

                <Container component="main" maxWidth="xl" className='main-container'  >

                    <Container className='whtiebg p-3' maxWidth="xl" >
                        
                            <Grid>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12} sm={4} >

                                        <Grid className=' container '>
                                        <span className='authorbg'>Author: </span><span>{displayAuthor}</span><br />
                                        Published: {format(new Date(displayDate), 'MMMM dd, yyyy')} <br />
                                            {/* Categories: Marketing, Strategy<br /> */}
                                        </Grid>
                                    </Grid>
                                {/*<Grid item xs={12} sm={8} >
                                        <Grid container className='mb-2 attchmentcon'>
                                            {insightAttachments && insightAttachments.length > 0 && insightAttachments.map(insightAttachments => {
                                                if (insightAttachments.s3BodyLink != null) {
                                                    return (
                                                        <Link onClick={() => handleClickfordownloaddocument(insightAttachments.s3BodyLink, datainsights.insightmodel.title, false)} className='attchmentbtn p-1 mr-1 mb-1'>  <Text type='small'>{insightAttachments.s3BodyLink}</Text></Link>
                                                    )
                                                }
                                            })
                                            }
                                            
                                        </Grid>
                                    </Grid>*/}
                                </Grid>
                                <Grid>

                                <Text type='h2' className="mt-3 text-gradient mb-2"><span className='text-gradient'>{displayTitle}</span></Text>

                                <Grid id="insightContentgrid"></Grid>

                                </Grid>


                            </Grid>
                       
                    </Container>
                    

                </Container>
                 <img src={ImgBottom} alt="Costmine Intelligence" className='innerbottom'  /> 
                <Footer />
            </Container>
        </ThemeProvider>
    );
}