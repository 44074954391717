import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@mui/material/Grid';
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import Text from './ui/atoms/Text.jsx';
import imginsights from '../images/CM-Haul-Bright.webp';
import { format } from 'date-fns';

const insightblock = (props) => {
    let id = props.insightId;
    let username = props.insightAuthor;
    let title = props.insightTitle;
    let displaydate = (props.insightDate != null ? format(new Date(props.insightDate), 'MM/dd/yyyy') : "12/01/2023");
    let description = props.insightText.replace(/<[^>]+>/g, '');

    let insighturltemp = title + " " + id;
    let insighturl = insighturltemp.replace(/\s+/g, '-').toLowerCase();     
    let thumbnailurl = props.thumbnailurl != "" ? "https://ci-thumbnail.s3.us-west-2.amazonaws.com/" + props.thumbnailurl : "https://ci-thumbnail.s3.us-west-2.amazonaws.com/13c3d650-3ab5-40b5-bdc3-d12b95e529c9.jpg";

    return (
        <Grid item xs={12} sm={6} md={3}>
            <Grid item className='dbinsight mninsight mb-2'>
                <Grid item className="verson" ><Text type="smaller" className="p-1">{displaydate}</Text> <Text type="smaller" className="p-1">{username}</Text></Grid>
                <Grid item className="insighthe p-2" >
                    <Link to={"/insight/" + insighturl} className='inshe'  ><Text type='insightname' className='borderright p-0 pr-3 mr-3'>{title}</Text> 
                    <FontAwesomeIcon className='bluetext' icon={faCircleArrowRight} /></Link>
                </Grid>
                <Link to={"/insight/" + insighturl}   ><img src={thumbnailurl} alt="Costmine Intelligence" /></Link>
               
            </Grid>
            <Text className='textwhite insnipt'>{description}</Text>
        </Grid>
    )
}

insightblock.defaultProps = {
    username: "Mike Sinden",
    title: "Lorem ipsum dolor sit amet, consec...",
    displaydate: "12/1/2023",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
};

export default insightblock
