import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CheckIsLogin from '../component/CheckIsLogin.js';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Text from '../assets/components/ui/atoms/Text.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Header from './include/Header.js';
import Footer from './include/Footer.js';
import { format } from 'date-fns';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { fetchGet, fetchPost, fetchHtml } from "../services/apiCalls.js";
import dayjs, { Dayjs } from 'dayjs';

const accessToken = localStorage.getItem('accessToken');

const InsightNewsletter_Page = () => {
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Semplicita Pro'
            ],
            fontWeight: [
                '400'
            ]
        },
    });

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    CheckIsLogin();

    const location = useLocation();

    const displayInsightSubject = localStorage.getItem('displayInsightSubject');
    const displayInsightBody = localStorage.getItem('displayInsightBody');

    useEffect(() => {
        document.getElementById("insightContentgrid").innerHTML = displayInsightBody;
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Header />


            <Container component="main" maxWidth="lg" className='main-container'  >

                <Container className='whtiebgmain'>
                    <Grid xs={12}>
                        <Text type='h2' className='text-gradient d-inline-block mb-3 '>DOCUMENT EMAIL NOTIFICATION</Text>

                    </Grid>

                    <Box component="form" noValidate className='whtiebgemailfrom p-4'>


                        <Grid container spacing={3}>

                            <Grid item xs={12} className='cmsinput'>
                                <Text type='h2' className="mt-1 text-gradient mb-2"><span className='text-gradient'>{displayInsightSubject}</span></Text>

                                <Grid id="insightContentgrid"></Grid>
                            </Grid>
                        </Grid>


                    </Box>


                </Container>
            </Container>
            <Footer />
        </ThemeProvider>
    )
}

export default InsightNewsletter_Page
