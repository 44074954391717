import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';

export default function ProfileAvatar() {

    let user = localStorage.getItem('user');
    let displayuser = "Sharp";

    displayuser = (user || 'Sharp').toUpperCase();
    
    const accessToken = localStorage.getItem('accessToken');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };

    async function calllogout() {
        var res = await fetch('/api/portal/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({
                "return_to": "/"
            })
        })
            .then(data => {
                return data.json();
            })
            .catch(error => setErrorMessage(error.message));

        return res;
    }

    const handleLogout = async () => {
        
        const response = await calllogout();
        if (response.status == "Y" && response.message !== null) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("user");
            localStorage.removeItem("userrole");
            localStorage.removeItem("productdashboardmenu");
            window.location.href = "/";
        }
        else {
            alert(response.message);
        }
        
    };

    return(
        <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                <IconButton onClick={handleClick} className="pr-0">
                    <Avatar alt={displayuser} className="displayuser" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                anchorEl={anchorEl}
                disableScrollLock={true}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 7,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >                        
                           
                       
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
    )
}
