import React, { useState, useEffect } from "react";
// import '../assets/css/form.css';
import '../../assets/css/form.css';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import Text from '../assets/components/ui/atoms/Text.jsx';
import Text from '../../assets/components/ui/atoms/Text';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Box from '@mui/material/Box';
import { fetchGet, fetchPost, fetchHtml } from "../../services/apiCalls.js";
import { format } from 'date-fns';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const accessToken = localStorage.getItem('accessToken');

export default function WelcomeEmail() {




    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [dataWelcomeEmail, setDataWelcome] = useState("");
    const [dataPlaceHolder, setDataPlaceHolder] = useState("");
    const [displayLoader, setDisplayLoader] = useState(true);
    const [openAlertNewUpload, setOpenAlertNewUpload] = useState(false);
    const [formMode, setFormMode] = useState("");

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertNewUpload(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        const data = new FormData(event.currentTarget);
        console.log('fdff');

        let subject = data.get('subject');
        let message = data.get('message');
        let realupdateddate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

        console.log(subject);
        console.log(message);

        console.log(formMode);

        if (subject == "") {
            setErrorMessage("Please add subject");
        }
        else if (message == "") {
            setErrorMessage("Please add content");
        }
        else {
            setErrorMessage("");
            setSuccessMessage("");

            const emailtemplate = {
                "id": 6,
                "templateType": "welcome",
                "emailSubject": subject,
                "htmlcontent": message,
                "lastUpdated": realupdateddate
            }

            if (formMode == "preview") {
                localStorage.setItem("displayInsightSubject", subject);
                localStorage.setItem("displayInsightBody", message);
                window.open("/admin/email-preview", '_blank');


            } else {
                const resfinalupload = await handlefinaluploadtemplate(emailtemplate);
            }

            

        }

    }

    function handlefinaluploadtemplate(emailtemplate) {


        return fetch('/api/cms/emailtemplate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(emailtemplate)
        })
            .then(data => {
                if (data.status == 200) {
                    setErrorMessage("");
                    setSuccessMessage("Emial template updated sccessfully...");
                    setOpenAlertNewUpload(true);
                    setTimeout(function () { window.location.href = "/admin/dashboard" }, 3000);
                }
                else {
                    setSuccessMessage("");
                    setErrorMessage("Something went wrong. Please try again.");
                }
            });
    }

    const handleClickfordownloaddocument = async (s3_link, filename, use_in_viewer) => {
        const response = await downloaddocumentrequest({
            s3_link,
            filename,
            use_in_viewer
        });


    };

    async function downloaddocumentrequest(downlddocumentrequest) {
        var data = await fetchPost('/api/cms/emailtemplate/download', downlddocumentrequest);
        setErrorMessage("");
        if (data.status == "E" && data.message !== null) {
            setErrorMessage("");
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessage(data.message);
        }
        else {
            setErrorMessage("");
            //get content from url
            var tempdata = await fetchHtml(data);

        }

    }

    const fetchData = async () => {

        var datawelcome = await fetchGet('/api/cms/emailtemplate?id=6');

        if (datawelcome.status == "E" && datawelcome.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
        } else if (datawelcome.status == "N" && datawelcome.message !== null) {
            setErrorMessage(datawelcome.message);
            setDisplayLoader(false);
        }
        else {
            setDataWelcome(datawelcome);

            var temp = await handleClickfordownloaddocument(datawelcome.s3BodyLink, datawelcome.templateType, true);
            setDisplayLoader(false);
        }

        var dataplaceholder = await fetchGet('/api/cms/email-placeholder');

        if (dataplaceholder.status == "E" && dataplaceholder.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
        } else if (dataplaceholder.status == "N" && dataplaceholder.message !== null) {
            setErrorMessage(dataplaceholder.message);
            setDisplayLoader(false);
        }
        else {
            setDataPlaceHolder(dataplaceholder.filter(el => el.emailType == "ResetPassword"));
        }
    }



    useEffect(() => {
        fetchData();
    }, [])

    return (


        <Grid container className='gridbg p-0 btmrd'>

            <Snackbar open={openAlertNewUpload} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity="success" className="w-100">
                    Email Template Updated Successfully!
                </Alert>
            </Snackbar>

            {dataWelcomeEmail &&
                <Box component="form" noValidate onSubmit={handleSubmit} className='p-2  w-100' >
                    <Text>Last Updated Date : {dataWelcomeEmail.lastUpdated}</Text>
                    <TextField fullWidth label="SUBJECT" className="emailformControl mt-3 " id="fullWidth" defaultValue={dataWelcomeEmail.emailSubject} name="subject" />

                    <Text className='attchmentbtn p-1 mr-1 mt-3 nobg' type='small'>Placeholders : </Text>
                    {dataPlaceHolder && !displayLoader && dataPlaceHolder.length > 0 && dataPlaceHolder.map(item => {
                        return (< Text className='attchmentbtn p-1 mr-1 mt-3' type='small'>{item.emailPlaceHolder}</Text>)
                    })}

                    <textarea placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry." className="emailformControl formControl mt-3" name="message" rows={12} id="insightContentgrid" required />
                    <Grid item xs={12} className="prebtn">
                        <Button type="submit" disableElevation variant="contained" className="btn-gradient mt-3 mr-2" onClick={() => setFormMode("preview")} >PREVIEW</Button>
                        <Button type="submit" disableElevation variant="contained" className="btn-gradient mt-3" onClick={() => setFormMode("publish")}>Publish</Button>
                    </Grid>

                    {errorMessage && <Text type='small' className="errormsg" id='errormsg'> {errorMessage} </Text>}
                    {successMessage && <Text type='small' className="successmsg" id='successmsg'> {successMessage} </Text>}
                </Box>}

        </Grid>
    );
}