import { useEffect } from 'react';

function ActionLogout(msg) {

    async function calllogout() {
        return fetch('/api/portal/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "return_to": "/"
            })
        })
            .then(data => {
                if (data.status == 200) {
                    return data.json();
                }
                else {
                    console.log('logout api fail');
                }
            })
    }

    const logout = calllogout();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("userrole");
    localStorage.removeItem("productdashboardmenu");
    //localStorage.clear();

    if (msg === null || msg === undefined) {
        setTimeout(function () { window.location.href = "/"; }, 2000);
        
    }
    else {
        setTimeout(function () { window.location.href = "/?ex"; }, 2000);
    }

    
    

    
}


export default ActionLogout;