import * as React from 'react';

import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Mainlogo from '../assets/images/main-logo.png';
import MobileMenu from './MobileNavigation.js';
import DesktopMenu from './DesktopNavigation.js'; 
import ProfileAvatar from './ProfileAvatar.js';
import { Link } from 'react-router-dom';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ],
    },
});

function ResponsiveAppBar(props) {

    return (
        
        <AppBar position="fixed" theme={theme}>
            <Container component="header" maxWidth="false" className='bg-gradient header'>
                <Toolbar disableGutters>

                    <Box className="logocont">
                        <Link to="/dashboard" state={{ productdashboardmenu: props.productdashboard }}><img src={Mainlogo} alt='Costmine Intelligence' /></Link>
                    </Box>

                    <MobileMenu />

                    <DesktopMenu />

                    <ProfileAvatar />
                    
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;