import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const accessToken = localStorage.getItem('accessToken');
const userrole = localStorage.getItem('userrole');

function CheckIsLogin() {

    const navigate = useNavigate();

    function isLogin() {
        if (!accessToken) {
            navigate("/admin/login");
        }
    }

    function isAnalyst() {
        if (userrole != "Analyst") {
            navigate("/dashboard");
        }
    }

    useEffect(() => {
        isLogin();
        //isAnalyst();
    }, [])

    
}


export default CheckIsLogin;