import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CheckIsLogin from '../component/CheckIsLogin.js';
import ActionLogout from '../component/ActionLogout.js';
import Header from '../include/Header.js';
import Footer from '../include/Footer.js';
import ImgBottom from '../assets/images/product-bg.png';
import Grid from '@mui/material/Grid';
import Text from '../assets/components/ui/atoms/Text.jsx';
import imginsights from '../assets/images/CM-Solar.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import InsightBlock from '../assets/components/insightblock.js'
import { Link } from "react-router-dom";
import { fetchGet, fetchGetWIAuth, fetchHtml } from "../services/apiCalls.js";
import { fetchPost } from "../services/apiCalls";
import { format } from 'date-fns';

// import SearchIcon from '@mui/material/Search';

const accessToken = localStorage.getItem('accessToken');

const theme = createTheme({
    typography: {
        fontFamily: [
            'Semplicita Pro'
        ],
        fontWeight: [
            '400'
        ]
    },
});

//end search bar 
export default function GetProductData() {

    

    let currentinsight = window.location.pathname;

    CheckIsLogin();

    const [errorMessage, setErrorMessage] = useState("");
    const [datainsights, setDataInsights] = useState([]);
    const [dataInsightsReadMor, setDataInsightsReadMor] = useState([]);
    const [dataauthors, setDataAuthors] = useState([]);
    const [insightAttachments, setInsightAttachments] = useState([]);
    const [displayLoader, setDisplayLoader] = useState(true);
    const [authorname, setAuthorName] = useState("");
    const [errorMessageDoc, setErrorMessageDoc] = useState("");
    const [insightContent, setInsightContent] = useState("");
    

    var insightid = currentinsight.split(/[-]+/).pop();

    const handleClickfordownloaddocument = async (s3_link, filename, use_in_viewer) => {
        if (use_in_viewer == true) {
            const response = await downloaddocumentrequest({
                s3_link,
                filename,
                use_in_viewer
            });
        } else {
            const response = await downloaddocumentrequestattach({
                s3_link,
                filename,
                use_in_viewer
            });
        }
        


    };

    async function downloaddocumentrequest(downlddocumentrequest) {
        var data = await fetchPost('/api/portal/insight/download', downlddocumentrequest);
        setErrorMessageDoc("");
        if (data.status == "E" && data.message !== null) {
            setErrorMessageDoc("");
            ActionLogout();
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessageDoc(data.message);
        }
        else {
            setErrorMessageDoc("");
            //get content from url
            var tempdata = await fetchHtml(data);
            setInsightContent(tempdata);
        }

    }

    async function downloaddocumentrequestattach(downlddocumentrequest) {
        var data = await fetchPost('/api/portal/insight/download', downlddocumentrequest);
        setErrorMessageDoc("");
        if (data.status == "E" && data.message !== null) {
            setErrorMessageDoc("");
            ActionLogout();
        } else if (data.status == "N" && data.message !== null) {
            setErrorMessageDoc(data.message);
        }
        else {
            setErrorMessageDoc("");
            //get content from url
            window.location.href = data;
        }

    }

    const fetchData = async (insightid) => {

        var dataauthor = await fetchGet('/api/cms/insight/GetAuthors');

        if (dataauthor.status == "E" && dataauthor.message !== null) {
            setErrorMessage("");
            ActionLogout();
        } else if (dataauthor.status == "N" && dataauthor.message !== null) {
            setErrorMessage(dataauthor.message);
        }
        else {
            setDataAuthors(dataauthor);
        }

        var datainsight = await fetchGet('/api/cms/insight/GetInsight?insightId=' + insightid);

        if (datainsight.status == "E" && datainsight.message !== null) {
            setErrorMessage("");
            setDisplayLoader(true);
            ActionLogout();
        } else if (datainsight.status == "N" && datainsight.message !== null) {
            setErrorMessage(datainsight.message);
            setDisplayLoader(false);
        }
        else
        {
            const insightAuthordata = dataauthor.filter(el => el.userId == datainsight.insightmodel.authorId);
            setAuthorName(insightAuthordata[0].userName);
            setInsightAttachments(datainsight.insightattachments);
            setDataInsights(datainsight);
            var temp = await handleClickfordownloaddocument(datainsight.insightmodel.s3HtmlContentLink, currentinsight, true);
            setDisplayLoader(false);

        }

        var datainsightreadmore = await fetchGet('/api/cms/insight/SearchInsight');

        if (datainsightreadmore.status == "E" && datainsightreadmore.message !== null) {
            setErrorMessage("");
        } else if (datainsightreadmore.status == "N" && datainsightreadmore.message !== null) {
            setErrorMessage(datainsightreadmore.message);
        }
        else {
            setDataInsightsReadMor(datainsightreadmore.filter(el => el.active == true && el.id != insightid).slice(0, 3));
        }
    }

    useEffect(() => {
        fetchData(insightid);
    }, [insightid])
    


    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="false" className="pr-0 pl-0 positionRelative">
                {datainsights && !displayLoader && <Header /> }

                <Container component="main" maxWidth="xl" className='main-container'  >

                    {datainsights && !displayLoader && <Container className='whtiebg p-3' maxWidth="xl" >
                        
                            <Grid>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12} sm={4} >

                                        <Grid className=' container '>
                                            <span className='authorbg'>Author: </span><span>{authorname}</span><br />
                                            Published: {format(new Date(datainsights.insightmodel.displayDate), 'MMMM dd, yyyy')} <br />
                                            {/* Categories: Marketing, Strategy<br /> */}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={8} >
                                        <Grid container className='mb-2 attchmentcon'>
                                            {insightAttachments && insightAttachments.length > 0 && insightAttachments.map(insightAttachments => {
                                                if (insightAttachments.s3BodyLink != null) {
                                                    return (
                                                        <Link onClick={() => handleClickfordownloaddocument(insightAttachments.s3BodyLink, insightAttachments.s3BodyFileName != null ? insightAttachments.s3BodyFileName : datainsights.insightmodel.title, false)} className='attchmentbtn p-1 mr-1 mb-1'>  <Text type='small'>{insightAttachments.s3BodyFileName != null ? insightAttachments.s3BodyFileName : insightAttachments.s3BodyLink}</Text></Link>
                                                    )
                                                }
                                            })
                                            }
                                            {/*<Link to="#" className='attchmentbtn p-1 mr-1 mb-1 btn-secondary text-white'>  <Text type='small'>Show More</Text></Link>*/}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid>

                                    <Text type='h2' className="mt-3 text-gradient mb-2"><span className='text-gradient'>{datainsights.insightmodel.title}</span></Text>

                                    <Grid id="insightContentgrid"></Grid>

                                </Grid>


                            </Grid>
                       
                    </Container>}

        
                    {datainsights && !displayLoader && <Container className='greybg p-3 mt-3' maxWidth="xl">
                        <Grid container className='mb-2'>
                            <Grid item xs={6} ><Text type='h2' className=" ">Read more Insights</Text></Grid>
                            <Grid item xs={6} ></Grid>
                        </Grid>
                        <Grid container spacing={3} >

                            {dataInsightsReadMor && dataauthors && !displayLoader && !errorMessage && dataInsightsReadMor.map(insightsdata => {
                                const insightAuthordata = dataauthors.filter(el => el.userId == insightsdata.authorId);
                                return (
                                    <InsightBlock insightAuthor={insightAuthordata[0].userName} insightDate={insightsdata.displayDate} insightTitle={insightsdata.title} insightId={insightsdata.id} thumbnailurl={insightsdata.s3ThumbnailLink} />
                                )
                            })
                            }

                        </Grid>
                    </Container>}

                </Container>
                 <img src={ImgBottom} alt="Costmine Intelligence" className='innerbottom'  /> 
                <Footer />
            </Container>
        </ThemeProvider>
    );
}