import * as React from 'react';
import { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import {NavLink} from 'react-router-dom'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Mcsicon from '../assets/images/mining-cost-service.png';
import Ecsicon from '../assets/images/equipment-cost-calculator.png';
import Ghlicon from '../assets/images/gold-heap-leach.png';
import Rcgicon from '../assets/images/reclamation.png';
import Lbricon from '../assets/images/labor.png';
import Srficon from '../assets/images/sherpa-surface.png';
import Undicon from '../assets/images/sherpa-underground.png';
import Mnpricon from '../assets/images/sherpa-mineral-processing.png';
import Miicon from '../assets/images/mining-intelligence.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Text from '../assets/components/ui/atoms/Text.jsx';
import '../assets/css/navbar.css';

export default function DesktopMenu(props) {
    
    const [DropdownMenu1, setDropdownMenu1] = React.useState(null);
    const openDropdownMenu1 = Boolean(DropdownMenu1);
    const handleOpenMenu1 = (evt) => { 
        setDropdownMenu1(evt.currentTarget); 
        evt.preventDefault();
    };
    const handleCloseMenu1 = () => { setDropdownMenu1(null); };

    const [DropdownMenu2, setDropdownMenu2] = React.useState(null);
    const openDropdownMenu2 = Boolean(DropdownMenu2);
    const handleOpenMenu2 = (event2) => { setDropdownMenu2(event2.currentTarget); };
    const handleCloseMenu2 = () => { setDropdownMenu2(null); };

    const [dataproducts, setDataProducts] = useState([]);
    const [modelsproducts, setModelsProducts] = useState([]);
    const [toolsproducts, setToolsProducts] = useState([]);

    const productdashboardmenu = localStorage.getItem('productdashboardmenu');

    const fetchData = (data) => {
        if (data !== null) {
            setDataProducts(data.filter(el => el.type == 'Data'));
            setModelsProducts(data.filter(el => el.type == 'Models'));
            setToolsProducts(data.filter(el => el.type == 'Tools'));
        }
    }

    useEffect(() => {
        fetchData(JSON.parse(productdashboardmenu));
    }, [])

    return(
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, }} justifyContent="center">
            <MenuItem className="nav-item">
                <NavLink 
                    className="nav-link"
                    to='/dashboard' state={{ productdashboardmenu: props.productdashboardmenu }}>Dashboard</NavLink>
                
            </MenuItem>
            <MenuItem className="nav-item">
                <a href="#" onClick={handleOpenMenu1} className="nav-link">All Products <FontAwesomeIcon icon={faChevronDown} className='menuarrow' /></a>
            </MenuItem> 
            <MenuItem className="nav-item">
            <NavLink className="nav-link"  to='/insights' state={{ productdashboardmenu: props.productdashboardmenu }}>Insights</NavLink>                
            </MenuItem>
            <MenuItem className="nav-item">
            <NavLink className="nav-link"  to='/faqs' state={{ productdashboardmenu: props.productdashboardmenu }}>Support</NavLink>             
            </MenuItem>                    
           

            <Menu
                anchorEl={DropdownMenu1}
                id="account-menu1"
                open={openDropdownMenu1}
                onClose={handleCloseMenu1}
                onClick={handleCloseMenu1}
                className="dropdown-menu productmenu"
                disableScrollLock={true}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {                                
                            backdropFilter:'blur(3px)',
                            mt:2.8,                            
                            borderRadius: 0,
                            backgroundColor: 'rgba(32,30,30,.85)',
                            color: '#FFFFFF',
                            left: '0 !important',
                            right: '0 !important',
                            width: '100%',
                            mx: 0,
                            px: 1,
                            py: 5,
                            '& .dropdown-link': {
                                color: 'white',
                                display: 'block',
                                textDecoration: 'none',
                                paddingY: 1.6,
                            }
                        },
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}  >
                            <Text type="h2" className="mb-1">Data</Text>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>

                                    {dataproducts && dataproducts.slice(0,3).map(productsdata => {
                                        if (productsdata.id !== 8) {

                                            let pid = productsdata.id;
                                            let iconnew = Mcsicon;

                                            let datalinktemp = productsdata.name + " " + productsdata.id;
                                            let datalink = datalinktemp.replace(/\s+/g, '-').toLowerCase(); 

                                            if (pid == 1) { iconnew = Lbricon; }
                                            if (pid == 2) { iconnew = Lbricon; }
                                            
                                            if (productsdata.access == true) {
                                                return (
                                                    <Link key={`desktop-item-link-${pid}`} to={"/data/" + datalink} className="dropdown-link discntr" onClick={() => setTimeout(function () { window.location.reload() }, 300)} >
                                                        <ListItemIcon className='bg-blue-gradient drpicon'>
                                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                                        </ListItemIcon>
                                                        {productsdata.name}
                                                    </Link>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Grid key={`desktop-item-link-${pid}`} className="dropdown-link discntr">
                                                        <ListItemIcon className='drpicon disicon'>
                                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                                        </ListItemIcon>
                                                        {productsdata.name}
                                                    </Grid>
                                                )
                                            }
                                        }
                                    })
                                    }
                                </Grid>

                                <Grid item xs={6}>

                                    {dataproducts && dataproducts.slice(3,6).map(productsdata => {
                                        if (productsdata.id !== 8) {

                                            let pid = productsdata.id;
                                            let iconnew = Mcsicon;

                                            let datalinktemp = productsdata.name + " " + productsdata.id;
                                            let datalink = datalinktemp.replace(/\s+/g, '-').toLowerCase(); 

                                            if (pid == 4) { iconnew = Ghlicon; }
                                            if (pid == 5) { iconnew = Mcsicon; }
                                            if (pid == 6) { iconnew = Ecsicon; }
                                            if (pid == 7) { iconnew = Rcgicon; }

                                            if (productsdata.access == true) {
                                                return (
                                                    <Link key={`desktop-item-link-${pid}`} to={"/data/" + datalink} className="dropdown-link discntr" onClick={() => setTimeout(function () { window.location.reload() }, 300)} >
                                                        <ListItemIcon className='bg-blue-gradient drpicon'>
                                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                                        </ListItemIcon>
                                                        {productsdata.name}
                                                    </Link>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Grid key={`desktop-item-link-${pid}`} className="dropdown-link discntr">
                                                        <ListItemIcon className='drpicon disicon'>
                                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                                        </ListItemIcon>
                                                        {productsdata.name}
                                                    </Grid>
                                                )
                                            }
                                        }
                                    })
                                    }
                                </Grid>
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={5} >
                            <Text type="h2" className="mb-1">Models</Text>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>

                                    {modelsproducts && modelsproducts.slice(0, 3).map(productsdata => {
                                        
                                        let pid = productsdata.id;
                                        let includdenolink = true;
                                        let iconnew = Mcsicon;

                                        if (pid == 9) { iconnew = Srficon; }
                                        if (pid == 10) { iconnew = Undicon; includdenolink = false; }
                                        if (pid == 11) { iconnew = Rcgicon; }
                                        
                                        if (productsdata.access == true) {
                                            if (includdenolink) {
                                                return (
                                                    <Link key={`desktop-item-link-${pid}`} to={productsdata.url} target="_blank" className="dropdown-link discntr" >
                                                        <ListItemIcon className='drpicon disicon'>
                                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                                        </ListItemIcon>
                                                        {productsdata.name}
                                                    </Link>
                                                )
                                            } else {
                                                return (
                                                    <Grid key={`desktop-item-link-${pid}`} className="dropdown-link discntr" >
                                                        <ListItemIcon className='drpicon disicon'>
                                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                                        </ListItemIcon>
                                                        {productsdata.name}
                                                    </Grid>
                                                )
                                            }
                                            
                                        }
                                        else {
                                            return (
                                                <Grid key={`desktop-item-link-${pid}`} className="dropdown-link discntr" >
                                                    <ListItemIcon className='drpicon disicon'>
                                                        <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                                    </ListItemIcon>
                                                    {productsdata.name}
                                                </Grid>
                                            )
                                        }
                                    })
                                    }

                                </Grid>
                                <Grid item xs={6}>

                                    {modelsproducts && modelsproducts.slice(3, 6).map(productsdata => {

                                        let pid = productsdata.id;
                                        let includdenolink = true;
                                        let iconnew = Mcsicon;

                                        if (pid == 12) { iconnew = Mnpricon; includdenolink = false; }

                                        if (productsdata.access == true) {
                                            if (includdenolink) {
                                                return (
                                                    <Link key={`desktop-item-link-${pid}`} to={productsdata.url} target="_blank" className="dropdown-link discntr" >
                                                        <ListItemIcon className='drpicon disicon'>
                                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                                        </ListItemIcon>
                                                        {productsdata.name}
                                                    </Link>
                                                )
                                            } else {
                                                return (
                                                    <Grid key={`desktop-item-link-${pid}`} className="dropdown-link discntr" >
                                                        <ListItemIcon className='drpicon disicon'>
                                                            <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                                        </ListItemIcon>
                                                        {productsdata.name}
                                                    </Grid>
                                                )
                                            }

                                        }
                                        else {
                                            return (
                                                <Grid key={`desktop-item-link-${pid}`} className="dropdown-link discntr" >
                                                    <ListItemIcon className='drpicon disicon'>
                                                        <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                                    </ListItemIcon>
                                                    {productsdata.name}
                                                </Grid>
                                            )
                                        }
                                    })
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Text type="h2" className="mb-1">Tools</Text>

                            {toolsproducts && toolsproducts.slice(0, 3).map(productsdata => {

                                let pid = productsdata.id;
                                let iconnew = Mcsicon;

                                if (pid == 15) { iconnew = Miicon; }
                                if (pid == 17) { iconnew = Ecsicon; }
                                
                                if (productsdata.access == true) {
                                    return (
                                        <Link key={`desktop-item-link-${pid}`} to={productsdata.url} target="_blank" className="dropdown-link discntr" >
                                            <ListItemIcon className='drpicon disicon'>
                                                <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                            </ListItemIcon>
                                            {productsdata.name}
                                        </Link>
                                    )
                                }
                                else {
                                    return (
                                        <Grid key={`desktop-item-link-${pid}`} className="dropdown-link discntr" >
                                            <ListItemIcon className='drpicon disicon'>
                                                <img src={iconnew} alt={productsdata.name} className='menuicon' />
                                            </ListItemIcon>
                                            {productsdata.name}
                                        </Grid>
                                    )
                                }
                            })
                            }

                        </Grid>
                    </Grid>
                </Container>
            </Menu>
                    
            
        </Box>       
    )
}